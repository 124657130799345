import { TweenMax, TimelineLite, Sine, Linear, Power0, Power1, Power2, Power3, Cubic, CSSPlugin } from "gsap/all";

/**
 * Detect if element is visible in viewport
 * @param {Element} `el` Element to check if it's visible in viewport.
 */
export function isElementInViewport( el ) {
    var rect = el.getBoundingClientRect();

    return ( rect.top + rect.height <= 0 && rect.top > (window.innerHeight || document.documentElement.clientHeight) &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
}


/**
 * Get Timeline for SCROLL animation
 * @param {String} `animationName` animation timeline to set
 * @param {Element} `el` element to animate
 */
export function getTimeline( animationName, el ) {

    var tween = new TimelineLite({paused: true});

    switch (animationName) {

        // HEADER
        case 'header':
            var content = el.querySelector( '.c-header__fold' );
            var yellowBar = el.querySelector( '.c-header__title__bar' ) || el.querySelector( '.c-header__tagline__bar' );
            var logo = el.querySelector( '.c-header__logo' );
            if( content )
                tween.fromTo( content, 1, { y: 0 }, { y: -100, ease: Linear.easeNone }, 'start' );
            if( yellowBar )
                tween.fromTo( yellowBar, 1, { y: 0 }, { y: -170, ease: Linear.easeNone }, 'start' );
            if( logo )
                tween.fromTo( logo, 1, { y: 0 }, { y: -50, ease: Linear.easeNone }, 'start+=0.1' );
            break;

        // GENERIC PARALLAX
        case 'parallaxOne':
            tween.fromTo( el, 1, { y: 75 }, { y: -35, ease: Linear.easeNone } );
            break;
        case 'parallaxTwo':
            tween.fromTo( el, 1, { y: 100 }, { y: -30, ease: Linear.easeNone } );
            break;
        case 'parallaxThree':
            tween.fromTo( el, 1, { y: 100 }, { y: -50, ease: Linear.easeNone } );
            break;


        // INTRODUCTION
        case 'introductionTitle':
            tween.fromTo( el, 1, { y: 55 }, { y: -35, ease: Linear.easeNone }, 'start' );
            break;
        case 'introductionDescription':
            tween.fromTo( el, 1, { y: 75 }, { y: -35, ease: Linear.easeNone }, 'start' );
            break;
    }

    return tween;
}