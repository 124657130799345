import AbstractComponent from '../AbstractComponent';
import { addClass, css , removeClass} from '../../utils/CssUtils';
import { addEventListener } from '../../utils/EventUtils';
import { convertHTMLStringToDOMElement } from '../../utils/HtmlUtils';
import { getPageHeight, getPageWidth, hasProperty, stringFormat } from '../../utils/JsUtils';
import Logger from '../../utils/Logger';
import Request from '../../utils/Request';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import mapboxgl from 'mapbox-gl';
import MapboxglSpiderifier from 'mapboxgl-spiderifier';
import ZoomControl from 'mapbox-gl-controls/lib/zoom';
import { TweenMax, Power1 } from 'gsap/all';


export default class ActionsMapMap extends AbstractComponent {
    constructor(...args) {
        Logger.log('ActionsMapMap->constructor()');
        super(...args);

        this.classname = 'c-actions-map-map';
        this.mapMaxZoom = 17;
        this.mapSpiderifyZoom = 9;
        this.initDomElements();
        this.initLocations();
        this.initFilters();
        this.initMapboxMap();
        this.initActionPopin();
        this.addEventListeners();
    }


    initDomElements() {
        this.$navbar = document.body.querySelector( '.c-site-menu__navbar' );
        this.$mapContainer = this.$el.querySelector( '.' + this.classname + '__map-container' );
        this.$filters = this.$el.querySelectorAll( '.' + this.classname + '__filters' );
        this.$locations = this.$el.querySelectorAll( '.' + this.classname + '__filters__location' );
        this.$locationsNavigationPrev = this.$el.querySelectorAll( '.' + this.classname + '__filters__locations__navigation--prev' );
        this.$locationsNavigationNext = this.$el.querySelectorAll( '.' + this.classname + '__filters__locations__navigation--next' );
        this.$activeLocation = this.$el.querySelector( '.' + this.classname + '__filters__locations__active-location' );
        this.$filtersLocationsTaxonomies = this.$el.querySelector( '.' + this.classname + '__filters__locations-taxonomies' );
        this.$filtersTaxonomies = this.$filtersLocationsTaxonomies.querySelectorAll( '.' + this.classname + '__filters__taxonomy' );
        this.$filtersTaxonomiesTerms = this.$el.querySelectorAll( '.' + this.classname + '__filters__taxonomy-terms' );
        this.$filtersReset = this.$el.querySelector( '.' + this.classname + '__filters__reset' );
        this.$filtersOpenMobile = this.$el.querySelector( '.' + this.classname + '__filters__open-mobile' );
        this.$filtersPopinMobile = document.querySelector( '.' + this.classname + '__filters__popin-mobile' );
        if ( this.$filtersPopinMobile ) {
            this.$filtersPopinMobileClose = this.$filtersPopinMobile.querySelector( '.' + this.classname + '__filters__popin-mobile__close' );
            this.$filtersPopinMobileResults = this.$filtersPopinMobile.querySelector( '.' + this.classname + '__filters__popin-mobile__results' );
            this.$filtersPopinMobileReset = this.$filtersPopinMobile.querySelector( '.' + this.classname + '__filters__reset' );
            this.$filtersPopinMobileTaxonomies = this.$filtersPopinMobile.querySelectorAll( '.' + this.classname + '__filters__taxonomy' );
            this.$filtersPopinMobileTaxonomiesTerms = this.$filtersPopinMobile.querySelectorAll( '.' + this.classname + '__filters__taxonomy-terms' );
        }
        this.$actionPopinContainer = document.querySelector( '.' + this.classname + '__action__popin-container' );
        this.$actionPopin = document.querySelector( '.' + this.classname + '__action__popin' );
        if ( this.$actionPopin ) {
            this.$actionPopinClose = this.$actionPopin.querySelector( '.' + this.classname + '__action__popin__close' );
            this.$actionPopinMedia = this.$actionPopin.querySelector( '.' + this.classname + '__action__popin__media' );
            this.$actionPopinInfo = this.$actionPopin.querySelector( '.' + this.classname + '__action__popin__info' );
        }
    }


    initMapboxMap() {
        this.actions = [];
        this.filteredActions = [];
        this.actionsPopinData = {};
        if ( this.$mapContainer ) {
            const activeLocation = this.getActiveLocationCenterAndZoom();
            
            // Creating the Mapbox map
            mapboxgl.accessToken = window.FNC.mapbox_access_token;
            this.mapboxMap = new mapboxgl.Map( {
                container: this.$mapContainer,
                // style: 'mapbox://styles/mapbox/satellite-v9',
                style: 'mapbox://styles/chasseurdefrance/ckb7ysjjs4s9c1imy2f780lxc',
                center: activeLocation.center,
                zoom: activeLocation.zoom,
                maxZoom: this.mapMaxZoom,
                attributionControl: false,
                scrollZoom: false
            } );

            // Adding attribution and zoom controls
            this.mapboxMap.addControl( new mapboxgl.AttributionControl( { compact: true } ) );
            this.mapboxMap.addControl( new ZoomControl(), 'bottom-left' );

            // Creating a popup, but don't add it to the map yet
            this.mapboxMapPopupHoverActionId = null;
            this.mapboxMapPopup = new mapboxgl.Popup( {
                offset: {
                    'top': [ 0, 22 ],
                    'top-left': [ 0, 22 ],
                    'top-right': [ 0, 22 ],
                    'bottom': [ 0, -22 ],
                    'bottom-left': [ 0, -22 ],
                    'bottom-right': [ 0, -22 ],
                    'left': [ 22, 0 ],
                    'right': [ -22, 0 ]
                },
                closeButton: false,
                closeOnClick: false
            } );
        }
    }


    initLocations() {
        this.locations = [];
        if ( this.$locations ) {
            for ( let index = 0; index < this.$locations.length; index++ ) {
                const locationElement = this.$locations[index];
                const location = {};
                location['index'] = index;
                location['element'] = locationElement;
                location['name'] = locationElement.getAttribute( 'data-name' );
                location['center'] = {
                    'lat': parseFloat( locationElement.getAttribute( 'data-center-lat' ) ),
                    'lng': parseFloat( locationElement.getAttribute( 'data-center-lng' ) ),
                };
                location['zoom'] = {
                    'desktop': parseFloat( locationElement.getAttribute( 'data-zoom-desktop' ) ),
                    'mobile': parseFloat( locationElement.getAttribute( 'data-zoom-mobile' ) ),
                };
                this.locations.push( location );
            }
            this.currentLocation = this.locations[0];
        }
    }


    initFilters() {
        this.isFiltersTaxonomyTermsOpen = false;
        this.filters = [];
        this.filtersByTaxonomy = {};
        if ( this.$filtersTaxonomies ) {
            for ( const filterTaxonomy of this.$filtersTaxonomies ) {
                const taxonomy = filterTaxonomy.getAttribute( 'data-taxonomy' );
                if ( taxonomy ) {
                    this.filtersByTaxonomy[taxonomy] = [];
                }
            }
        }
    }


    addEventListeners() {
        this.app.on( 'resize', this.resize.bind( this ) );
        addEventListener( document, 'keydown', this.escapeKeyHandler.bind( this ) );
        this.addMapboxMapEventListeners();
        this.addLocationsEventListeners();
        this.addFiltersTaxonomiesEventListeners();
        this.addFiltersTaxonomiesTermsEventListeners();
        this.addFiltersResetEventListeners();
        this.addFiltersOpenMobileEventListeners();
        this.addFiltersPopinMobileEventListeners();
        this.addActionPopinEventListeners();
    }


    escapeKeyHandler( e ) {
        if ( e.keyCode == 27 ) { // Escape key
            if ( this.isActionPopinOpen ) {
                this.actionPopinFromHistory = false;
                this.hideActionPopin();
                return;
            }

            if ( this.isFiltersTaxonomyTermsOpen ) {
                this.hideFiltersTaxonomyTerms();
                return;
            }
        }
    }


    addMapboxMapEventListeners() {
        if ( this.$mapContainer && this.mapboxMap ) {
            this.mapboxMap.on( 'load', () => {
                // Source
                this.mapboxMap.addSource( 'actions', {
                    'type': 'geojson',
                    'data': {
                        'type': 'FeatureCollection',
                        'features': []
                    },
                    'cluster': true,
                    'clusterRadius': 35
                } );
                
                // Clusters circles layout
                this.mapboxMap.addLayer( {
                    id: 'clusters',
                    type: 'circle',
                    source: 'actions',
                    filter: ['has', 'point_count'],
                    paint: {
                        'circle-color': '#ffffff',
                        'circle-radius': [
                            'case',
                            ['boolean',
                              ['feature-state', 'spiderifyHover'],
                              false
                            ],
                            5,
                            21
                        ],
                        'circle-opacity': [
                            'case',
                            ['boolean',
                              ['feature-state', 'spiderifyHover'],
                              false
                            ],
                            0,
                            1
                        ]
                    }
                } );
                
                // Clusters counts layout
                this.mapboxMap.addLayer( {
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'actions',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': '{point_count}',
                        'text-size': 16
                    },
                    paint: {
                        'text-color': '#1B1B1B',
                        'text-opacity': [
                            'case',
                            ['boolean',
                              ['feature-state', 'spiderifyHover'],
                              false
                            ],
                            0,
                            1
                        ]
                    }
                } );
                
                // Actions circles
                this.mapboxMap.addLayer( {
                    id: 'unclustered-point',
                    type: 'circle',
                    source: 'actions',
                    filter: ['!', ['has', 'point_count']],
                    paint: {
                        'circle-color': this.getMapboxMapProgramsColors(),
                        'circle-radius': [
                            'case',
                            ['boolean',
                              ['feature-state', 'hover'],
                              false
                            ],
                            16,
                            8
                        ],
                        'circle-stroke-width': [
                            'case',
                            ['boolean',
                              ['feature-state', 'hover'],
                              false
                            ],
                            4,
                            3
                        ],
                        'circle-stroke-color': '#ffffff'
                    }
                } );

                // Initializing the map data
                this.initMapboxMapData();

                // Initializing map spiderifier and undo events
                this.mapboxMapSpiderifier = this.getMapboxMapSpiderifier();
                this.mapboxMap.on( 'zoomstart', () => this.unspiderifyMapboxMap() );
                this.mapboxMap.on( 'dragstart', () => this.unspiderifyMapboxMap() );

                // Inspect a cluster on click or spiderify the cluster (if on max zoom)
                this.mapboxMap.on( 'click', ( e ) => {
                    const clustersFeatures = this.mapboxMap.queryRenderedFeatures( e.point, { layers: ['clusters'] } );
                    const clusterCountfeatures = this.mapboxMap.queryRenderedFeatures( e.point, { layers: ['cluster-count'] } );
                    const clusterFeature = ( clustersFeatures.length > 0 ) ? clustersFeatures[0] : null;
                    const clusterCountfeature = ( clusterCountfeatures.length > 0 ) ? clusterCountfeatures[0] : null;

                    if ( clusterFeature && !this.mapboxMapSpiderifierCluster ) {
                        if ( this.mapboxMap.getZoom() < this.mapSpiderifyZoom ) {
                            this.mapboxMap.getSource( 'actions' ).getClusterExpansionZoom(
                                clusterFeature.properties.cluster_id,
                                ( err, zoom ) => {
                                    if ( err ) { return; }
                                    this.mapboxMap.easeTo( {
                                        center: clusterFeature.geometry.coordinates,
                                        zoom: zoom
                                    } );
                                }
                            );
                        } else {
                            this.mapboxMapSpiderifierCluster = clusterFeature;
                            this.setMapboxMapSpiderifyHoverActionState( this.mapboxMapSpiderifierCluster, true );
                            this.mapboxMapSpiderifierClusterCount = clusterCountfeature;
                            this.setMapboxMapSpiderifyHoverActionState( this.mapboxMapSpiderifierClusterCount, true );
                            this.mapboxMap.getSource( 'actions' ).getClusterLeaves(
                                clusterFeature.properties.cluster_id, 150, 0,
                                ( err, leafFeatures ) => {
                                    if ( err ) { return; }
                                    this.mapboxMapSpiderifier.spiderfy( clusterFeature.geometry.coordinates, leafFeatures );
                                }
                            );
                        }
                    } else {
                        this.unspiderifyMapboxMap();
                    }
                } );

                // 'Mouse Enter' listener on actions layer
                this.mapboxMap.on( 'mouseenter', 'unclustered-point', ( e ) => {
                    if ( e.features.length > 0 ) {
                        const action = e.features[0];

                        // Setting 'hover' state
                        this.setMapboxMapHoverActionState( false );
                        this.mapboxMapPopupHoverActionId = action.id;
                        this.setMapboxMapHoverActionState( true );

                        // Showing the map popup
                        var coordinates = action.geometry.coordinates.slice();
                        this.mapboxMapPopup.setLngLat( coordinates )
                            .setHTML( action.properties.n )
                            .addTo( this.mapboxMap );

                        // Pre-fetching the action popin data
                        this.fetchActionPopinData( action.id );
                    }
                } );

                // 'Mouse Leave' listener on actions layer
                this.mapboxMap.on( 'mouseleave', 'unclustered-point', () => {
                    // Resetting 'hover' state
                    this.setMapboxMapHoverActionState( false );

                    // Hiding the map popup
                    this.mapboxMapPopup.remove();
                } );

                // 'Click' listener on actions
                this.mapboxMap.on( 'click', 'unclustered-point', ( e ) => {
                    if ( e.features.length > 0 ) {
                        const action = e.features[0];
                        this.actionPopinFromHistory = false;
                        this.showActionPopinForAction( action.id, this.getProgramSlugForAction( action ) );
                    }
                } );

                // 'Mouse Enter' & 'Mouse Leave' listeners on clusters layers
                const addCursor = () => this.mapboxMap.getCanvas().style.cursor = 'pointer';
                const removeCursor = () => this.mapboxMap.getCanvas().style.cursor = '';
                this.mapboxMap.on( 'mouseenter', 'clusters', addCursor );
                this.mapboxMap.on( 'mouseleave', 'clusters', removeCursor);
                this.mapboxMap.on( 'mouseenter', 'unclustered-point', addCursor );
                this.mapboxMap.on( 'mouseleave', 'unclustered-point', removeCursor );
            } );
        }
    }


    getMapboxMapSpiderifier() {
        this.mapboxMapSpiderifierCluster = null;
        this.mapboxMapSpiderifierClusterCount = null;
        this.mapboxMapSpiderifierPopup = null;
        return new MapboxglSpiderifier( this.mapboxMap, {
            animate: true,
            animationSpeed: 250,
            customPin: true,
            onClick: ( e, spiderLeg ) => {
                const action = spiderLeg.feature;
                this.actionPopinFromHistory = false;
                this.showActionPopinForAction( action.id, this.getProgramSlugForAction( action ) );
            },
            initializeLeg: ( spiderLeg ) => {
                const pin = spiderLeg.elements.pin;
                const action = spiderLeg.feature;

                let backgroundColor = '#61615a';
                for ( const program of window.FNC.actions_map_programs ) {
                    if ( hasProperty( program, 'id' ) && hasProperty( program, 'color' ) ) {
                        if ( action.properties.t.indexOf( program.id ) !== -1 ) {
                            backgroundColor = program.color;
                            break;
                        }
                    }
                }

                css( pin, { backgroundColor: backgroundColor } );

                addEventListener( pin, 'mouseenter', () => {
                    this.mapboxMapSpiderifierPopup = new mapboxgl.Popup({
                        closeButton: false,
                        closeOnClick: false,
                        offset: MapboxglSpiderifier.popupOffsetForSpiderLeg( spiderLeg, 22 )
                    } );
                    this.mapboxMapSpiderifierPopup.setHTML( action.properties.n )
                    .addTo( this.mapboxMap )
                    spiderLeg.mapboxMarker.setPopup( this.mapboxMapSpiderifierPopup );
                })

                addEventListener( pin, 'mouseleave', () => { 
                    if ( this.mapboxMapSpiderifierPopup ) {
                        this.mapboxMapSpiderifierPopup.remove();
                    }
                } );
            }
        } );
    }


    unspiderifyMapboxMap() {
        this.mapboxMapSpiderifier.unspiderfy();
        this.setMapboxMapSpiderifyHoverActionState( this.mapboxMapSpiderifierCluster, false );
        this.setMapboxMapSpiderifyHoverActionState( this.mapboxMapSpiderifierClusterCount, false );
        setTimeout( () => {
            this.mapboxMapSpiderifierCluster = null;
            this.mapboxMapSpiderifierClusterCount = null;
        }, 100 );
    }


    setMapboxMapSpiderifyHoverActionState( item, state ) {
        if ( item ) {
            this.mapboxMap.setFeatureState( item, { spiderifyHover: state } );
        }
    }


    getMapboxMapProgramsColors() {
        const colorsSpecs = [ 'case' ];
        for ( const program of window.FNC.actions_map_programs ) {
            if ( hasProperty( program, 'id' ) && hasProperty( program, 'color' ) ) {
                colorsSpecs.push( [ 'in', program.id, [ 'get', 't' ] ] );
                colorsSpecs.push( program.color );
            }
        }
        colorsSpecs.push( '#61615a' );
        return colorsSpecs;
    }


    initMapboxMapData() {
        Request.AJAX( {
            'url': window.FNC.actions_map_geojson_url,
            'success': ( response ) => {
                if ( response && hasProperty( response, 'features' ) ) {
                    this.actions = response.features;
                    this.filteredActions = this.actions;
                    this.updateMapboxMapActions();
                    this.updateFiltersPopinMobileResults();
                    this.checkOnLoadActionPopin();
                }
            }
        } );
    }


    setMapboxMapHoverActionState( state ) {
        if ( this.mapboxMapPopupHoverActionId ) {
            this.mapboxMap.setFeatureState( {
                source: 'actions',
                id: this.mapboxMapPopupHoverActionId
            }, { hover: state } );
        }
    }


    fetchActionPopinData( actionId ) {
        if ( hasProperty( this.actionsPopinData, actionId ) ) {
            return;
        }

        this.actionsPopinData[actionId] = { ready: false };
        Request.AJAX( {
            'url': window.FNC.ajax_url + '?action=fnc_actions_action_popin_ajax',
            'data': { 'action_id': actionId },
            'success': ( response ) => {
                if ( response && hasProperty( response, 'data' ) ) {
                    this.actionsPopinData[response.data.action_id].ready = true;
                    this.actionsPopinData[response.data.action_id].data = response.data;
                    this.emit( 'action-popin-data-loaded', response.data.action_id, response.data );
                }
            }
        } );
    }


    getProgramSlugForAction( action ) {
        if ( action ) {
            const terms = action.properties.t;
            for ( const program of window.FNC.actions_map_programs ) {
                if ( hasProperty( program, 'id' ) && hasProperty( program, 'slug' ) ) {
                    if ( terms.indexOf( program.id ) !== -1 ) {
                        return program.slug;
                    }
                }
            }
        }
        return null;
    }


    addLocationsEventListeners() {
        if ( this.locations ) {
            for ( const location of this.locations ) {
                addEventListener( location.element, 'click', () => {
                    location.element.blur();
                    this.setCurrentLocation( location.index );
                } );
            }
        }

        if ( this.$locationsNavigationPrev ) {
            addEventListener( this.$locationsNavigationPrev, 'click', this.goToPrevLocation.bind( this ) );
        }

        if ( this.$locationsNavigationPrev ) {
            addEventListener( this.$locationsNavigationNext, 'click', this.goToNextLocation.bind( this ) );
        }
    }


    setCurrentLocation( index ) {
        if ( !this.currentLocation || ( this.currentLocation.index !== index ) ) {
            this.currentLocation = this.locations[index];
            removeClass( this.$locations, this.classname + '__filters__location--active' );
            addClass( this.currentLocation.element, this.classname + '__filters__location--active' );
            if ( this.$activeLocation ) {
                this.$activeLocation.innerHTML = this.currentLocation.name;
            }
        }
        if ( this.mapboxMap ) {
            const activeLocation = this.getActiveLocationCenterAndZoom();
            this.mapboxMap.flyTo( {
                center: activeLocation.center,
                zoom: activeLocation.zoom,
                speed: 1.5
            } );
        }
    }


    goToPrevLocation() {
        if ( this.locations ) {
            let index = this.currentLocation.index - 1
            if ( index < 0 ) {
                index = ( this.locations.length - 1 );
            }
            this.setCurrentLocation( index );
        }
    }


    goToNextLocation() {
        if ( this.locations ) {
            let index = this.currentLocation.index + 1
            if ( index >= this.locations.length ) {
                index = 0;
            }
            this.setCurrentLocation( index );
        }
    }


    getActiveLocationCenterAndZoom() {
        if ( this.currentLocation ) {
            return {
                center: [ this.currentLocation.center.lng, this.currentLocation.center.lat ],
                zoom: this.app.isBreakpointUp( 'lg' ) ? this.currentLocation.zoom.desktop : this.currentLocation.zoom.mobile 
            }
        } else {
            return {
                center: [1.7191036, 46.71109],
                zoom: 4.9
            }
        }
    }

    
    addFiltersTaxonomiesEventListeners() {
        if ( this.$filtersTaxonomies ) {
            for ( const filtersTaxonomy of this.$filtersTaxonomies ) {
                const taxonomy = filtersTaxonomy.getAttribute( 'data-taxonomy' );
                if ( taxonomy ) {
                    const filtersTaxonomyButton = filtersTaxonomy.querySelector( '.' + this.classname + '__filters__taxonomy__button' );
                    if ( filtersTaxonomyButton ) {
                        addEventListener( filtersTaxonomyButton, 'click', () => {
                            this.showFiltersTaxonomyTerms( taxonomy );
                        } );
                    }
                }
            }
        }
    }


    addFiltersTaxonomiesTermsEventListeners() {
        if ( this.$filtersTaxonomiesTerms ) {
            for ( const filtersTaxonomyTerms of this.$filtersTaxonomiesTerms ) {
                const filtersTaxonomyTermsButton = filtersTaxonomyTerms.querySelector( '.' + this.classname + '__filters__taxonomy-terms__taxonomy' );
                if ( filtersTaxonomyTermsButton ) {
                    addEventListener( filtersTaxonomyTermsButton, 'click', this.hideFiltersTaxonomyTerms.bind( this ) );
                }

                const taxonomy = filtersTaxonomyTerms.getAttribute( 'data-taxonomy' );
                if ( taxonomy ) {
                    const filtersTaxonomyCheckboxes = filtersTaxonomyTerms.querySelectorAll( '.' + this.classname + '__filters__taxonomy-terms__term__checkbox' );
                    if ( filtersTaxonomyCheckboxes ) {
                        for ( const filtersTaxonomyCheckbox of filtersTaxonomyCheckboxes ) {
                            addEventListener( filtersTaxonomyCheckbox, 'click', () => {
                                this.toggleFiltersTaxonomiesTermsCheckbox( filtersTaxonomyCheckbox, taxonomy );
                            } );
                        }
                    }
                }
            }
        }
    }

    
    toggleFiltersTaxonomiesTermsCheckbox( filtersTaxonomyCheckbox, taxonomy ) {
        const filtersTaxonomyCheckboxId = filtersTaxonomyCheckbox.getAttribute( 'data-id' );
        if ( filtersTaxonomyCheckboxId ) {
            const checkboxes = document.querySelectorAll( '.' + this.classname + '__filters__taxonomy-terms__term__checkbox[data-id="' + filtersTaxonomyCheckboxId + '"]' );
            for ( const checkbox of checkboxes ) {
                checkbox.checked = filtersTaxonomyCheckbox.checked;
            }
        }
        const filtersTaxonomyCheckboxValue = parseInt( filtersTaxonomyCheckbox.value );
        if ( filtersTaxonomyCheckbox.checked ) {
            this.filters.push( filtersTaxonomyCheckboxValue );
            this.filtersByTaxonomy[taxonomy].push( filtersTaxonomyCheckboxValue );
        } else {
            this.filters.splice( this.filters.indexOf( filtersTaxonomyCheckboxValue ), 1 );
            this.filtersByTaxonomy[taxonomy].splice( this.filtersByTaxonomy[taxonomy].indexOf( filtersTaxonomyCheckboxValue ), 1 );
        }
        this.updateFiltersTaxonomiesDisplayBadgesAndReset();
        this.filterDisplayedActions();
    }


    updateFiltersTaxonomiesDisplayBadgesAndReset() {
        if ( this.$filtersTaxonomies ) {
            this.updateFiltersTaxonomiesDisplayBadges( this.$filtersTaxonomies );
        }

        if ( this.$filtersPopinMobileTaxonomies ) {
            this.updateFiltersTaxonomiesDisplayBadges( this.$filtersPopinMobileTaxonomies );
        }

        const filtersCount = this.filters.length;

        if ( this.$filtersOpenMobile ) {
            const filtersOpenMobileBadge = this.$filtersOpenMobile.querySelector( '.' + this.classname + '__filters__open-mobile__icon__badge' );
            if ( filtersOpenMobileBadge ) {
                filtersOpenMobileBadge.innerHTML = filtersCount;
            }
        }

        if ( this.$filtersReset ) {
            if ( filtersCount ) {
                addClass( this.$filtersReset, this.classname + '__filters__reset--show' );
                addClass( this.$filtersPopinMobileReset, this.classname + '__filters__reset--show' );
                addClass( this.$filtersOpenMobile, this.classname + '__filters__open-mobile--active' );
            } else {
                removeClass( this.$filtersReset, this.classname + '__filters__reset--show' );
                removeClass( this.$filtersPopinMobileReset, this.classname + '__filters__reset--show' );
                removeClass( this.$filtersOpenMobile, this.classname + '__filters__open-mobile--active' );
            }
        }
    }


    updateFiltersTaxonomiesDisplayBadges( filtersTaxonomies ) {
        for ( const filtersTaxonomy of filtersTaxonomies ) {
            const taxonomy = filtersTaxonomy.getAttribute( 'data-taxonomy' );
            if ( taxonomy ) {
                const termsFiltersCount = this.filtersByTaxonomy[taxonomy].length;
                if ( termsFiltersCount ) {
                    addClass( filtersTaxonomy, this.classname + '__filters__taxonomy--active' );
                } else {
                    removeClass( filtersTaxonomy, this.classname + '__filters__taxonomy--active' );
                }
                const filtersTaxonomyBadge = filtersTaxonomy.querySelector( '.' + this.classname + '__filters__taxonomy__badge' );
                if ( filtersTaxonomyBadge ) {
                    filtersTaxonomyBadge.innerHTML = termsFiltersCount;
                }
            }
        }
    }


    showFiltersTaxonomyTerms( taxonomy ) {
        const filtersTaxonomyTerms = this.$el.querySelector( '.' + this.classname + '__filters__taxonomy-terms[data-taxonomy="' + taxonomy + '"' );
        if ( filtersTaxonomyTerms ) {
            const filtersTaxonomyTermsTerms = filtersTaxonomyTerms.querySelector( '.' + this.classname + '__filters__taxonomy-terms__terms' );
            if ( filtersTaxonomyTermsTerms ) {
                filtersTaxonomyTermsTerms.scrollTop = 0;
            }
            this.isFiltersTaxonomyTermsOpen = true;
            addClass( filtersTaxonomyTerms, this.classname + '__filters__taxonomy-terms--show' );
            addClass( this.$filters, this.classname + '__filters--show-taxonomy-terms' );
        }
    }


    hideFiltersTaxonomyTerms() {
        removeClass( this.$filters, this.classname + '__filters--show-taxonomy-terms' );
        removeClass( this.$filtersTaxonomiesTerms, this.classname + '__filters__taxonomy-terms--show' );
        this.isFiltersTaxonomyTermsOpen = false;
    }


    addFiltersResetEventListeners() {
        this._resetFilters = this.resetFilters.bind( this );
        if ( this.$filtersReset ) {
            addEventListener( this.$filtersReset, 'click', this._resetFilters );
        }
        if ( this.$filtersPopinMobileReset ) {
            addEventListener( this.$filtersPopinMobileReset, 'click', this._resetFilters );
        }
    }


    resetFilters( e ) {
        e.target.blur();
        this.initFilters();
        this.resetTaxonomiesTermsCheckboxes();
        this.updateFiltersTaxonomiesDisplayBadgesAndReset();
        this.filterDisplayedActions();
    }

    
    resetTaxonomiesTermsCheckboxes() {
        if ( this.$filtersTaxonomiesTerms ) {
            for ( const filtersTaxonomyTerms of this.$filtersTaxonomiesTerms ) {
                const filtersTaxonomyCheckboxes = filtersTaxonomyTerms.querySelectorAll( '.' + this.classname + '__filters__taxonomy-terms__term__checkbox' );
                if ( filtersTaxonomyCheckboxes ) {
                    for ( const filtersTaxonomyCheckbox of filtersTaxonomyCheckboxes ) {
                        filtersTaxonomyCheckbox.checked = false;
                    }
                }
            }
        }
        if ( this.$filtersPopinMobileTaxonomiesTerms ) {
            for ( const filtersTaxonomyTerms of this.$filtersPopinMobileTaxonomiesTerms ) {
                const filtersTaxonomyCheckboxes = filtersTaxonomyTerms.querySelectorAll( '.' + this.classname + '__filters__taxonomy-terms__term__checkbox' );
                if ( filtersTaxonomyCheckboxes ) {
                    for ( const filtersTaxonomyCheckbox of filtersTaxonomyCheckboxes ) {
                        filtersTaxonomyCheckbox.checked = false;
                    }
                }
            }
        }
    }


    addFiltersOpenMobileEventListeners() {
        if ( this.$filtersOpenMobile ) {
            addEventListener( this.$filtersOpenMobile, 'click', ( e ) => {
                e.target.blur();
                this.showFiltersPopinMobile();
            } );
        }
    }


    showFiltersPopinMobile() {
        if ( this.$filtersPopinMobile ) {
            disableBodyScroll( this.$filtersPopinMobile );
            css( this.$filtersPopinMobile, { visibility: 'visible' } );
            addClass( this.$filtersPopinMobile, this.classname + '__filters__popin-mobile--show' );
        }
    }


    addFiltersPopinMobileEventListeners() {
        if ( this.$filtersPopinMobile ) {
            if ( this.$filtersPopinMobileClose ) {
                addEventListener( this.$filtersPopinMobileClose, 'click', ( e ) => {
                    e.target.blur();
                    this.hideFiltersPopinMobile();
                } );
            }

            if ( this.$filtersPopinMobileResults ) {
                addEventListener( this.$filtersPopinMobileResults, 'click', ( e ) => {
                    e.target.blur();
                    this.hideFiltersPopinMobile();
                } );
            }

            if ( this.$filtersPopinMobileTaxonomies ) {
                for ( const filtersTaxonomy of this.$filtersPopinMobileTaxonomies ) {
                    const taxonomy = filtersTaxonomy.getAttribute( 'data-taxonomy' );
                    if ( taxonomy ) {
                        const filtersTaxonomyButton = filtersTaxonomy.querySelector( '.' + this.classname + '__filters__taxonomy__button' );
                        if ( filtersTaxonomyButton ) {
                            addEventListener( filtersTaxonomyButton, 'click', () => {
                                this.showFiltersPopinMobileTaxonomyTerms( taxonomy );
                            } );
                        }
                    }
                }
            }

            if ( this.$filtersPopinMobileTaxonomiesTerms ) {
                for ( const filtersTaxonomyTerms of this.$filtersPopinMobileTaxonomiesTerms ) {
                    const filtersTaxonomyTermsButton = filtersTaxonomyTerms.querySelector( '.' + this.classname + '__filters__taxonomy-terms__taxonomy' );
                    if ( filtersTaxonomyTermsButton ) {
                        addEventListener( filtersTaxonomyTermsButton, 'click', this.hideFiltersPopinMobileTaxonomyTerms.bind( this ) );
                    }

                    const taxonomy = filtersTaxonomyTerms.getAttribute( 'data-taxonomy' );
                    if ( taxonomy ) {
                        const filtersTaxonomyCheckboxes = filtersTaxonomyTerms.querySelectorAll( '.' + this.classname + '__filters__taxonomy-terms__term__checkbox' );
                        if ( filtersTaxonomyCheckboxes ) {
                            for ( const filtersTaxonomyCheckbox of filtersTaxonomyCheckboxes ) {
                                addEventListener( filtersTaxonomyCheckbox, 'click', () => {
                                    this.toggleFiltersTaxonomiesTermsCheckbox( filtersTaxonomyCheckbox, taxonomy );
                                } );
                            }
                        }
                    }
                }
            }
        }
    }


    hideFiltersPopinMobile() {
        if ( this.$filtersPopinMobile ) {
            removeClass( this.$filtersPopinMobile, this.classname + '__filters__popin-mobile--show' );
            clearAllBodyScrollLocks();
            setTimeout( () => css( this.$filtersPopinMobile, { visibility: 'hidden' } ), 300 );
        }
    }


    showFiltersPopinMobileTaxonomyTerms( taxonomy ) {
        const filtersTaxonomyTerms = this.$filtersPopinMobile.querySelector( '.' + this.classname + '__filters__taxonomy-terms[data-taxonomy="' + taxonomy + '"' );
        if ( filtersTaxonomyTerms ) {
            const filtersTaxonomyTermsTerms = filtersTaxonomyTerms.querySelector( '.' + this.classname + '__filters__taxonomy-terms__terms' );
            if ( filtersTaxonomyTermsTerms ) {
                filtersTaxonomyTermsTerms.scrollTop = 0;
                disableBodyScroll( filtersTaxonomyTermsTerms );
            }
            addClass( filtersTaxonomyTerms, this.classname + '__filters__taxonomy-terms--show' );
            addClass( this.$filtersPopinMobile, this.classname + '__filters__popin-mobile--show-taxonomy-terms' );
        }
    }


    hideFiltersPopinMobileTaxonomyTerms() {
        removeClass( this.$filtersPopinMobile, this.classname + '__filters__popin-mobile--show-taxonomy-terms' );
        removeClass( this.$filtersPopinMobileTaxonomiesTerms, this.classname + '__filters__taxonomy-terms--show' );
    }


    filterDisplayedActions() {
        this.filteredActions = this.actions.filter( ( item ) => {
            if ( hasProperty( item, 'properties' ) && hasProperty( item.properties, 't' ) ) {
                for ( const taxonomy in this.filtersByTaxonomy ) {
                    const taxonomyFilters = this.filtersByTaxonomy[taxonomy];
                    if ( taxonomyFilters.length > 0 ) {
                        const matches = taxonomyFilters.some( ( taxonomyFilter ) => {
                            return item.properties.t.indexOf( taxonomyFilter ) !== -1;
                        } );
                        if ( !matches ) {
                            return false;
                        }
                    }
                }
            }
            return true;
        } );
        this.updateMapboxMapActions();
        this.updateFiltersPopinMobileResults();
    }

    
    updateMapboxMapActions() {
        this.mapboxMap.getSource( 'actions' ).setData( {
            'type': 'FeatureCollection',
            'features': this.filteredActions
        } );
    }


    updateFiltersPopinMobileResults() {
        const filteredActionsCount = this.filteredActions.length;
        let label = this.$filtersPopinMobileResults.getAttribute( 'data-no-results' );
        if ( filteredActionsCount === 0 ) {
            label = this.$filtersPopinMobileResults.getAttribute( 'data-no-results' );
        } else if ( filteredActionsCount === 1 ) {
            label = this.$filtersPopinMobileResults.getAttribute( 'data-single-result' );
        } else {
            label = stringFormat( this.$filtersPopinMobileResults.getAttribute( 'data-multiple-results' ), filteredActionsCount );
        }
        this.$filtersPopinMobileResults.innerHTML = label;
    }


    initActionPopin() {
        this.isActionPopinOpen = false;
        this.actionPopinDefaultClass = this.$actionPopin.getAttribute( 'class' );
        this.isActionPopinActionId = null;
        this.actionPopinMedia = null;
        this.actionPopinInfo = null;
        this.actionPopinFromHistory = false;
    }


    addActionPopinEventListeners() {
        if ( this.$actionPopin ) {
            if ( this.$actionPopinClose ) {
                addEventListener( this.$actionPopinClose, 'click', ( e ) => {
                    e.target.blur();
                    this.actionPopinFromHistory = false;
                    this.hideActionPopin();
                } );
            }
        }

        addEventListener( window, 'popstate', ( event ) => {
            this.actionPopinFromHistory = true;
            if ( hasProperty( event.state, 'action_id' ) ) {
                this.showActionPopinForAction( event.state.action_id );
            } else {
                this.hideActionPopin();
            }
        } );
    }


    showActionPopinForAction( actionId, program = null, zoomOnMap = false ) {
        if ( actionId && ( this.isActionPopinActionId !== actionId ) ) {
            this.isActionPopinActionId = actionId;
            if ( program && !this.isActionPopinOpen ) {
                this.setActionPopinProgramClass( program );
            }
            this.showActionPopin();
            if ( hasProperty( this.actionsPopinData, actionId ) && this.actionsPopinData[actionId].ready ) {
                const data = this.actionsPopinData[actionId].data;
                this.showActionPopinDataForAction( data );
            } else {
                const showActionPopinDataWhenLoaded = ( id, data ) => {
                    if ( actionId === id ) {
                        this.off( 'action-popin-data-loaded', showActionPopinDataWhenLoaded );
                        this.showActionPopinDataForAction( data );
                    }
                };

                this.on( 'action-popin-data-loaded', showActionPopinDataWhenLoaded );
                this.fetchActionPopinData( actionId );
            }
        }
    }


    showActionPopinDataForAction( data, zoomOnMap = false ) {
        // Program
        this.setActionPopinProgramClass( data.program );

        // Media
        const mediaDOMElement = convertHTMLStringToDOMElement( data.media_html );
        if ( mediaDOMElement ) {
            this.$actionPopinMedia.firstElementChild.appendChild( mediaDOMElement );
        }

        // Info
        const infoDOMElement = convertHTMLStringToDOMElement( data.info_html );
        if ( infoDOMElement ) {
            this.$actionPopinInfo.firstElementChild.appendChild( infoDOMElement );
        }

        // Animate
        this.updateActionPopinMediaMinWidth();
        if ( this.actionPopinInfo ) {
            css( this.actionPopinInfo, { display: 'none' } );
        }
        css( infoDOMElement, { display: 'block' } );
        TweenMax.fromTo( mediaDOMElement, 0.7 ,{ width: '0%' }, { width: '100%', ease: Power1.easeOut, onStart: () => {
            addClass( mediaDOMElement, 'active' );
        } } );
        if ( this.actionPopinInfo ) {
            TweenMax.fromTo( this.actionPopinInfo, 0.3, { y: 0, autoAlpha: 1 }, { x: 25, autoAlpha: 0, ease: Power1.easeOut } );
        }
        TweenMax.fromTo( infoDOMElement, 0.5, { x: -25, autoAlpha: 0 }, { x: 0, autoAlpha: 1, delay: 0.2, ease: Power1.easeOut, onComplete: () => {
            addClass( infoDOMElement, 'active' );
            this.resetActionPopinData();
            this.actionPopinMedia = mediaDOMElement;
            this.actionPopinInfo = infoDOMElement;
            if ( this.app.isMobile ) {
                disableBodyScroll( this.actionPopinInfo.querySelector( '.u-overflow-container' ) );
            }
        } } );

        // Zoom on map
        if ( zoomOnMap ) {
            this.mapboxMap.jumpTo( {
                center: data.gps_coordinates,
                zoom: this.mapMaxZoom
            } );
        }

        if ( !this.actionPopinFromHistory && window.location != data.url  && !this.$el.classList.contains('in-master')) {
            history.pushState( { action_id: data.action_id }, data.page_title, data.url );
            document.title = data.page_title;
        }
    }


    resetActionPopinData() {
        if ( this.actionPopinMedia ) {
            this.$actionPopinMedia.firstElementChild.removeChild( this.actionPopinMedia );
            this.actionPopinMedia = null;
        }
        if ( this.actionPopinInfo ) {
            this.$actionPopinInfo.firstElementChild.removeChild( this.actionPopinInfo );
            this.actionPopinInfo = null;
        }
    }


    showActionPopin() {
        if ( this.isActionPopinOpen ) {
            return;
        }

        if ( this.$actionPopin ) {
            this.isActionPopinOpen = true;
            this.updateActionPopinPositionAndSize();
            css( this.$actionPopin, { visibility: 'visible' } );
            if ( this.app.isMobile ) {
                disableBodyScroll( this.$actionPopin );
            }
            addClass( this.$actionPopin, this.classname + '__action__popin--show' );
        }
    }


    setActionPopinProgramClass( program ) {
        this.$actionPopin.setAttribute( 'class', this.actionPopinDefaultClass );
        if ( this.isActionPopinOpen ) {
            addClass( this.$actionPopin, this.classname + '__action__popin--show' );
        }
        if ( program ) {
            addClass( this.$actionPopin, this.classname + '__action__popin--' + program );
        }
    }


    updateActionPopinMediaMinWidth() {
        if ( this.$actionPopinMedia && this.$actionPopinInfo ) {
            const mediaItems =  this.$actionPopinMedia.querySelectorAll( '.' + this.classname + '__action__popin__media__item' );
            if ( mediaItems ) {
                for ( const media of mediaItems ) {
                    var mediaFirstElementChild = media.firstElementChild;
                    if ( mediaFirstElementChild ) {
                        let minWidth = getPageWidth();
                        if ( this.app.isBreakpointUp( 'lg' ) ) {
                            minWidth = this.$actionPopinMedia.getBoundingClientRect().width;
                        }
                        css( mediaFirstElementChild, { minWidth: minWidth + 'px' } );
                    }
                }
            }
        }
    }


    hideActionPopin() {
        if ( !this.isActionPopinOpen ) {
            return;
        }

        if ( this.$actionPopin ) {
            removeClass( this.$actionPopin, this.classname + '__action__popin--show' );
            if ( this.app.isMobile ) {
                clearAllBodyScrollLocks();
            }
            if ( !this.actionPopinFromHistory && window.location != window.FNC.actions_map_page.url && !this.$el.classList.contains('in-master')) {
                history.pushState( null, window.FNC.actions_map_page.title, window.FNC.actions_map_page.url );
                document.title = window.FNC.actions_map_page.title;
            }
            setTimeout( () => {
                css( this.$actionPopin, { visibility: 'hidden' } );
                this.resetActionPopinData();
                this.isActionPopinActionId = null;
                this.isActionPopinOpen = false;
            }, 300 );
        }
    }


    updateActionPopinPositionAndSize() {
        if ( this.isActionPopinOpen && this.$actionPopinContainer && this.$actionPopin ) {
            const actionPopinMediaRatio = { width: 540, height: 292 };
            const elBoundingClientRect = this.$el.getBoundingClientRect();
            if ( this.app.isBreakpointUp( 'lg' ) ) {
                css( this.$actionPopinContainer, {
                    top: this.$el.offsetTop + 'px',
                    left: this.$el.offsetParent.offsetLeft + 'px',
                    height: elBoundingClientRect.height + 'px'
                } );
                css( this.$actionPopin, {
                    height: elBoundingClientRect.height + 'px'
                } );
                css( this.$actionPopinInfo, {
                    height: ( elBoundingClientRect.height - actionPopinMediaRatio.height ) + 'px'
                } );
            } else {
                css( this.$actionPopinContainer, { top: null, left: null, height: null } );
                css( this.$actionPopin, { height: null } );
                css( this.$actionPopinInfo, {
                    height: ( getPageHeight() - ( getPageWidth() * actionPopinMediaRatio.height / actionPopinMediaRatio.width ) ) + 'px'
                } );
            }
        }
    }


    checkOnLoadActionPopin() {
        if ( hasProperty( window.FNC, 'actions_map_action_popin_data' ) ) {
            const actionId = window.FNC.actions_map_action_popin_data.action_id;
            this.actionsPopinData[actionId] = {
                ready: true,
                data: window.FNC.actions_map_action_popin_data
            };
            window.scrollTo( 0, this.$el.offsetTop - this.$navbar.getBoundingClientRect().height );
            const actionData = window.FNC.actions_map_action_popin_data;
            if(!this.$el.classList.contains('in-master')){
                history.replaceState( { action_id: actionData.action_id }, actionData.page_title, actionData.url );  
            }
            this.showActionPopin();
            this.showActionPopinDataForAction( actionData, true );
            this.mapboxMapPopupHoverActionId = actionId;
        }
    }


    resize() {
        this.updateActionPopinPositionAndSize();
        this.updateActionPopinMediaMinWidth();
    }


    destroy() {}
}