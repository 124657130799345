

import { addClass, css, removeClass } from '../utils/CssUtils';
import AbstractComponent from "./AbstractComponent";
import Logger from '../utils/Logger';
import Media from './media';
import VideoEmbed from './video-embed';


export default class PhotoVideoPopin extends AbstractComponent {
    constructor(...args) {
        Logger.log( 'PhotoVideoPopin->constructor()' );
        super(...args);

        this.classname = 'c-photo-video-popin';
        this.initDomElements();
        this.addEventListeners();
        this.updateMediaImageClass();
        this.updateMediaVideoWidth();
    }


    initDomElements() {
        this.$media = this.$el.querySelector( '.' + this.classname + '__media' );
        this.$mediaInner = this.$el.querySelector( '.' + this.classname + '__media__inner' );
        this.$mediaImage = this.$el.querySelector( '.' + this.classname + '__media__image' );
        if ( this.$mediaImage ) {
            this.mediaImage = new Media( this.$mediaImage );
        }
        this.$mediaVideo = this.$el.querySelector( '.' + this.classname + '__media__video' );
        if ( this.$mediaVideo ) {
            this.mediaVideo = new VideoEmbed( this.$mediaVideo );
        }
    }


    addEventListeners() {
        if ( this.$mediaImage ) {
            this.app.on( 'resize', this.updateMediaImageClass.bind( this ) );
            this.mediaImage.addListener( 'media-attributes-update', this.updateMediaImageClass.bind( this ) );
        }
        if ( this.$mediaVideo ) {
            this.app.on( 'resize', this.updateMediaVideoWidth.bind( this ) );
        }
    }


    updateMediaImageClass() {
        if ( this.$mediaImage ) {
            const mediaBoundingClientRect = this.$media.getBoundingClientRect();
            const mediaWidth = mediaBoundingClientRect.width;
            const mediaHeight = mediaBoundingClientRect.height;
            const mediaImageRatio = parseFloat( this.$mediaImage.getAttribute( 'data-ratio' ) );
            if ( mediaImageRatio ) {
                // console.log( 'mediaWidth: ' + mediaWidth );
                // console.log( 'mediaHeight: ' + mediaHeight );
                // console.log( 'mediaWidth / mediaHeight: ' + mediaWidth / mediaHeight );
                // console.log( 'mediaImageRatio: ' + mediaImageRatio );
                // console.log( '( mediaWidth / mediaHeight ) > mediaImageRatio: ' + ( mediaWidth / mediaHeight ) > mediaImageRatio );
                if ( ( mediaWidth / mediaHeight ) > mediaImageRatio ) {
                    removeClass( this.$mediaImage, this.classname + '__media__image--width' );
                    addClass( this.$mediaImage, this.classname + '__media__image--height' );
                } else {
                    removeClass( this.$mediaImage, this.classname + '__media__image--height' );
                    addClass( this.$mediaImage, this.classname + '__media__image--width' );
                }
            } else {
                addClass( this.$mediaImage, this.classname + '__media__image--width' );
            }
        }
    }


    updateMediaVideoWidth() {
        if ( this.$mediaVideo ) {
            // Page width and height & video ratio
            const mediaBoundingClientRect = this.$mediaInner.getBoundingClientRect();
            const mediaWidth = mediaBoundingClientRect.width;
            const mediaHeight = mediaBoundingClientRect.height;
            const mediaVideoRatio = 16 / 9;

            // Computing the video container's width & height
            let videoContainerWidth = mediaWidth;
            let videoContainerHeight = Math.floor( videoContainerWidth / mediaVideoRatio );

            // Checking if the player is too high
            if ( videoContainerHeight > mediaHeight ) {
                videoContainerHeight = mediaHeight;
                videoContainerWidth = Math.floor( videoContainerHeight * mediaVideoRatio );
            }

            // Updating the video container
            css( this.$mediaVideo, {
                width: videoContainerWidth + 'px'
            } );
        }
    }


    destroy() {}
}