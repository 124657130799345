import AbstractPageController from './AbstractPageController';
import Logger from '../utils/Logger';
import { addEventListener } from '../utils/EventUtils';
import { addClass, css, removeClass } from '../utils/CssUtils';


export default class FaqController extends AbstractPageController {
    constructor( ...args ) {
        super( ...args );
        Logger.log( 'FaqController->constructor()' );


        this.ready();
    }


    initDomElements() {
    }


    addEventListeners() {

    }

    update() {}


    destroy() {}
}
