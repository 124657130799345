import AbstractComponent from "../AbstractComponent";
import Logger from '../../utils/Logger';
import { addEventListener, removeEventListener, preventDefault } from "../../utils/EventUtils";
import { closest } from '../../utils/JsUtils';
import { TweenMax, TimelineLite, Power1, Linear, Cubic, ScrollToPlugin } from "gsap/all";
import gsap from 'gsap';
gsap.registerPlugin(ScrollToPlugin);

export default class Header extends AbstractComponent {
    constructor(...args) {
        Logger.log('Header->constructor()');
        super(...args);

        this.classname = 'c-header';

        this.initDomElements();
        this.addEventListeners();
    }

    initDomElements() {

        // extra button dropdown
        this.$extra_button_dropdown = this.$el.querySelector( '.js-button-dropdown' );

        // scroll-indication
        this.$scroll_indication = this.$el.querySelector( '.js-scroll-indication' );
    }

    addEventListeners() {

        // Enable body click listening to close dropdown outlick
        this.app.on( 'body_click', (e) => {
            if( e.target.classList.contains( 'c-button__dropdown-content' ) ) return;

            var outClicked = closest( e.target, '.js-button-dropdown' );
            if( outClicked == this.$extra_button_dropdown ) return;

            this.update();
        } );


        // Enable refresh on update()
        this.app.on( 'app_scroll', this.update.bind(this) );


        // Bind extra dropdown button
        if( this.$extra_button_dropdown ) {
            this._extraButtonDropdownListener = this.extraButtonDropdownListener.bind( this );
            addEventListener( this.$extra_button_dropdown, 'click', this._extraButtonDropdownListener);
        }

        // Bind scrollTo
        if( this.$scroll_indication ) {
            addEventListener( this.$scroll_indication, 'click', () => {
                var offset = this.$el.getBoundingClientRect().height - document.querySelector( '.c-site-menu__navbar' ).getBoundingClientRect().height;
                TweenMax.to( window, 1, { scrollTo: { y: offset, autoKill: false } , ease: Cubic.easeOut } );
            });
        }

    }

    removeEventListeners() {
        removeEventListener( this.$extra_button_dropdown, 'click', this._prevBtnListener);
    }

    extraButtonDropdownListener(e) {

        if( this.app.isDesktop ) return;

        if( e.target.classList.contains( 'c-button__dropdown-content' ) ) return;

        if( this.$extra_button_dropdown.classList.contains( 'is-open' ) )
            this.$extra_button_dropdown.classList.remove( 'is-open' );
        else
            this.$extra_button_dropdown.classList.add( 'is-open' );
    }

    update() {

        // Close dropdown on scroll
        if( this.$extra_button_dropdown ) {
            if( this.$extra_button_dropdown.classList.contains( 'is-open' ) )
                this.$extra_button_dropdown.classList.remove( 'is-open' );
        }
    }


    destroy() {
        Logger.log( 'Header->destroy()' );

        this.removeEventListeners();
    }
}