export default class Sticky {

    constructor( element, opts = {} ) {

        // Check element
        if( element && typeof element == 'object')
            this.element = element;
        else
            throw new Error('You must specifiy element ot sticky');

        // Boolean to block process
        this._process = true;

        // Define options
        this.is_bounded = opts.is_bounded || false; // Container to sticky along
        this.referer = opts.referer || this.element; // Container to sticky along
        this.custom_classes = opts.custom_classes || false; // Custom class to apply
        this.offset = opts.offset || 0; // Define offset to add before sticky
        this.waiting = opts.waiting || false; // Define if element should reset or wait at bottom position
        this.positionFrom = opts.positionFrom || 'top'; // Define if element should be positioned from bottom

        // Store Document height
        this.docLastHeight = document.body.clientHeight;
        this.docNewHeight = document.body.clientHeight;

        this.initialize();

        // Add Scroll event
        window.onscroll = () => {
            if( this._process == true ) {
                this._onScroll();
            }
        };
    }

    initialize() {
        // Initial styles
        this._position = window.getComputedStyle(this.element,null).getPropertyValue("position");
        this._positionTop = window.getComputedStyle(this.element,null).getPropertyValue("top");
        this._width = window.getComputedStyle(this.element,null).getPropertyValue("width");
        // Store element height to fake create substituant
        this._height = this.element.getBoundingClientRect().height;
        this._width = window.getComputedStyle(this.element,null).getPropertyValue("width");
        // Prepare fake element
        this._classes = this._getClasses();
        this._styles = this._getStyles();
        this._fakeDOM = '<div class="fake-sticky ' + this._classes + '" style="' + this._styles + 'z-index: -1;"></div>';

        this._is_fixed = false;

        // Initialize positions
        this._limitTop = ( this.positionFrom == 'top' ) ? this.referer.getBoundingClientRect().top + window.pageYOffset - this.offset : this.element.getBoundingClientRect().top + window.pageYOffset;
        if( this.is_bounded ) {
            this._limitBottom = this.referer.getBoundingClientRect().top + window.pageYOffset + this.referer.getBoundingClientRect().height - this._height;
            if( this.positionFrom == 'bottom' )
                this._limitBottom = this.referer.getBoundingClientRect().top  + window.pageYOffset + this.referer.getBoundingClientRect().height - this._limitTop;
        }

        this._onScroll();
    }

    _onScroll() {
        // Manage sticky
        if( this._isAbove() ) {
            if( this._limitBottom && this._isPassed() ) {
                if( this.waiting == true ) {
                    this._stickElement();
                } else {
                    this._removeFixed();
                }
            } else {
                this._setFixed();
            }
        } else {
            this._removeFixed();
        }

        // Listen for DOM height change
        this._checkDOMHeight();
    }

    _checkDOMHeight() {
        this.docNewHeight = document.body.clientHeight;
        if( this.docLastHeight != this.docNewHeight ) {
            this._resetReferences();
        }

        this.docLastHeight = this.docNewHeight;
    }

    _isAbove() {
        return this._limitTop < window.pageYOffset;
    }

    _isPassed() {
        return this._limitBottom < window.pageYOffset;
    }

    _setFixed() {
        if( this._is_fixed == false ) {
            this._width = window.getComputedStyle(this.element,null).getPropertyValue("width");
            this.element.insertAdjacentHTML('afterend', this._fakeDOM);
            this._fakeElement = document.body.querySelector('.fake-sticky');
            this.element.style.position = 'fixed';
            this.element.style.top = ( this.positionFrom == 'top' ) ? this.offset + 'px' : window.innerHeight - this.offset - 40 + 'px'; // retrieve 40 to overpassed iphone bottom bar
            this.element.style.width = this._width;
            this._is_fixed = true;
        }
    }

    _removeFixed() {
        if( this._is_fixed == true ) {
            this.element.style.removeProperty('top');
            this.element.style.removeProperty('width');
            this._fakeElement.parentNode.removeChild( this._fakeElement );
            this.element.style.position = this._position;
            this.element.style.width = window.getComputedStyle(this.element,null).getPropertyValue("width");
            // prevent bug when resizing during fixed position (no reference)
            // this.resize();
            this._is_fixed = false;
        }
    }

    _stickElement() {
        if( this._is_fixed == true ) {
            var offsetWaiting = window.pageYOffset + this.offset;
            this._fakeElement.parentNode.removeChild(this._fakeElement);
            this.element.style.position = 'absolute';
            this.element.style.top = offsetWaiting + 'px';

            this._is_fixed = false;
        }
    }

    _getClasses() {
        return this.element.className;
    }

    _getStyles() {
        var styles = '';
        // Set Width
        styles += 'width: ' + window.getComputedStyle(this.element,null).getPropertyValue("width") + ';';

        // Set Height
        styles += 'height: ' + window.getComputedStyle(this.element,null).getPropertyValue("height") + ';';

        return styles;
    }

    resize() {
        this._resetReferences();
    }

    setOffset( offset ) {
        this.offset = offset;
    }

    _resetReferences() {
        // Disable again scroll event
        if( this._process == true ) {

            this._process = false;

            this.element.style.removeProperty('top');
            this.element.style.removeProperty('width');

            if( this._fakeElement != null && this._fakeElement.parentNode != null )
                this._fakeElement.parentNode.removeChild( this._fakeElement );

            this.element.style.removeProperty('position');
            this.element.style.position = window.getComputedStyle(this.element,null).getPropertyValue("position");
            this.element.style.width = window.getComputedStyle(this.element,null).getPropertyValue("width");
            this._is_fixed = false;

            this.initialize();

            // Enable again scroll event
            this._process = true;
        }
    }

}