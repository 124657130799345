import AbstractComponent from './AbstractComponent';
import Logger from '../utils/Logger';
import { addClass, removeClass } from '../utils/CssUtils';
import { addEventListener, preventDefault } from '../utils/EventUtils';
import { createHTMLElement } from '../utils/HtmlUtils';
import { closest, hasProperty } from '../utils/JsUtils';
import AJAXRequest from '../utils/AJAXRequest';


export default class AbstractForm extends AbstractComponent {
    constructor( el, classname ) {
        Logger.log( 'AbstractForm->constructor()' );
        super( el );

        // Checking abstract class instantiation
        if ( this.constructor === AbstractComponent ) {
            throw new TypeError( 'Abstract class "AbstractComponent" cannot be instantiated directly.' ); 
        }

        // Checking abstract methods
        if ( ( this.checkFormFields === undefined ) 
          || ( this.getAjaxAction === undefined )
          || ( this.resetFormFields === undefined ) ) {
            throw new TypeError( 'Classes extending the "AbstractForm" abstract class need to implement all required methods.' );
        }

        this.classname = classname;
        this.formClassname = 'c-form';
        this.isFormEnabled = true;
        this.needsResetAfterSuccess = false;
        this.initDomElements();
        this.addEventListeners();
    }


    initDomElements() {
        this.$type = this.$el.getAttribute( 'data-type' );
        this.$form = this.$el.querySelector( '.' + this.formClassname + '__form' );
        this.$formIdInput = this.$el.querySelector( '[name="form_id"]' );
        this.$formPageInput = this.$el.querySelector( '[name="form_page"]' );
        this.$newsletterField = this.$el.querySelector( '[name="form_newsletter"]' );
        this.$submitButton = this.$el.querySelector( '[name="form_submit"]' );

    }


    addEventListeners() {
        if ( this.$form ) {
            addEventListener( this.$form, 'submit', this.submit.bind( this ) );
        }
    }


    submit( e ) {
        preventDefault( e );
        if ( this.isFormEnabled ) {
            this.isFormEnabled = false;
            
            // console.log( this.getFormData() ); return;

            const checkFormFields = this.checkFormFields();
            if ( checkFormFields ) {
                this.executeRecaptcha();
            } else {
                this.showErrorSubmitButton();
            }
        }
    }


    executeRecaptcha() {
        if ( grecaptcha !== undefined ) {
            addClass( this.$submitButton, this.formClassname + '__submit--progress' );

            // Removing previously created recaptcha & reset related fields
            if ( this.$recaptcha ) {
                this.$submitButton.parentElement.removeChild( this.$recaptcha );
                this.$recaptcha = null;
                this.recaptchaWidget = null;
                this.recaptchaValue = null;
            }
    
            // Initializing & executing a new Recaptcha
            this.$recaptcha = createHTMLElement( 'div', { classname: this.formClassname + '__recaptcha' } );
            this.$submitButton.parentElement.appendChild( this.$recaptcha );
            try {
                this.recaptchaWidget = grecaptcha.render( this.$recaptcha, {
                    'sitekey': window.FNC.recaptcha_site_key,
                    'size': 'invisible',
                    'callback': this.recaptchaCallback.bind( this ),
                    'error-callback': this.showErrorSubmitButton.bind( this )
                } );
                grecaptcha.execute( this.recaptchaWidget );
            } catch ( error ) {
                this.showErrorSubmitButton();
            }
        } else {
            this.showErrorSubmitButton();
        }
    }


    recaptchaCallback() {
        this.recaptchaValue = grecaptcha.getResponse( this.recaptchaWidget );

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push( { 'event': 'Formulaire' } );

        new AJAXRequest( {
            'url': window.FNC.ajax_url + '?action=' + this.getAjaxAction(),
            'data': this.getFormData(),
            'onSuccess': this.handleAjaxResponse.bind( this ),
            'onError': this.showErrorSubmitButton.bind( this )
        } );
    }


    getFormData() {
        const data = [];

        // ID
        if ( this.$formIdInput ) {
            data.push( { name: 'id', value: this.$formIdInput.value } );
        }

        // Page
        if ( this.$formPageInput ) {
            data.push( { name: 'page', value: this.$formPageInput.value } );
        }

        // Newsletter
        if ( this.$newsletterField ) {
            data.push( { name: 'newsletter', value: this.$newsletterField.checked } );
        }

        // Recaptcha
        data.push( { name: 'recaptcha', value: this.recaptchaValue } );

        return data;
    }


    handleAjaxResponse( response ) {
        if ( response && hasProperty( response, 'success' ) && response.success ) {
            this.showSuccessSubmitButton();
        } else {
            this.showErrorSubmitButton();
        }
    }


    showSuccessSubmitButton() {
        removeClass( this.$submitButton, this.formClassname + '__submit--progress' );
        addClass( this.$submitButton, this.formClassname + '__submit--success' );
        setTimeout( () => {
            this.isFormEnabled = true;
            this.needsResetAfterSuccess = true;
        }, 1500 );
    }


    showErrorSubmitButton() {
        removeClass( this.$submitButton, this.formClassname + '__submit--progress' );
        const submitErrorClass = this.formClassname + '__submit--error';
        addClass( this.$submitButton, submitErrorClass );
        setTimeout( () => {
            removeClass( this.$submitButton, submitErrorClass );
            this.$submitButton.blur();
            this.isFormEnabled = true;
        }, 1500 );
    }


    checkResetAfterSuccess() {
        if ( this.needsResetAfterSuccess ) {
            this.needsResetAfterSuccess = false;
            removeClass( this.$submitButton, this.formClassname + '__submit--success' );
            this.resetFormFields();
        }
    }


    checkField( field, checkEmpty = null, checkValid = null ) {
        if ( field ) {
            const value = field.value;
            if ( checkEmpty ) {
                const isEmpty = checkEmpty( value );
                if ( isEmpty ) {
                    if ( field != document.activeElement ) {
                        this.showFieldError( field, 'empty' );
                    }
                    return false;
                }
            }
            if ( checkValid ) {
                const isValid = checkValid( value );
                if ( !isValid ) {
                    this.showFieldError( field, 'invalid' );
                    return false;
                }
            }
            this.hideFieldError( field );
        }
        return true;
    }


    checkFieldCheckbox( fields, checkChecked = true ) {
        if ( fields ) {
            const checkedFields = this.getCheckedFields( fields );
            if ( checkChecked && ( checkedFields.length == 0 ) ) {
                this.showFieldError( fields[0], 'checked' );
                return false;
            }
            this.hideFieldError( fields[0] );
        }
        return true;
    }
    
    
    getCheckedFields( fields ) {
        const checkedFields = [];
        for ( const field of fields ) {
            if ( field.checked ) {
                checkedFields.push( field );
            }
        }
        return checkedFields;
    }
    
    
    getCheckedValues( fields ) {
        const checkedValues = [];
        const checkedFields = this.getCheckedFields( fields );
        for ( const checkedField of checkedFields ) {
            checkedValues.push( checkedField.value );
        }
        return checkedValues;
    }


    isEmpty( s ) {
        if ( s && ( s !== '' ) && ( s.trim() !== '' ) ) {
            return false;
        }
        return true;
    }


    isValidEmail( email ) {
        if ( email && ( email !== '' ) && ( email.trim() !== '' ) ) {
            const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            return regex.test( email );
        }
        return false;
    }


    showFieldError( field, attribute ) {
        const fieldParent = closest( field, '.' + this.formClassname + '__field' );
        if ( fieldParent ) {
            addClass( fieldParent, this.formClassname + '__field--error' );
            const errorElement = fieldParent.querySelector( '.' + this.formClassname + '__error' );
            if ( errorElement ) {
                const message = errorElement.getAttribute( 'data-message-' + attribute );
                if ( message ) {
                    errorElement.innerHTML = message;
                }
            }
        }
    }


    hideFieldError( field ) {
        const fieldParent = closest( field, '.' + this.formClassname + '__field' );
        if ( fieldParent ) {
            removeClass( fieldParent, this.formClassname + '__field--error' );
            const errorElement = fieldParent.querySelector( '.' + this.formClassname + '__error' );
            if ( errorElement ) {
                errorElement.innerHTML = '';
            }
        }
    }


    destroy() {}
}