import AbstractComponent from "../AbstractComponent";
import Logger from '../../utils/Logger';
import { addEventListener, removeEventListener, preventDefault } from "../../utils/EventUtils";
import Chart from 'chart.js';
// import Flickity from 'flickity';


export default class MasterCharts extends AbstractComponent {
    constructor(...args) {
        Logger.log('MasterCharts->constructor()');
        super(...args);

        this.classname = 'c-master-charts';
        this.initDomElements();
        this.initCharts();
        this.addEventListeners();
    }

    initDomElements() {

        this.$charts = document.querySelectorAll( '.' + this.classname + '__content__item' );
    }

    initCharts() {

        this.Charts = [];

        if( this.$charts.length > 0 ) {
            for( var i = 0, j = this.$charts.length; i < j; i++ )
            {
                var Chart = this.createChart( this.$charts[i] );
                this.Charts.push( Chart );
            }
        }
    }

    createChart( el ) {

        // Define type of chart
        var item_type = el.getAttribute( 'data-type' );

        var ChartOption = this.getChartOptionsByType( item_type );

        if( item_type.includes( 'pie' ) )
            item_type = 'pie'

        // Get data values
        var item_data = JSON.parse( el.getAttribute( 'data-segments' ) );

        // Get data labels
        var item_labels = JSON.parse( el.getAttribute( 'data-labels' ) );

        // Get background color
        var item_bg_colors = JSON.parse( el.getAttribute( 'data-bg-colors' ) );

        // Define canvas
        var item_canvas = el.querySelector( 'canvas' ).getContext('2d');

        var chartData = {
            datasets: [item_data],
            labels: item_labels,
            backgroundColor: item_bg_colors
        };

        // Common global options
        Chart.defaults.global.defaultFontFamily = 'TT Commons';
        Chart.defaults.global.defaultFontStyle = 'bold';
        Chart.defaults.global.defaultFontSize = 12;
        Chart.defaults.global.tooltips.yPadding = 12;
        Chart.defaults.global.tooltips.xPadding = 12;
        Chart.defaults.global.tooltips.bodyFontSize = 16;
        Chart.defaults.global.tooltips.displayColors = false;

        // Instanciate chart
        var ChartItem = new Chart( item_canvas, {
            type: item_type,
            data: chartData,
            options: ChartOption
        });



        return Chart;
    }

    getChartOptionsByType( type ) {
        // Default options
        var chartOptions = {
            responsive: true,
            maintainAspectRatio: true,
            legend: {
                display: false
            },
            tooltips: {
                enabled: true,
                mode: 'single',
                filter:function(tooltipItem, data) {
                    return typeof data.labels[tooltipItem.index] != 'undefined';
                },
                callbacks: {
                    label: (tooltipItem, data) => {
                        var label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + '%';
                        if( typeof data.labels[tooltipItem.index] != 'undefined' && type != 'bar' && type != 'horizontalBar' )
                            label = label + ' : ' + data.labels[tooltipItem.index];

                        return this.trimText( label );
                    }
                }
            },
            scales: {
                xAxes: [],
                yAxes: []
            }
        }

        // Override options by type
        if( type == 'bar' ) {
            chartOptions.fontStyle = 'normal';
            chartOptions.tooltips.fontFamily = 'Ivar';
            chartOptions.tooltips.fontStyle = 'normal';
            chartOptions.scales.yAxes.push( { display: false } );
            chartOptions.scales.xAxes.push( { display: false } );
        }
        if( type == 'horizontalBar' ) {
            chartOptions.fontStyle = 'normal';
            chartOptions.tooltips.fontFamily = 'Ivar';
            chartOptions.tooltips.fontStyle = 'normal';
            chartOptions.scales.yAxes.push( { display: false } );
            chartOptions.scales.xAxes.push( { display: false } );
        }
        if( type == 'pie_simple' ) {
            chartOptions.tooltips.enabled = false;
        }

        return chartOptions;
    }


    trimText( text, length = 27 ) {
        if ( text ) {
            return text.length > length ? text.substring( 0, length ) + '…' : text;
        }
        return text;
    }



    addEventListeners() {
    }


    removeEventListeners() {
    }


    destroy() {
        Logger.log( 'MasterCharts->destroy()' );

        this.removeEventListeners();
    }
}