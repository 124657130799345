import AbstractComponent from "./AbstractComponent";
import { addClass, css, removeClass } from '../utils/CssUtils';
import { addEventListener } from '../utils/EventUtils';
import Logger from '../utils/Logger';
import YouTubePlayer from 'youtube-player';
import VimeoPlayer from '@vimeo/player';
import { convertToBoolean } from "../utils/JsUtils";

export default class VideoEmbed extends AbstractComponent {
    constructor(...args) {
        Logger.log( 'VideoEmbed->constructor()' );
        super(...args);

        this.classname = 'c-video-embed';
        this.initDomElements();
        this.initVideoPlayer();
        this.addEventListeners();
    }


    initDomElements() {
        this.$video = this.$el.querySelector( '.' + this.classname + '__video' );
        this.$iframe = this.$el.querySelector( '.' + this.classname + '__video__iframe' );
        this.$cover = this.$el.querySelector( '.' + this.classname + '__cover' );
    }


    addEventListeners() {
        if ( this.$cover ) {
            this._play = this.play.bind( this );
            addEventListener( this.$cover, 'click', this._play );
        }
        // if ( this.videoPlayer ) {
        //     switch ( this.videoProvider ) {
        //         case 'youtube':
        //             this.videoPlayer.on( 'stateChange', ( event ) => {
        //                 if ( event.data == YT.PlayerState.PAUSED ) {
        //                     this.pauseVideo();
        //                 }
        //             } );
        //             break;
        //     }
        // }
    }


    initVideoPlayer() {
        if ( this.$video ) {
            this.videoId = this.$video.getAttribute( 'data-video-id' );
            this.videoProvider = this.$video.getAttribute( 'data-video-provider' );
            this.videoAutoplay = convertToBoolean( this.$video.getAttribute( 'data-video-autoplay' ) );
            switch ( this.videoProvider ) {
                case 'youtube':
                    this.initVideoPlayerYouTube();
                    break;
                case 'dailymotion':
                    // TODO
                    break;
                case 'vimeo':
                    this.initVideoPlayerVimeo();
                    break;
            }
            if ( this.videoAutoplay ) {
                this.play();
            }
        }
    }


    initVideoPlayerYouTube() {
        if ( this.videoId && this.$iframe ) {
            this.videoPlayer = YouTubePlayer( this.$iframe, {
                videoId: this.videoId,
                host: 'https://www.youtube-nocookie.com',
                playerVars: {
                    autoplay: ( this.videoAutoplay ? 1 : 0 ),
                    color: 'white',
                    playsinline: 0
                }
            } );
        }
    }


    initVideoPlayerVimeo() {
        if ( this.videoId ) {
            this.videoPlayer = new VimeoPlayer( this.$video, {
                id: this.videoId,
                autoplay: ( this.videoAutoplay ? 'true' : 'false' ),
                color: 'ffffff',
                dnt: true,
                playsinline: false
            } );
        }
    }

    
    play() {
        addClass( this.$el, this.classname + '--hide-cover' );
        this.videoPlayerPlay();
    }


    videoPlayerPlay() {
        if ( this.videoPlayer ) {
            switch ( this.videoProvider ) {
                case 'youtube':
                    return this.videoPlayer.playVideo();
                case 'vimeo':
                    return this.videoPlayer.play();
            }
        }
    }


    pause() {
        removeClass( this.$el, this.classname + '--hide-cover' );
        this.videoPlayerPause();
    }


    videoPlayerPause() {
        if ( this.videoPlayer ) {
            switch ( this.videoProvider ) {
                case 'youtube':
                    return this.videoPlayer.pauseVideo();
                case 'vimeo':
                    return this.videoPlayer.pause();
            }
        }
    }


    reset() {
        removeClass( this.$el, this.classname + '--hide-cover' );
        this.videoPlayerReset();
    }


    videoPlayerReset() {
        if ( this.videoPlayer ) {
            switch ( this.videoProvider ) {
                case 'youtube':
                    this.videoPlayer.pauseVideo();
                    this.videoPlayer.seekTo( 0 );
                    return this.videoPlayer.pauseVideo();
                case 'vimeo':
                    this.videoPlayer.pause();
                    return this.videoPlayer.setCurrentTime( 0 );
            }
        }

    }


    destroy() {}
}