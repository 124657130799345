import AbstractComponent from "./AbstractComponent";
import { addClass, removeClass } from '../utils/CssUtils';
import { addEventListener, preventDefault } from '../utils/EventUtils';
import Logger from '../utils/Logger';
import ClipboardJS from 'clipboard';


export default class MobileSharingBar extends AbstractComponent {
    constructor(...args) {
        Logger.log( 'MobileSharingBar->constructor()' );
        super(...args);

        this.classname = 'c-mobile-sharing-bar';
        this.initDomElements();
        this.addEventListeners();
    }


    initDomElements() {
        this.$sharingItemsPopup = this.$el.querySelectorAll( '.js-' + this.classname + '__item--popup' );
        this.$sharingClipboard = this.$el.querySelector( '.js-' + this.classname + '__item--clipboard' );
    }


    addEventListeners() {
        addEventListener( this.$sharingItemsPopup, 'click', this.openSharingPopup.bind( this ) );
        this.addCopyUrlToClipboardListener();
    }


    openSharingPopup( e ) {
        preventDefault( e );
        const target = e.currentTarget;
        const width = 650;
        const height = 450;
        const left = ( screen.width / 2 ) - ( width / 2 );
        var top = ( screen.height / 2 ) - ( height / 2 );
        window.open( target.href, 'Partage', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left );
    }


    addCopyUrlToClipboardListener() {
        const copyUrlToClipboard = new ClipboardJS( this.$sharingClipboard );
        let copyUrlToClipboardOverlayTimeout = null;
        copyUrlToClipboard.on( 'success', ( e ) => {
            clearTimeout( copyUrlToClipboardOverlayTimeout );
            addClass( this.$sharingClipboard.parentNode, this.classname + '__item--show-overlay' );
            copyUrlToClipboardOverlayTimeout = setTimeout(
                () => removeClass( this.$sharingClipboard.parentNode, this.classname + '__item--show-overlay' ),
                1500
            );
            e.clearSelection();
        } );
    }


    destroy() {}
}
