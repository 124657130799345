import { addClass, hasClass, removeClass } from '../utils/CssUtils';
import AbstractComponent from "./AbstractComponent";
import Logger from '../utils/Logger';
import Sticky from '../utils/Sticky';
import queryString from "query-string";
import { TweenMax, Cubic, ScrollToPlugin } from "gsap/all";
import gsap from 'gsap';
import {addEventListener} from "../utils/EventUtils";
gsap.registerPlugin(ScrollToPlugin);


export default class FaqFilters extends AbstractComponent {
    constructor(...args) {
        Logger.log( 'FaqFilters->constructor()' );
        super(...args);

        this.classname = 'c-faq-filters';
        this.is_process = false;
        this.scroll_duration = 1;

        this.initDomElements();
        this.addEventListeners();

        // Query Filters check
        this.urlQuery = queryString.parseUrl( window.location.href );
        if( Object.keys( this.urlQuery.query ).length > 0 ) {
            this.preFillFilters();
        }

        this.initStickyFilters();
    }


    initDomElements() {
        this.$navbar = document.body.querySelector( '.c-site-menu__navbar' );
        this.$filter_category = this.$el.querySelector( 'select' );
        this.$filter_category_label = this.$el.querySelector( '.' + this.classname + '__container__label' );
        this.$list_container = document.body.querySelector( '.c-faq-list' );
    }


    addEventListeners() {
        // Filter category
        if( this.$filter_category ) {
            this._filterCategoryListener = this.processFilter.bind( this, 'category' );
            addEventListener( this.$filter_category, 'change', this._filterCategoryListener );
        }

        // On Reveal
        this.app.on( 'reveal_page_transition_end', this.scrollTo.bind( this ) );

        // Resize
        this.app.on( 'resize', this.resize.bind(this) );
    }

    initStickyFilters() {
        this.StickyFilters = new Sticky( this.$el, {
            is_bounded: true,
            referer: this.$list_container,
            offset: this.$navbar.getBoundingClientRect().height + 50,
            positionFrom: 'top'
        });

        this.resetSticky();
    }

    resetSticky() {
        // Set new offset and resize
        var baseOffset = this.$navbar.getBoundingClientRect().height;
        var offset = baseOffset + ( this.$filter_category.getBoundingClientRect().height / 2 ) + 15; // XS default
        if( this.app.breakpoint == 'sm' || this.app.breakpoint == 'md' )
            offset = baseOffset + 40;
        if( this.app.breakpoint == 'lg' || this.app.breakpoint == 'xl' || this.app.breakpoint == 'xxl'  )
            offset = baseOffset + 50;

        this.StickyFilters.setOffset( offset );
        this.StickyFilters.resize();
    }

    preFillFilters() {

        // Prefill News Category
        if( this.urlQuery.query['c'] ) {
            for( var i = 0, j = this.$filter_category.options.length; i < j; i++ ) {
                if( this.$filter_category.options[i].getAttribute( 'data-slug' ) == this.urlQuery.query['c'] ) {
                    this.$filter_category.value = this.$filter_category.options[i].value;
                    this.faq_category = [ this.$filter_category.value ];
                    this.faq_category_slug = this.$filter_category.options[i].getAttribute( 'data-slug' );
                }
            }
        }
    }

    processFilter() {

        if( this.is_process == false ) {

            this.is_process = true;

            document.body.classList.add( 'is-loading' );
            this.$filter_category_label.classList.add( 'is-loading' );

            // Prepare scroll data
            this.faq_category_slug = ( this.$filter_category.value ) ? this.$filter_category.options[this.$filter_category.options.selectedIndex].getAttribute( 'data-slug' ) : '';

            // Format URL filters
            if( this.faq_category_slug ) {
                let newQueryUrl = {
                    c: this.faq_category_slug
                };
                let newUrlParams = queryString.stringify( newQueryUrl );
                let url = ( newUrlParams.length > 0 ) ? this.urlQuery.url + '?' + newUrlParams : this.urlQuery.url;
                history.pushState( newUrlParams, null, url );
            } else
                history.pushState( null, null, this.urlQuery.url );


            // Send request
            this.scrollTo();
        }

    }

    scrollTo() {
        var target = document.getElementById( this.faq_category_slug );
        if( target ) {
            var offset = window.pageYOffset + target.getBoundingClientRect().top - this.$navbar.getBoundingClientRect().height - 40;

            TweenMax.to( window, this.scroll_duration, { scrollTo: { y: offset, autoKill: false } , ease: Cubic.easeOut, onComplete: () => {
                document.body.classList.remove( 'is-loading' );
                this.$filter_category_label.classList.remove( 'is-loading' );

                this.is_process = false;
            } } );
        } else {
            document.body.classList.remove( 'is-loading' );
            this.$filter_category_label.classList.remove( 'is-loading' );
            this.is_process = false;
        }
    }

    resize() {
        this.resetSticky();
    }

    destroy() {}
}