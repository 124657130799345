import AbstractPageController from './AbstractPageController';
import { addClass, css, removeClass } from '../utils/CssUtils';
import { addEventListener } from '../utils/EventUtils';
import { convertHTMLStringToDOMElements } from '../utils/HtmlUtils';
import { hasProperty } from '../utils/JsUtils';
import Logger from '../utils/Logger';
import Request from '../utils/Request';
import Sticky from '../utils/Sticky';
import { debounce } from 'lodash';
import queryString from 'query-string';
import Masonry from 'masonry-layout';
import { TweenMax, Cubic, ScrollToPlugin } from "gsap/all";
import gsap from 'gsap';
import { setTimeout } from 'core-js';
gsap.registerPlugin(ScrollToPlugin);


export default class PhotosVideosController extends AbstractPageController {
    constructor( ...args ) {
        super( ...args );
        Logger.log( 'PhotosVideosController->constructor()' );

        this.action = 'fnc_photos_videos_ajax';
        this.popinFromHistory = false;
        this.is_process = false;
        this.params = {
            page: 1,
            topic: null,
            topicSlug: null,
            type: null
        };
        this.loader_duration = 1500;

        this.initDomElements();
        this.addEventListeners();
        this.initMasonryGrid();
        this.initStickyFilters();

        // Query Filters check
        this.urlQuery = queryString.parseUrl( window.location.href );
        if ( Object.keys( this.urlQuery.query ).length > 0 ) {
            this.preFillFilters();
        }

        this.checkOnLoadPopin();

        this.ready();
    }


    initDomElements() {
        this.$navbar = document.body.querySelector( '.c-site-menu__navbar' );
        this.$header = document.body.querySelector( '.c-header__content__title' );
        this.$container = document.body.querySelector( '.js-photos-videos-container' );
        this.$grid = document.body.querySelector( '.js-photos-videos-grid' );
        this.$loadMore = document.body.querySelector( '.js-photos-videos-load-more' );
        this.$filtersContainer = document.body.querySelector('.c-photos-videos-filters');
        this.$filterLabel = document.body.querySelector('.c-photos-videos-filters__container__label');
        this.$filterTopic = document.body.querySelector('.js-photos-videos-filter-topic select');
        this.$filterType = document.body.querySelector('.js-photos-videos-filter-type select');
    }


    addEventListeners() {
        // Load more click
        this._loadMoreListener = this.processLoadMore.bind( this );
        addEventListener( this.$loadMore, 'click', this._loadMoreListener );

        // Filter topic
        if ( this.$filterTopic ) {
            this._filterTopicListener = this.processFilter.bind( this );
            addEventListener( this.$filterTopic, 'change', this._filterTopicListener );
        }

        // Filter type
        if ( this.$filterType ) {
            this._filterTypeListener = this.processFilter.bind( this );
            addEventListener( this.$filterType, 'change', this._filterTypeListener );
        }


        addEventListener( window, 'popstate', ( event ) => {
            this.popinFromHistory = true;
            if ( hasProperty( event.state, 'code' ) ) {
                this.openPopinForId( event.state.id );
            } else {
                this.closePopin();
            }
        } );

        addEventListener( window, 'resize', debounce( this.resize.bind( this ), 16 ) );
    }


    initMasonryGrid() {
        this.masonryGrid = new Masonry( this.$grid, {
            itemSelector: '.c-photos-videos-grid-item',
            percentPosition: true,
            columnWidth: '.c-photos-videos-item--column-width',
            gutter: 30,
            transitionDuration: '0.2s'
        } );
    }


    initStickyFilters() {
        if( this.$filtersContainer ) {
            this.StickyFilters = new Sticky( this.$filtersContainer, {
                is_bounded: true,
                referer: this.$container,
                offset: document.body.querySelector( '.c-site-menu__navbar' ).getBoundingClientRect().height + this.$filtersContainer.getBoundingClientRect().height / 2,
                positionFrom: 'top'
            } );
            this.resetSticky();
        }
    }


    preFillFilters() {
        // Topic
        const topic = this.urlQuery.query['c']
        if ( topic ) {
            const topicValue = this.findValueForSlugInSelectOptions( this.$filterTopic, topic );
            if ( topicValue ) {
                this.$filterTopic.value = topicValue;
                this.params.topic = [ topicValue ];
                this.params.topicSlug = topic;
            }
        }

        // Type
        const type = this.urlQuery.query['t'];
        if ( type ) {
            this.$filterType.value = type;
            this.params.type = [ type ];
        }
    }


    findValueForSlugInSelectOptions( select, slug ) {
        for ( var i = 0; i < select.options.length; i++ ) {
            if ( select.options[i].getAttribute( 'data-slug' ) == slug ) {
                return select.options[i].value;
            }
        }
        return null;
    }


    showLoader( el ) {
        this.current_loader = el;

        // Show loading classes
        addClass( document.body, 'is-loading' );
        addClass( this.current_loader, 'is-loading' );

        // Force delay to avoid too fast data load...
        this.loader_timeout = setTimeout( () => {
            this.loader_timeout = null;
            this.hideLoader( true );
            clearTimeout( this.loader_timeout );
        }, this.loader_duration );
    }


    hideLoader( fromTimeout = false ) {
        if ( this.is_process == false ) {
            if ( fromTimeout == true || this.loader_timeout == null ) {
                removeClass( document.body, 'is-loading' );
                removeClass( this.current_loader, 'is-loading' );
                this.showResults();
            }
        }
    }


    processFilter() {
        if ( this.is_process ) {
            return;
        }

        this.is_process = true;
        this.scrollTop();
        this.showLoader( this.$filterLabel );

        // Prepare request data
        this.params.page = 1;
        this.params.topic = null;
        this.params.topicSlug = null;
        if ( this.$filterTopic.value ) {
            this.params.topic = this.$filterTopic.value;
            this.params.topicSlug = this.findSlugForValueInSelectOptions( this.$filterTopic, this.$filterTopic.value );
        }
        this.params.type = null;
        if ( this.$filterType.value ) {
            this.params.type = this.$filterType.value;
        }

        // Update page URL with filters
        let url = this.urlQuery.url;
        if ( this.params.topic || this.params.type ) {
            const queryParams = {};
            if ( this.params.topic ) {
                queryParams['c'] = this.params.topicSlug;
            }
            if ( this.params.type ) {
                queryParams['t'] = this.params.type;
            }
            url += '?' + queryString.stringify( queryParams );
        }
        history.replaceState( null, null, url );

        // Send request
        this.request();
    }


    findSlugForValueInSelectOptions( select, value ) {
        for ( var i = 0; i < select.options.length; i++ ) {
            if ( select.options[i].value == value ) {
                return select.options[i].getAttribute( 'data-slug' );
            }
        }
        return null;
    }


    processLoadMore() {
        if ( this.is_process ) {
            return;
        }

        this.is_process = true;
        this.showLoader( this.$loadMore );

        // Prepare request data
        this.params.page = this.params.page + 1;

        // Send request
        this.request();
    }


    request() {
        Request.AJAX({
            'url': window.FNC.ajax_url + '?action=' + this.action,
            'data': this.params,
            'success': this.onSuccess.bind( this )
        } );
    }


    onSuccess( response ) {
        // Store data before replacing or appending it (to allow force loading)
        this.tempResponsePage = response.data.page;
        this.tempResponseItems = response.data.items_html;
        this.tempResponseItemsCount = response.data.items_count;
        this.tempResponseHasMore = response.data.has_more;

        // Unlock process
        this.is_process = false;
        this.hideLoader();
    }


    showLoadMore() {
        removeClass( this.$loadMore, 'u-d-none' );
    }


    hideLoadMore() {
        addClass( this.$loadMore, 'u-d-none' );
    }


    showResults() {
        // Results
        if ( this.tempResponseItemsCount > 0 ) {
            // Hide no results screen
            removeClass( this.$container, 'no-results' );

            if ( this.tempResponsePage == 1 ) {
                this.$grid.innerHTML = this.tempResponseItems;
                this.masonryGrid.destroy();
                this.initMasonryGrid();
            } else {
                const domItems = convertHTMLStringToDOMElements( this.tempResponseItems );
                const fragment = document.createDocumentFragment();
                for ( let i = 0; i < domItems.length; i++ ) {
                    fragment.appendChild( domItems[i] );
                }
                this.$grid.appendChild( fragment );
                this.masonryGrid.appended( domItems );
            }

            // Animate-in new items
            this.newItems = document.querySelectorAll( '.to-animate' );
            for( var i = 0, j = this.newItems.length; i < j; i++ ) {
                var delay = ( i + 1 ) * 100;
                this.animateResult( i, delay );
            }
        } else {
            // Disable grid
            this.$grid.innerHTML = '';
            this.masonryGrid.destroy();
            this.initMasonryGrid();

            // Show no results screen
            addClass( this.$container, 'no-results' );
        }

        // Check if there is more items to load
        if ( this.tempResponseHasMore ) {
            this.showLoadMore();
        } else {
            this.hideLoadMore();
        }

        if ( this.StickyFilters ) {
            this.StickyFilters.resize();
        }

        this.tempResponsePage = null;
        this.tempResponseItems = null;
        this.tempResponseItemsCount = null;
        this.tempResponseHasMore = null;
    }


    animateResult( index, delay ) {
        setTimeout( () => {
            removeClass( this.newItems[index], 'to-animate' );
        }, delay );
    }


    resize() {
        if ( this.StickyFilters ) {
            this.resetSticky();
        }
    }


    resetSticky() {
        // Set new offset and resize
        var baseOffset = document.body.querySelector( '.c-site-menu__navbar' ).getBoundingClientRect().height;
        var offset = baseOffset + 15;
        if ( window.innerWidth > 768 ) {
            offset = baseOffset + ( this.$filtersContainer.getBoundingClientRect().height / 2 ) + 25;
        }

        this.StickyFilters.setOffset( offset );
        this.StickyFilters.resize();
    }


    scrollTop() {
        var offset = window.pageYOffset + this.$header.getBoundingClientRect().top - this.$navbar.getBoundingClientRect().height - 30;
        TweenMax.to( window, 0.65, { scrollTo: { y: offset, autoKill: false } , ease: Cubic.easeOut } );
    }


    checkOnLoadPopin() {
        if ( hasProperty( window.FNC, 'photo_video_popin_id' ) ) {
            const id = window.FNC.photo_video_popin_id;
            history.replaceState( { id: id }, document.title, window.location );
            this.openPopinForId( id );
        }
    }


    openPopinForId( id ) {
        const type = 'photo-video';
        this.app.Popin.setType( type );
        this.app.Popin.open();
        this.app.Popin._requestData( type, id );

        // this.app.Popin.setType( 'federation' );
        // if ( !this.popinFromHistory && window.location != data.url ) {
        //     history.pushState( { id: id }, data.title, data.url );
        //     document.title = data.title;
        // }
        // if ( hasProperty( window.FNC, 'federations_popin_root_page' ) ) {
        //     this.app.Popin.setCloseHistory( window.FNC.federations_popin_root_page.title, window.FNC.federations_popin_root_page.url );
        // }
        // this.app.Popin.setContents( data.popin_html );
        // this.app.Popin.openAndReveal();
    }


    closePopin() {
        if ( this.popinFromHistory ) {
            this.app.Popin.setCloseHistory( null, null );
        }
        this.app.Popin.to_close();
    }


    update() {}


    destroy() {}
}
