import { getProperty } from './JsUtils';


export default class AJAXRequest {
    constructor( options ) {
        this.options = Object.assign( {
            method: 'POST',
            url: '',
            data: [],
            onSuccess: null,
            onError: null
        }, options );

        this.formData = new FormData();
        this.appendAll( this.options.data );
        this.send();
    }


    append( name, value ) {
        this.formData.append( name, value );
    }


    appendAll( array ) {
        for ( const item of array ) {
            const name = getProperty( item, 'name' );
            const value = getProperty( item, 'value' );

            if ( name && value ) {
                this.append( name, value );
            }
        }
    }


    send() {
        this.request = new XMLHttpRequest();
        this.request.open( this.options.method, this.options.url, true );
        this.request.setRequestHeader( 'X-Requested-With', 'XMLHttpRequest' );
        this.request.onreadystatechange = () => {
            if ( this.request.readyState === 4 ) {
                if ( this.request.status === 200 ) {
                    try {
                        if ( this.options.onSuccess ) {
                            this.options.onSuccess.call( this.options.onSuccess, JSON.parse( this.request.response ) );
                        }
                        return;
                    } catch ( error ) {
                        // Continue until the error callback is called
                    }
                }
                
                if ( this.options.onError ) {
                    this.options.onError.call( this.options.onError, this.request.response );
                }
                
                return;
            }
        };
        this.request.send( this.formData );
    }
}