import AbstractForm from '../AbstractForm';
import Logger from '../../utils/Logger';
import { addClass, removeClass } from '../../utils/CssUtils';
import { addEventListener, preventDefault } from '../../utils/EventUtils';
import { closest, convertToBoolean } from '../../utils/JsUtils';


export default class MasterForm extends AbstractForm {
    constructor( el ) {
        Logger.log('MasterForm->constructor()');
        super( el, 'c-master-form' );
    }


    initDomElements() {
        super.initDomElements();

        this.$fields = this.$el.querySelectorAll( '.' + this.classname + '__fields' );
        this.$subjectField = this.$el.querySelector( '[name="form_subject"]' );
        this.$subjectMessage = this.$el.querySelector( '.' + this.classname + '__subject-message' );
        this.$emailField = this.$el.querySelector( '[name="form_email"]' );
        this.$firstnameField = this.$el.querySelector( '[name="form_firstname"]' );
        this.$lastnameField = this.$el.querySelector( '[name="form_lastname"]' );
        this.$departementField = this.$el.querySelector( '[name="form_departement"]' );
        this.$telephoneField = this.$el.querySelector( '[name="form_telephone"]' );
        this.$messageField = this.$el.querySelector( '[name="form_message"]' );
        this.$filesField = closest( this.$el.querySelector( '.' + this.formClassname + '__files' ), '.' + this.formClassname + '__field' );
        this.$files = this.$el.querySelector( '.' + this.formClassname + '__files' );
        this.$file1Field = this.$el.querySelector( '[name="form_file_1"]' );
        this.$file2Field = this.$el.querySelector( '[name="form_file_2"]' );
        this.$file3Field = this.$el.querySelector( '[name="form_file_3"]' );
        this.$file4Field = this.$el.querySelector( '[name="form_file_4"]' );
    }


    addEventListeners() {
        super.addEventListeners();

        if ( this.$subjectField ) {
            addEventListener( this.$subjectField, 'focus', () => this.checkResetAfterSuccess() );
            addEventListener( this.$subjectField, 'blur', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$subjectField, this.isEmpty );
            } );
            addEventListener( this.$subjectField, 'change', () => {
                this.checkResetAfterSuccess();
                this.subjectChange();
                this.checkField( this.$subjectField, this.isEmpty );
            } );
        }

        if ( this.$emailField ) {
            addEventListener( this.$emailField, 'focus', () => this.checkResetAfterSuccess() );
            addEventListener( this.$emailField, 'blur', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$emailField, this.isEmpty, this.isValidEmail );
            } );
            addEventListener( this.$emailField, 'keyup', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$emailField, this.isEmpty, this.isValidEmail );
            } );
        }

        if ( this.$departementField ) {
            addEventListener( this.$departementField, 'focus', () => this.checkResetAfterSuccess() );
            addEventListener( this.$departementField, 'blur', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$departementField, this.isEmpty );
            } );
            addEventListener( this.$departementField, 'change', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$departementField, this.isEmpty );
            } );
        }

        if ( this.$messageField ) {
            addEventListener( this.$messageField, 'focus', () => this.checkResetAfterSuccess() );
            addEventListener( this.$messageField, 'blur', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$messageField, this.isEmpty );
            } );
            addEventListener( this.$messageField, 'keyup', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$messageField, this.isEmpty );
            } );
        }

        const fileFields = [ this.$file1Field, this.$file2Field, this.$file3Field, this.$file4Field ];
        for ( const fileField of fileFields ) {
            if ( fileField ) {
                this.addFileEventListener( fileField );
            }
        }
    }


    addFileEventListener( fileField ) {
        addEventListener( fileField, 'change', () => this.fileFieldChange( fileField ) );

        const fileFieldResetButton = fileField.parentElement.querySelector( '.' + this.formClassname + '__file-reset' );
        if ( fileFieldResetButton ) {
            addEventListener( fileFieldResetButton, 'click', ( e ) =>{
                preventDefault( e );
                this.fileFieldReset( fileField );
            } );
        }
    }


    checkFormFields() {
        const checkAllFields = [];

        if ( this.$subjectField ) {
            checkAllFields.push( this.checkField( this.$subjectField, this.isEmpty, null ) );
        }

        if ( this.$emailField ) {
            checkAllFields.push( this.checkField( this.$emailField, this.isEmpty, this.isValidEmail ) );
        }

        if ( this.$departementField ) {
            checkAllFields.push( this.checkField( this.$departementField, this.isEmpty, null ) );
        }

        if ( this.$messageField ) {
            checkAllFields.push( this.checkField( this.$messageField, this.isEmpty, null ) );
        }
        
        if ( this.$files && this.$file1Field && this.$file2Field && this.$file3Field && this.$file4Field ) {
            checkAllFields.push( this.checkFiles() );
        }

        return ( checkAllFields.indexOf( false ) === -1 );
    }


    getAjaxAction() {
        return 'fnc_master_form_ajax';
    }


    getFormData() {
        const data = super.getFormData();

        if ( this.$subjectField ) {
            data.push( { name: 'subject', value: this.$subjectField.value } );
        }

        if ( this.$emailField ) {
            data.push( { name: 'email', value: this.$emailField.value } );
        }

        if ( this.$firstnameField ) {
            data.push( { name: 'firstname', value: this.$firstnameField.value } );
        }

        if ( this.$lastnameField ) {
            data.push( { name: 'lastname', value: this.$lastnameField.value } );
        }

        if ( this.$departementField ) {
            data.push( { name: 'departement', value: this.$departementField.value } );
        }

        if ( this.$telephoneField ) {
            data.push( { name: 'telephone', value: this.$telephoneField.value } );
        }

        if ( this.$messageField ) {
            data.push( { name: 'message', value: this.$messageField.value } );
        }

        const fileFields = [ this.$file1Field, this.$file2Field, this.$file3Field, this.$file4Field ];
        for ( const fileField of fileFields ) {
            if ( fileField && fileField.files && ( fileField.files.length > 0 ) ) {
                for ( const file of fileField.files ) {
                    data.push( { name: 'files[]', value: file } );  
                }
            }
        }

        return data;
    }


    resetFormFields() {
        if ( this.$subjectField ) {
            this.$subjectField.value = '';
        }

        if ( this.$emailField ) {
            this.$emailField.value = '';
        }

        if ( this.$firstnameField ) {
            this.$firstnameField.value = '';
        }

        if ( this.$lastnameField ) {
            this.$lastnameField.value = '';
        }

        if ( this.$departementField ) {
            this.$departementField.value = '';
        }

        if ( this.$telephoneField ) {
            this.$telephoneField.value = '';
        }

        if ( this.$messageField ) {
            this.$messageField.value = '';
        }

        const fileFields = [ this.$file1Field, this.$file2Field, this.$file3Field, this.$file4Field ];
        for ( const fileField of fileFields ) {
            if ( fileField ) {
                this.fileFieldReset( fileField );
            }
        }

        if ( this.$newsletterField ) {
            this.$newsletterField.checked = false;
        }
    }


    subjectChange() {
        const value = this.$subjectField.value;
        if ( value ) {
            for ( let i = 0; i < this.$subjectField.options.length; i++ ) {
                const option = this.$subjectField.options[i];
                if ( value == option.value ) {
                    const showMessage = convertToBoolean( option.getAttribute( 'data-show-message' ) );
                    if ( showMessage ) {
                        const message = option.getAttribute( 'data-message' );
                        const hideForm = convertToBoolean( option.getAttribute( 'data-hide-form' ) );
                        this.showMessageAndHideForm( showMessage, message, hideForm );
                        return;
                    }
                }
            }
        }
        this.resetMessageAndShowForm();
    }


    showMessageAndHideForm( showMessage, message, hideForm ) {
        if ( showMessage ) {
            addClass( this.$form, this.classname + '__form--show-message' );
        } else {
            removeClass( this.$form, this.classname + '__form--show-message' );
        }

        if ( hideForm ) {
            addClass( this.$form, this.classname + '__form--hide-fields' );
        } else {

            removeClass( this.$form, this.classname + '__form--hide-fields' );
        }

        this.$subjectMessage.innerHTML = message;
    }


    resetMessageAndShowForm() {
        removeClass( this.$form, this.classname + '__form--show-message' );
        removeClass( this.$form, this.classname + '__form--hide-fields' );
        this.$subjectMessage.innerHTML = '';
    }


    fileFieldChange( fileField ) {
        const labelSpan = fileField.nextElementSibling.querySelector( 'span' );

        if ( fileField.files && ( fileField.files.length > 0 ) ) {
            addClass( fileField, this.formClassname + '__file--selected' );
            if ( fileField.files.length == 1 ) {
                labelSpan.innerHTML = fileField.value.split( '\\' ).pop().normalize();
            } else {
                const labelMultiple = fileField.getAttribute( 'data-label-multiple' ) || '';
                labelSpan.innerHTML = labelMultiple.replace( '{count}', fileField.files.length );
            }
        } else {
            removeClass( fileField, this.formClassname + '__file--selected' );
            const labelEmpty = fileField.getAttribute( 'data-label-empty' ) || '';
            labelSpan.innerHTML = labelEmpty;
        }
        fileField.blur();
        this.updateFilesClass();
        this.checkFiles();
    }


    updateFilesClass() {
        const filesClassShow2 = this.formClassname + '__files--show-2';
        const filesClassShow3 = this.formClassname + '__files--show-3';
        const filesClassShow4 = this.formClassname + '__files--show-4';
        removeClass( this.$files, filesClassShow2 );
        removeClass( this.$files, filesClassShow3 );
        removeClass( this.$files, filesClassShow4 );

        let filesClassShow = null; 
        if ( this.$file1Field && this.$file1Field.files && ( this.$file1Field.files.length > 0 ) ) {
            filesClassShow = filesClassShow2;
        }
        if ( this.$file2Field && this.$file2Field.files && ( this.$file2Field.files.length > 0 ) ) {
            filesClassShow = filesClassShow3;
        }
        if ( this.$file3Field && this.$file3Field.files && ( this.$file3Field.files.length > 0 ) ) {
            filesClassShow = filesClassShow4;
        }
        if ( filesClassShow ) {
            addClass( this.$files, filesClassShow );
        }
    }


    checkFiles() {
        let filesSize = 0;
        if ( this.$file1Field  ) {
            filesSize += this.getFileFieldFilesSize( this.$file1Field );
        }
        if ( this.$file2Field  ) {
            filesSize += this.getFileFieldFilesSize( this.$file2Field );
        }
        if ( this.$file3Field  ) {
            filesSize += this.getFileFieldFilesSize( this.$file3Field );
        }
        if ( this.$file4Field  ) {
            filesSize += this.getFileFieldFilesSize( this.$file4Field );
        }
        
        if ( this.$files ) {
            const sizeMax = parseInt( this.$files.getAttribute( 'data-size-max' ) || '10485760' ); // 10 Mo
            if ( filesSize > sizeMax ) {
                this.showFieldError( this.$filesField, 'size-max' );
                return false;
            }
            this.hideFieldError( this.$filesField );
        }

        return true;
    }


    getFileFieldFilesSize( fileField ) {
        let size = 0;
        if ( fileField && fileField.files && ( fileField.files.length > 0 ) ) {
            for ( const file of fileField.files ) {
                size += file.size;
            }
        }
        return size;
    }


    fileFieldReset( fileField ) {
        if ( fileField.files && ( fileField.files.length > 0 ) ) {
            try {
                fileField.value = '';
                if ( fileField.value ) {
                    fileField.type = 'text';
                    fileField.type = 'file';
                }
            } catch( e ) {}
            this.fileFieldChange( fileField );
        }
    }


    destroy() {}
}