import { addClass, removeClass } from '../utils/CssUtils';
import { addEventListener } from '../utils/EventUtils';
import Logger from '../utils/Logger';
import {closest} from "../utils/JsUtils";
import {debounce} from "lodash";
import AppController from "../controllers/AppController";


export default class SiteFooter {
    constructor() {
        Logger.log( 'SiteFooter->constructor()' );

        this.classname = 'c-site-footer';
        this.is_process = false;
        this.enableDropdown = ( AppController.getInstance().breakpoint == 'sm' || AppController.getInstance().breakpoint == 'xs' ) ? true : false;
        this.dropdowns = [];

        this.initDomElements();
        this.addEventListeners();
    }


    initDomElements() {
        this.$el = document.body.querySelector( '.' + this.classname );
        this.$sections = this.$el.querySelectorAll( '.c-menu-list__col' );
        if( this.$sections )
            this.initDropdownSections();
    }


    addEventListeners() {
        for( var i = 0, j = this.dropdowns.length; i < j; i++ ) {
            addEventListener( this.dropdowns[i].trigger, 'click', this.toggleSection.bind(this, i) );
        }

        addEventListener( window, 'resize', debounce( this.resize.bind(this), 16 ) );

        AppController.getInstance().on( 'breakpoint-update', ( newBreakpoint, oldBreakpoint ) => {
            if( newBreakpoint == 'sm' && oldBreakpoint == 'md' )
                this.close();
        } )
    }

    initDropdownSections() {
        for( var i = 0, j = this.$sections.length; i < j; i++ ) {
            var dropdown = {};
            dropdown.is_open = false;
            dropdown.element = this.$sections[i];
            dropdown.trigger = this.$sections[i].querySelector( '.c-menu-list__col__head' );
            dropdown.container = this.$sections[i].querySelector( '.c-menu-list__col__content' );
            dropdown.content = this.$sections[i].querySelector( '.c-menu-list__col__content ul' );
            dropdown.maxHeight = dropdown.content.getBoundingClientRect().height;
            this.dropdowns.push( dropdown );
        }

        if( AppController.getInstance().breakpoint == 'sm' || AppController.getInstance().breakpoint == 'xs' )
            this.close();

        this.resetDropdownSections();
    }

    toggleSection( index ) {
        if( this.is_process == false && this.enableDropdown == true ) {

            this.is_process = true;

            if( this.dropdowns[index].is_open == false ) {
                this.open( index );
            } else {
                this.close( index );
            }
        }
    }

    open( index = null ) {
        if( index != null ) {
            // open unique el
            this.dropdowns[index].element.classList.add( 'is-open' );
            this.dropdowns[index].container.style.maxHeight = this.dropdowns[index].maxHeight + 'px';
            this.dropdowns[index].is_open = true;
        } else {
            // open all dropdowns
            for( var i = 0, j = this.dropdowns.length; i < j; i++ ) {
                this.dropdowns[i].element.classList.add( 'is-open' );
                this.dropdowns[i].container.style.maxHeight = this.dropdowns[index].maxHeight + 'px';
                this.dropdowns[i].is_open = true;
            }
        }

        this.is_process = false;
    }

    close( index = null ) {

        if( index != null ) {
            // close unique el
            this.dropdowns[index].element.classList.remove( 'is-open' );
            this.dropdowns[index].container.style.maxHeight = '0px';
            this.dropdowns[index].is_open = false;
        } else {
            // close all dropdowns
            for( var i = 0, j = this.dropdowns.length; i < j; i++ ) {
                this.dropdowns[i].element.classList.remove( 'is-open' );
                this.dropdowns[i].container.style.maxHeight = '0px';
                this.dropdowns[i].is_open = false;
            }
        }

        this.is_process = false;
    }

    udpateDropdownSection( dropdown_item, open = true ) {
        // Find parent section
        var parent = closest( dropdown_item, '.c-menu-list__col' );

        // Update parent height if matched
        if( parent ) {
            for( var i = 0, j = this.$sections.length; i < j; i++ ) {
                if( this.$sections[i] == parent ) {
                    if( open == true )
                        this.dropdowns[i].maxHeight = this.dropdowns[i].maxHeight + dropdown_item.getBoundingClientRect().height;
                    else
                        this.dropdowns[i].maxHeight = this.dropdowns[i].maxHeight - dropdown_item.getBoundingClientRect().height;

                    if( this.dropdowns[i].is_open == true ) {
                        this.dropdowns[i].container.style.maxHeight = this.dropdowns[i].maxHeight + 'px';
                    }
                }
            }
        }
    }

    resetDropdownSections() {

        // Enable on mobile
        if( AppController.getInstance().breakpoint == 'sm' || AppController.getInstance().breakpoint == 'xs'  ) {
            this.enableDropdown = true;
            for( var i = 0, j = this.$sections.length; i < j; i++ ) {
                this.dropdowns[i].maxHeight = this.dropdowns[i].content.getBoundingClientRect().height;

                if( this.dropdowns[i].is_open == true )
                    this.dropdowns[i].container.style.maxHeight = this.dropdowns[i].maxHeight + 'px';
            }
        } else {
            this.enableDropdown = false;
            for( var i = 0, j = this.$sections.length; i < j; i++ ) {
                this.dropdowns[i].maxHeight = this.dropdowns[i].content.getBoundingClientRect().height;
                this.dropdowns[i].container.style.maxHeight = 'none';
            }
        }
    }

    resize() {
        this.resetDropdownSections();
    }
}
