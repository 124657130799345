import AbstractForm from '../AbstractForm';
import Logger from '../../utils/Logger';
import { addClass, removeClass } from '../../utils/CssUtils';
import { addEventListener, preventDefault } from '../../utils/EventUtils';
import { closest } from '../../utils/JsUtils';


export default class MasterFormReport extends AbstractForm {
    constructor( el ) {
        Logger.log('MasterFormReport->constructor()');
        super( el, 'c-master-form-report' );
    }


    initDomElements() {
        super.initDomElements();

        this.$maliciousActFields = this.$el.querySelectorAll( '[name="form_malicious_act[]"]' );
        this.$firstnameField = this.$el.querySelector( '[name="form_firstname"]' );
        this.$lastnameField = this.$el.querySelector( '[name="form_lastname"]' );
        this.$emailField = this.$el.querySelector( '[name="form_email"]' );
        this.$telephoneField = this.$el.querySelector( '[name="form_telephone"]' );
        this.$departementField = this.$el.querySelector( '[name="form_departement"]' );
        this.$legalAgeField = this.$el.querySelector( '[name="form_legal_age"]' );
        this.$reporterProfileField = this.$el.querySelector( '[name="form_reporter_profile"]' );
        this.$pressChargesField = this.$el.querySelector( '[name="form_press_charges"]' );
        this.$warnFederationField = this.$el.querySelector( '[name="form_warn_federation"]' );
        this.$firstReportField = this.$el.querySelector( '[name="form_first_report"]' );
        this.$platformAwarenessField = this.$el.querySelector( '[name="form_platform_awareness"]' );
        this.$messageField = this.$el.querySelector( '[name="form_message"]' );
        this.$filesField = closest( this.$el.querySelector( '.' + this.formClassname + '__files' ), '.' + this.formClassname + '__field' );
        this.$files = this.$el.querySelector( '.' + this.formClassname + '__files' );
        this.$file1Field = this.$el.querySelector( '[name="form_file_1"]' );
        this.$file2Field = this.$el.querySelector( '[name="form_file_2"]' );
        this.$file3Field = this.$el.querySelector( '[name="form_file_3"]' );
        this.$file4Field = this.$el.querySelector( '[name="form_file_4"]' );
    }


    addEventListeners() {
        super.addEventListeners();

        if ( this.$maliciousActFields ) {
            addEventListener( this.$maliciousActFields, 'change', () => 
                this.checkFieldCheckbox( this.$maliciousActFields ) );
        }

        if ( this.$emailField ) {
            addEventListener( this.$emailField, 'focus', () => this.checkResetAfterSuccess() );
            addEventListener( this.$emailField, 'blur', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$emailField, this.isEmpty, this.isValidEmail );
            } );
            addEventListener( this.$emailField, 'keyup', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$emailField, this.isEmpty, this.isValidEmail );
            } );
        }

        if ( this.$firstnameField ) {
            addEventListener( this.$firstnameField, 'focus', () => this.checkResetAfterSuccess() );
            addEventListener( this.$firstnameField, 'blur', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$firstnameField, this.isEmpty );
            } );
            addEventListener( this.$firstnameField, 'keyup', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$firstnameField, this.isEmpty );
            } );
        }

        if ( this.$lastnameField ) {
            addEventListener( this.$lastnameField, 'focus', () => this.checkResetAfterSuccess() );
            addEventListener( this.$lastnameField, 'blur', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$lastnameField, this.isEmpty );
            } );
            addEventListener( this.$lastnameField, 'keyup', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$lastnameField, this.isEmpty );
            } );
        }

        if ( this.$departementField ) {
            addEventListener( this.$departementField, 'focus', () => this.checkResetAfterSuccess() );
            addEventListener( this.$departementField, 'blur', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$departementField, this.isEmpty );
            } );
            addEventListener( this.$departementField, 'change', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$departementField, this.isEmpty );
            } );
        }

        if ( this.$legalAgeField ) {
            addEventListener( this.$legalAgeField, 'focus', () => this.checkResetAfterSuccess() );
            addEventListener( this.$legalAgeField, 'blur', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$legalAgeField, this.isEmpty );
            } );
            addEventListener( this.$legalAgeField, 'change', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$legalAgeField, this.isEmpty );
            } );
        }

        if ( this.$reporterProfileField ) {
            addEventListener( this.$reporterProfileField, 'focus', () => this.checkResetAfterSuccess() );
            addEventListener( this.$reporterProfileField, 'blur', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$reporterProfileField, this.isEmpty );
            } );
            addEventListener( this.$reporterProfileField, 'change', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$reporterProfileField, this.isEmpty );
            } );
        }

        if ( this.$pressChargesField ) {
            addEventListener( this.$pressChargesField, 'focus', () => this.checkResetAfterSuccess() );
            addEventListener( this.$pressChargesField, 'blur', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$pressChargesField, this.isEmpty );
            } );
            addEventListener( this.$pressChargesField, 'change', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$pressChargesField, this.isEmpty );
            } );
        }

        if ( this.$warnFederationField ) {
            addEventListener( this.$warnFederationField, 'focus', () => this.checkResetAfterSuccess() );
            addEventListener( this.$warnFederationField, 'blur', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$warnFederationField, this.isEmpty );
            } );
            addEventListener( this.$warnFederationField, 'change', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$warnFederationField, this.isEmpty );
            } );
        }

        if ( this.$firstReportField ) {
            addEventListener( this.$firstReportField, 'focus', () => this.checkResetAfterSuccess() );
            addEventListener( this.$firstReportField, 'blur', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$firstReportField, this.isEmpty );
            } );
            addEventListener( this.$firstReportField, 'change', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$firstReportField, this.isEmpty );
            } );
        }

        if ( this.$platformAwarenessField ) {
            addEventListener( this.$platformAwarenessField, 'focus', () => this.checkResetAfterSuccess() );
            addEventListener( this.$platformAwarenessField, 'blur', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$platformAwarenessField, this.isEmpty );
            } );
            addEventListener( this.$platformAwarenessField, 'change', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$platformAwarenessField, this.isEmpty );
            } );
        }

        if ( this.$messageField ) {
            addEventListener( this.$messageField, 'focus', () => this.checkResetAfterSuccess() );
            addEventListener( this.$messageField, 'blur', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$messageField, this.isEmpty );
            } );
            addEventListener( this.$messageField, 'keyup', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$messageField, this.isEmpty );
            } );
        }

        const fileFields = [ this.$file1Field, this.$file2Field, this.$file3Field, this.$file4Field ];
        for ( const fileField of fileFields ) {
            if ( fileField ) {
                this.addFileEventListener( fileField );
            }
        }
    }


    addFileEventListener( fileField ) {
        addEventListener( fileField, 'change', () => this.fileFieldChange( fileField ) );

        const fileFieldResetButton = fileField.parentElement.querySelector( '.' + this.formClassname + '__file-reset' );
        if ( fileFieldResetButton ) {
            addEventListener( fileFieldResetButton, 'click', ( e ) => {
                preventDefault( e );
                this.fileFieldReset( fileField );
            } );
        }
    }


    checkFormFields() {
        const checkAllFields = [];

        if ( this.$maliciousActFields ) {
            checkAllFields.push( this.checkFieldCheckbox( this.$maliciousActFields ) );
        }

        if ( this.$firstnameField ) {
            checkAllFields.push( this.checkField( this.$firstnameField, this.isEmpty, null ) );
        }

        if ( this.$lastnameField ) {
            checkAllFields.push( this.checkField( this.$lastnameField, this.isEmpty, null ) );
        }

        if ( this.$emailField ) {
            checkAllFields.push( this.checkField( this.$emailField, this.isEmpty, this.isValidEmail ) );
        }

        if ( this.$departementField ) {
            checkAllFields.push( this.checkField( this.$departementField, this.isEmpty, null ) );
        }

        if ( this.$legalAgeField ) {
            checkAllFields.push( this.checkField( this.$legalAgeField, this.isEmpty, null ) );
        }

        if ( this.$reporterProfileField ) {
            checkAllFields.push( this.checkField( this.$reporterProfileField, this.isEmpty, null ) );
        }

        if ( this.$pressChargesField ) {
            checkAllFields.push( this.checkField( this.$pressChargesField, this.isEmpty, null ) );
        }

        if ( this.$warnFederationField ) {
            checkAllFields.push( this.checkField( this.$warnFederationField, this.isEmpty, null ) );
        }

        if ( this.$firstReportField ) {
            checkAllFields.push( this.checkField( this.$firstReportField, this.isEmpty, null ) );
        }

        if ( this.$platformAwarenessField ) {
            checkAllFields.push( this.checkField( this.$platformAwarenessField, this.isEmpty, null ) );
        }

        if ( this.$messageField ) {
            checkAllFields.push( this.checkField( this.$messageField, this.isEmpty, null ) );
        }

        if ( this.$files && this.$file1Field && this.$file2Field && this.$file3Field && this.$file4Field ) {
            checkAllFields.push( this.checkFiles() );
        }

        return ( checkAllFields.indexOf( false ) === -1 );
    }


    getAjaxAction() {
        return 'fnc_master_form_report_ajax';
    }


    getFormData() {
        const data = super.getFormData();

        if ( this.$maliciousActFields ) {
            const maliciousActs =  this.getCheckedValues( this.$maliciousActFields );
            for ( const maliciousAct of maliciousActs ) {
                data.push( { name: 'malicious_acts[]', value: maliciousAct } );
            }
        }

        if ( this.$firstnameField ) {
            data.push( { name: 'firstname', value: this.$firstnameField.value } );
        }

        if ( this.$lastnameField ) {
            data.push( { name: 'lastname', value: this.$lastnameField.value } );
        }

        if ( this.$emailField ) {
            data.push( { name: 'email', value: this.$emailField.value } );
        }

        if ( this.$telephoneField ) {
            data.push( { name: 'telephone', value: this.$telephoneField.value } );
        }

        if ( this.$departementField ) {
            data.push( { name: 'departement', value: this.$departementField.value } );
        }

        if ( this.$legalAgeField ) {
            data.push( { name: 'legal_age', value: this.$legalAgeField.value } );
        }

        if ( this.$reporterProfileField ) {
            data.push( { name: 'reporter_profile', value: this.$reporterProfileField.value } );
        }

        if ( this.$pressChargesField ) {
            data.push( { name: 'press_charges', value: this.$pressChargesField.value } );
        }

        if ( this.$warnFederationField ) {
            data.push( { name: 'warn_federation', value: this.$warnFederationField.value } );
        }

        if ( this.$firstReportField ) {
            data.push( { name: 'first_report', value: this.$firstReportField.value } );
        }

        if ( this.$platformAwarenessField ) {
            data.push( { name: 'platform_awareness', value: this.$platformAwarenessField.value } );
        }

        if ( this.$messageField ) {
            data.push( { name: 'message', value: this.$messageField.value } );
        }

        const fileFields = [ this.$file1Field, this.$file2Field, this.$file3Field, this.$file4Field ];
        for ( const fileField of fileFields ) {
            if ( fileField && fileField.files && ( fileField.files.length > 0 ) ) {
                for ( const file of fileField.files ) {
                    data.push( { name: 'files[]', value: file } );  
                }
            }
        }

        return data;
    }


    resetFormFields() {
        if ( this.$maliciousActFields ) {
            for ( const maliciousActField  of this.$maliciousActFields ) {
                maliciousActField.checked = false;
            }
        }

        if ( this.$firstnameField ) {
            this.$firstnameField.value = '';
        }

        if ( this.$lastnameField ) {
            this.$lastnameField.value = '';
        }

        if ( this.$emailField ) {
            this.$emailField.value = '';
        }

        if ( this.$telephoneField ) {
            this.$telephoneField.value = '';
        }

        if ( this.$departementField ) {
            this.$departementField.value = '';
        }

        if ( this.$legalAgeField ) {
            this.$legalAgeField.value = '';
        }

        if ( this.$reporterProfileField ) {
            this.$reporterProfileField.value = '';
        }

        if ( this.$pressChargesField ) {
            this.$pressChargesField.value = '';
        }

        if ( this.$warnFederationField ) {
            this.$warnFederationField.value = '';
        }

        if ( this.$firstReportField ) {
            this.$firstReportField.value = '';
        }

        if ( this.$platformAwarenessField ) {
            this.$platformAwarenessField.value = '';
        }

        if ( this.$messageField ) {
            this.$messageField.value = '';
        }

        const fileFields = [ this.$file1Field, this.$file2Field, this.$file3Field, this.$file4Field ];
        for ( const fileField of fileFields ) {
            if ( fileField ) {
                this.fileFieldReset( fileField );
            }
        }

        if ( this.$newsletterField ) {
            this.$newsletterField.checked = false;
        }
    }


    fileFieldChange( fileField ) {
        const labelSpan = fileField.nextElementSibling.querySelector( 'span' );

        if ( fileField.files && ( fileField.files.length > 0 ) ) {
            addClass( fileField, this.formClassname + '__file--selected' );
            if ( fileField.files.length == 1 ) {
                labelSpan.innerHTML = fileField.value.split( '\\' ).pop().normalize();
            } else {
                const labelMultiple = fileField.getAttribute( 'data-label-multiple' ) || '';
                labelSpan.innerHTML = labelMultiple.replace( '{count}', fileField.files.length );
            }
        } else {
            removeClass( fileField, this.formClassname + '__file--selected' );
            const labelEmpty = fileField.getAttribute( 'data-label-empty' ) || '';
            labelSpan.innerHTML = labelEmpty;
        }
        fileField.blur();
        this.updateFilesClass();
        this.checkFiles();
    }


    updateFilesClass() {
        const filesClassShow2 = this.formClassname + '__files--show-2';
        const filesClassShow3 = this.formClassname + '__files--show-3';
        const filesClassShow4 = this.formClassname + '__files--show-4';
        removeClass( this.$files, filesClassShow2 );
        removeClass( this.$files, filesClassShow3 );
        removeClass( this.$files, filesClassShow4 );

        let filesClassShow = null; 
        if ( this.$file1Field && this.$file1Field.files && ( this.$file1Field.files.length > 0 ) ) {
            filesClassShow = filesClassShow2;
        }
        if ( this.$file2Field && this.$file2Field.files && ( this.$file2Field.files.length > 0 ) ) {
            filesClassShow = filesClassShow3;
        }
        if ( this.$file3Field && this.$file3Field.files && ( this.$file3Field.files.length > 0 ) ) {
            filesClassShow = filesClassShow4;
        }
        if ( filesClassShow ) {
            addClass( this.$files, filesClassShow );
        }
    }


    checkFiles() {
        let filesSize = 0;
        if ( this.$file1Field  ) {
            filesSize += this.getFileFieldFilesSize( this.$file1Field );
        }
        if ( this.$file2Field  ) {
            filesSize += this.getFileFieldFilesSize( this.$file2Field );
        }
        if ( this.$file3Field  ) {
            filesSize += this.getFileFieldFilesSize( this.$file3Field );
        }
        if ( this.$file4Field  ) {
            filesSize += this.getFileFieldFilesSize( this.$file4Field );
        }
        
        if ( this.$files ) {
            const sizeMax = parseInt( this.$files.getAttribute( 'data-size-max' ) || '10485760' ); // 10 Mo
            if ( filesSize > sizeMax ) {
                this.showFieldError( this.$filesField, 'size-max' );
                return false;
            }
            this.hideFieldError( this.$filesField );
        }

        return true;
    }


    getFileFieldFilesSize( fileField ) {
        let size = 0;
        if ( fileField.files && ( fileField.files.length > 0 ) ) {
            for ( const file of fileField.files ) {
                size += file.size;
            }
        }
        return size;
    }


    fileFieldReset( fileField ) {
        if ( fileField.files && ( fileField.files.length > 0 ) ) {
            try {
                fileField.value = '';
                if ( fileField.value ) {
                    fileField.type = 'text';
                    fileField.type = 'file';
                }
            } catch( e ) {}
            this.fileFieldChange( fileField );
        }
    }


    destroy() {}
}