import AbstractPageController from './AbstractPageController';
import Logger from '../utils/Logger';
import { addClass, removeClass, css } from '../utils/CssUtils';
import { addEventListener } from '../utils/EventUtils';
import { createHTMLElement } from '../utils/HtmlUtils';
import { hasProperty } from '../utils/JsUtils';


export default class FederationsDirectoryController extends AbstractPageController {
    constructor( ...args ) {
        super( ...args );
        Logger.log( 'FederationsDirectoryController->constructor()' );

        this.classname = 'federations-directory';
        this.popinFromHistory = false;
        this.initDomElements();
        this.addEventListeners();
        this.checkOnLoadPopin();

        this.ready();
    }


    initDomElements() {
        this.$dropdowns = document.querySelectorAll( '.js-' + this.classname + '__select select' );
        this.$mapDepartements = document.querySelector( '.js-' + this.classname + '__map__svg--departements' );
        this.$mapRegions = document.querySelector( '.js-' + this.classname + '__map__svg--regions' );
        this.$overlay = createHTMLElement( 'div', { classname: this.classname + '__map__overlay' } );
        document.body.appendChild( this.$overlay );
    }


    addEventListeners() {
        if ( this.$mapDepartements ) {
            addEventListener( this.$dropdowns, 'change', this.dropdownChanged.bind( this ) );
        }
        if ( this.$mapDepartements ) {
            this.addEventListenersMapDepartements();
        }
        if ( this.$mapRegions ) {
            this.addEventListenersMapRegions();
        }

        addEventListener( window, 'popstate', ( event ) => {
            this.popinFromHistory = true;
            if ( hasProperty( event.state, 'code' ) ) {
                this.openPopinForCode( event.state.code );
            } else {
                this.closePopin();
            }
        } );
    }


    dropdownChanged( e ) {
        const code = e.target.value;
        if ( code ) {
            this.popinFromHistory = false;
            this.openPopinForCode( code );
            setTimeout( () => { e.target.value = '' }, 500 );
        }
    }


    addEventListenersMapDepartements() {
        const departements = this.$mapDepartements.querySelectorAll( '.' + this.classname + '__map__svg__departement' );
        for ( const departement of departements ) {
            addEventListener( departement, 'mouseenter', () => this.updateNameAndShowOverlay( departement ) );
            addEventListener( departement, 'mouseleave', () => this.resetNameAndHideOverlay( departement ) );
            const code = departement.getAttribute( 'data-code' );
            if ( code && hasProperty( window.FNC.federations_data, code ) ) {
                addEventListener( departement, 'mouseenter', () => this.addHighlightDepartementMapDepartements( departement ) );
                addEventListener( departement, 'mouseleave', () => this.removeHighlightDepartementMapDepartements( departement ) );
                departement.addEventListener( 'click', () => {
                    this.popinFromHistory = false;
                    this.openPopinForCode( code );
                } );
            } else {
                addClass( departement, this.classname + '__map__svg--disabled' );
            }
        }

        const ileDeFranceGroup = this.$mapDepartements.querySelector( '.' + this.classname + '__map__svg__departement.' + this.classname + '__map__svg--ile-de-france' );
        const ileDeFranceDepartements = this.$mapDepartements.querySelectorAll(
            '.' + this.classname + '__map__svg__departement[data-code="75"], ' +
            '.' + this.classname + '__map__svg__departement[data-code="92"],' +
            '.' + this.classname + '__map__svg__departement[data-code="93"],' +
            '.' + this.classname + '__map__svg__departement[data-code="94"]'
        );
        addEventListener( ileDeFranceGroup, 'mouseenter', () => {
            // addClass( ileDeFranceGroup, this.classname + '__map__svg--disabled' );
            for ( const departement of ileDeFranceDepartements ) {
                addClass( departement, this.classname + '__map__svg--highlight' );
            }
        } );
        addEventListener( ileDeFranceGroup,'mouseleave', () => {
            // removeClass( ileDeFranceGroup, this.classname + '__map__svg--disabled' );
            for ( const departement of ileDeFranceDepartements ) {
                removeClass( departement, this.classname + '__map__svg--highlight' );
            }
        } );

        addEventListener( this.$mapDepartements, 'mousemove', this.overlayMouseMoveHandler.bind( this ) );
    }


    updateNameAndShowOverlay( item ) {
        const name = item.getAttribute( 'data-name' );
        if ( name ) {
            this.$overlay.innerHTML = name;
            css( this.$overlay, { display: 'block' } );
        }
    }


    resetNameAndHideOverlay( item ) {
        css( this.$overlay, { display: 'none' } );
        this.$overlay.innerHTML = '';
    }


    addHighlightDepartementMapDepartements( departement ) {
        addClass( departement, this.classname + '__map__svg--highlight' );
    }


    removeHighlightDepartementMapDepartements( departement ) {
        removeClass( departement, this.classname + '__map__svg--highlight' );
    }


    openPopinForCode( code ) {
        if ( code && hasProperty( window.FNC.federations_data, code ) ) {
            const data = window.FNC.federations_data[code];
            this.app.Popin.setType( 'federation' );
            if ( !this.popinFromHistory && window.location != data.url ) {
                history.pushState( { code: code }, data.title, data.url );
                document.title = data.title;
            }
            if ( hasProperty( window.FNC, 'federations_popin_root_page' ) ) {
                this.app.Popin.setCloseHistory( window.FNC.federations_popin_root_page.title, window.FNC.federations_popin_root_page.url );
            }
            this.app.Popin.setContents( data.popin_html );
            this.app.Popin.openAndReveal();
        }
    }


    closePopin() {
        if ( this.popinFromHistory ) {
            this.app.Popin.setCloseHistory( null, null );
        }
        this.app.Popin.to_close();
    }


    addEventListenersMapRegions() {
        const regions = this.$mapRegions.querySelectorAll( '.' + this.classname + '__map__svg__region' );
        for ( const region of regions ) {
            addEventListener( region, 'mouseenter', () => this.updateNameAndShowOverlay( region ) );
            addEventListener( region, 'mouseleave', () => this.resetNameAndHideOverlay( region ) );
            const code = region.getAttribute( 'data-code' );
            if ( code && hasProperty( window.FNC.federations_data, code ) ) {
                addEventListener( region, 'mouseenter', () => this.addHighlightDepartementsMapRegions( region ) );
                addEventListener( region, 'mouseleave', () => this.removeHighlightDepartementsMapRegions( region ) );
                region.addEventListener( 'click', () => {
                    this.popinFromHistory = false;
                    this.openPopinForCode( code );
                } );
            } else {
                addClass( region, this.classname + '__map__svg--disabled' );
            }
        }

        addEventListener( this.$mapRegions, 'mousemove', this.overlayMouseMoveHandler.bind( this ) );
    }


    addHighlightDepartementsMapRegions( region ) {
        const regionSlug = region.getAttribute( 'data-region' );
        if ( regionSlug ) {
            const departements = this.$mapRegions.querySelectorAll( '.' + this.classname + '__map__svg__departement[data-region="' + regionSlug + '"]' );
            for ( const departement of departements ) {
                addClass( departement, this.classname + '__map__svg--highlight' );
            }
        }
    }


    removeHighlightDepartementsMapRegions( region ) {
        const regionSlug = region.getAttribute( 'data-region' );
        if ( regionSlug ) {
            const departements = this.$mapRegions.querySelectorAll( '.' + this.classname + '__map__svg__departement[data-region="' + regionSlug + '"]' );
            for ( const departement of departements ) {
                removeClass( departement, this.classname + '__map__svg--highlight' );
            }
        }
    }


    overlayMouseMoveHandler( e ) {
        const horizontalOffset = 4;
        const verticalOffset = 26;
        const horizontalScrollOffset = ( window.pageXOffset || document.documentElement.scrollLeft ) - ( document.documentElement.clientLeft || 0 );
        const verticalScrollOffset = ( window.pageYOffset || document.documentElement.scrollTop )  - ( document.documentElement.clientTop || 0 );
        const overlayLeftPosition = ( e.clientX + horizontalScrollOffset + horizontalOffset );
        const overlayTopPosition = ( e.clientY + verticalScrollOffset + verticalOffset );
        css( this.$overlay, { left: overlayLeftPosition + 'px' } );
        css( this.$overlay, { top: overlayTopPosition + 'px' } );
    }


    checkOnLoadPopin() {
        if ( hasProperty( window.FNC, 'federations_popin_code' ) ) {
            const code = window.FNC.federations_popin_code;
            history.replaceState( { code: code }, document.title, window.location );
            this.openPopinForCode( code );
        }
    }


    destroy() {}
}
