document.addEventListener("DOMContentLoaded", () => {
	let button_load_more = document.querySelector(".faq-load-more-button");

	function loadMorePost(e) {
		let faq_item = document.querySelectorAll(".c-faq-item");

		for (let item of faq_item) {
			if (item.classList.contains("is-hide")) {
				item.classList.toggle("is-hide");

				if (e.isTrusted == true) {
					button_load_more.classList.add("is-hide");
				}
			}
		}
	}

	if (button_load_more !== null) {
		button_load_more.addEventListener("click", loadMorePost);
	}
});
