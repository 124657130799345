import AbstractComponent from '../AbstractComponent';
import { addClass, css , removeClass} from '../../utils/CssUtils';
import { addEventListener } from '../../utils/EventUtils';
import { convertHTMLStringToDOMElement } from '../../utils/HtmlUtils';
import { getPageHeight, getPageWidth, hasProperty, stringFormat } from '../../utils/JsUtils';
import Logger from '../../utils/Logger';
import Request from '../../utils/Request';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import mapboxgl from 'mapbox-gl';
import MapboxglSpiderifier from 'mapboxgl-spiderifier';
import ZoomControl from 'mapbox-gl-controls/lib/zoom';
import { TweenMax, Power1 } from 'gsap/all';
import Flickity from 'flickity';


export default class SensisMapMap extends AbstractComponent {
    constructor(...args) {
        Logger.log('SensiMap->constructor()');
        super(...args);

        this.classname = 'c-sensi-map-map';
        this.mapMaxZoom = 17;
        this.mapSpiderifyZoom = 9;
        this.filters = {
            year:[],
            department:[]
        }
        this.initDomElements();
        this.initLocations();
        this.initMapboxMap();
        this.initSensiPopin();
        this.addEventListeners();
    }


    initDomElements() {
        this.$navbar = document.body.querySelector( '.c-site-menu__navbar' );
        this.$mapContainer = this.$el.querySelector( '.' + this.classname + '__map-container' );
        this.$filters = this.$el.querySelector( '.' + this.classname + '__filters' );
        this.$locations = this.$el.querySelectorAll( '.' + this.classname + '__filters__location' );
        this.$locationsNavigationPrev = this.$el.querySelectorAll( '.' + this.classname + '__filters__locations__navigation--prev' );
        this.$locationsNavigationNext = this.$el.querySelectorAll( '.' + this.classname + '__filters__locations__navigation--next' );
        this.$activeLocation = this.$el.querySelector( '.' + this.classname + '__filters__locations__active-location' );
        this.$filtersReset = this.$el.querySelector( '.' + this.classname + '__filters__reset' );
        this.$filtersOpenMobile = this.$el.querySelector( '.' + this.classname + '__filters__open-mobile' );
        this.$filtersPopinMobile = document.querySelector( '.' + this.classname + '__filters' );
        if ( this.$filtersPopinMobile ) {
            this.$filtersPopinMobileClose = this.$filtersPopinMobile.querySelector( '.' + this.classname + '__filters__close' );
            this.$filtersPopinMobileResults = this.$filtersPopinMobile.querySelector( '.' + this.classname + '__filters__results' );
            this.$filtersPopinMobileReset = this.$filtersPopinMobile.querySelector( '.' + this.classname + '__filters__reset' );
            this.$filtersPopinMobileTaxonomiesCheckboxes = this.$filtersPopinMobile.querySelectorAll( '.' + this.classname + '__filters__checkbox' );
            this.$filtersPopinMobileTaxonomiesTerms = this.$filtersPopinMobile.querySelectorAll( '.' + this.classname + '__filters__taxonomy-terms' );
        }
        this.$sensiPopinContainer = document.querySelector( '.' + this.classname + '__sensi__popin-container' );
        this.$sensiPopin = document.querySelector( '.' + this.classname + '__sensi__popin' );
        if ( this.$sensiPopin ) {
            this.$sensiPopinClose = this.$sensiPopin.querySelector( '.' + this.classname + '__sensi__popin__close' );
            this.$sensiPopinImageClose = this.$sensiPopin.querySelector( '.' + this.classname + '__sensi__popin__image__close' );
            this.$sensiPopinImage = this.$sensiPopin.querySelector( '.' + this.classname + '__sensi__popin__image' );
            this.$sensiPopinMedia = this.$sensiPopin.querySelector( '.' + this.classname + '__sensi__popin__media' );
            this.$sensiPopinInfo = this.$sensiPopin.querySelector( '.' + this.classname + '__sensi__popin__info' );
        }
    }


    initMapboxMap() {
        this.actions = [];
        this.filteredSensis = [];
        this.actionsPopinData = {};
        if ( this.$mapContainer ) {
            const activeLocation = {
                center: [1.7191036, 46.71109],
                zoom: 4.9
            };
            
            // Creating the Mapbox map
            mapboxgl.accessToken = window.FNC.mapbox_access_token;
            this.mapboxMap = new mapboxgl.Map( {
                container: this.$mapContainer,
                // style: 'mapbox://styles/mapbox/satellite-v9',
                style: 'mapbox://styles/chasseurdefrance/ckb7ysjjs4s9c1imy2f780lxc',
                center: activeLocation.center,
                zoom: activeLocation.zoom,
                maxZoom: this.mapMaxZoom,
                attributionControl: false,
                scrollZoom: false
            } );

            // Adding attribution and zoom controls
            this.mapboxMap.addControl( new mapboxgl.AttributionControl( { compact: true } ) );
            this.mapboxMap.addControl( new ZoomControl(), 'bottom-left' );
        }
    }


    initLocations() {
        this.locations = [];
        if ( this.$locations ) {
            for ( let index = 0; index < this.$locations.length; index++ ) {
                const locationElement = this.$locations[index];
                const location = {};
                location['index'] = index;
                location['element'] = locationElement;
                location['name'] = locationElement.getAttribute( 'data-name' );
                location['center'] = {
                    'lat': parseFloat( locationElement.getAttribute( 'data-center-lat' ) ),
                    'lng': parseFloat( locationElement.getAttribute( 'data-center-lng' ) ),
                };
                location['zoom'] = {
                    'desktop': parseFloat( locationElement.getAttribute( 'data-zoom-desktop' ) ),
                    'mobile': parseFloat( locationElement.getAttribute( 'data-zoom-mobile' ) ),
                };
                this.locations.push( location );
            }
            this.currentLocation = this.locations[0];
        }
    }


    addEventListeners() {
        this.app.on( 'resize', this.resize.bind( this ) );
        addEventListener( document, 'keydown', this.escapeKeyHandler.bind( this ) );
        this.addMapboxMapEventListeners();
        this.addFiltersOpenMobileEventListeners();
        this.addSensiPopinEventListeners();
        this.addFiltersPopinMobileEventListeners();
        this.addEventCheckboxes();
        this.$filtersReset.addEventListener('click',()=>{
            this.filters = {
                year:[],
                department:[]
            }
            this.$filtersPopinMobileTaxonomiesCheckboxes.forEach(checkbox=>{
                checkbox.checked = false
            })
            this.updateFiltersTaxonomiesDisplayBadges();
            this.filterDisplayedSensis();
            css(this.$filtersReset,{display:'none'})
        })
    }


    escapeKeyHandler( e ) {
        if ( e.keyCode == 27 ) { // Escape key
            if ( this.isSensiPopinOpen ) {
                this.actionPopinFromHistory = false;
                this.hideSensiPopin();
                return;
            }

            if ( this.isFiltersTaxonomyTermsOpen ) {
                this.hideFiltersTaxonomyTerms();
                return;
            }
        }
    }

    addEventCheckboxes(){
        this.$filtersPopinMobileTaxonomiesCheckboxes.forEach(checkboxe => {
            checkboxe.addEventListener('click',(e)=>{
                this.toggleFiltersCheckbox(e.target);
            })
        });

    }
    addMapboxMapEventListeners() {
        if ( this.$mapContainer && this.mapboxMap ) {
            this.mapboxMap.on( 'load', () => {
                // Source
                this.mapboxMap.addSource( 'actions', {
                    'type': 'geojson',
                    'data': {
                        'type': 'FeatureCollection',
                        'features': []
                    },
                    'cluster': true,
                    'clusterRadius': 35
                } );
                
                // Clusters circles layout
                this.mapboxMap.addLayer( {
                    id: 'clusters',
                    type: 'circle',
                    source: 'actions',
                    filter: ['has', 'point_count'],
                    paint: {
                        'circle-color': '#ffffff',
                        'circle-radius': [
                            'case',
                            ['boolean',
                              ['feature-state', 'spiderifyHover'],
                              false
                            ],
                            5,
                            21
                        ],
                        'circle-opacity': [
                            'case',
                            ['boolean',
                              ['feature-state', 'spiderifyHover'],
                              false
                            ],
                            0,
                            1
                        ]
                    }
                } );
                
                // Clusters counts layout
                this.mapboxMap.addLayer( {
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'actions',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': '{point_count}',
                        'text-size': 16
                    },
                    paint: {
                        'text-color': '#1B1B1B',
                        'text-opacity': [
                            'case',
                            ['boolean',
                              ['feature-state', 'spiderifyHover'],
                              false
                            ],
                            0,
                            1
                        ]
                    }
                } );
                
                // Sensis circles
                this.mapboxMap.addLayer( {
                    id: 'unclustered-point',
                    type: 'circle',
                    source: 'actions',
                    filter: ['!', ['has', 'point_count']],
                    paint: {
                        "circle-color": ["get", "color"],
                        'circle-radius': [
                            'case',
                            ['boolean',
                              ['feature-state', 'hover'],
                              false
                            ],
                            16,
                            8
                        ],
                        'circle-stroke-width': [
                            'case',
                            ['boolean',
                              ['feature-state', 'hover'],
                              false
                            ],
                            4,
                            3
                        ],
                        'circle-stroke-color': '#ffffff'
                    }
                } );

                // Initializing the map data
                this.initMapboxMapData();

                // Initializing map spiderifier and undo events
                this.mapboxMapSpiderifier = this.getMapboxMapSpiderifier();
                this.mapboxMap.on( 'zoomstart', () => this.unspiderifyMapboxMap() );
                this.mapboxMap.on( 'dragstart', () => this.unspiderifyMapboxMap() );

                // Inspect a cluster on click or spiderify the cluster (if on max zoom)
                this.mapboxMap.on( 'click', ( e ) => {
                    const clustersFeatures = this.mapboxMap.queryRenderedFeatures( e.point, { layers: ['clusters'] } );
                    const clusterCountfeatures = this.mapboxMap.queryRenderedFeatures( e.point, { layers: ['cluster-count'] } );
                    const clusterFeature = ( clustersFeatures.length > 0 ) ? clustersFeatures[0] : null;
                    const clusterCountfeature = ( clusterCountfeatures.length > 0 ) ? clusterCountfeatures[0] : null;

                    if ( clusterFeature && !this.mapboxMapSpiderifierCluster ) {
                        if ( this.mapboxMap.getZoom() < this.mapSpiderifyZoom ) {
                            this.mapboxMap.getSource( 'actions' ).getClusterExpansionZoom(
                                clusterFeature.properties.cluster_id,
                                ( err, zoom ) => {
                                    if ( err ) { return; }
                                    this.mapboxMap.easeTo( {
                                        center: clusterFeature.geometry.coordinates,
                                        zoom: zoom
                                    } );
                                }
                            );
                        } else {
                            this.mapboxMapSpiderifierCluster = clusterFeature;
                            this.setMapboxMapSpiderifyHoverSensiState( this.mapboxMapSpiderifierCluster, true );
                            this.mapboxMapSpiderifierClusterCount = clusterCountfeature;
                            this.setMapboxMapSpiderifyHoverSensiState( this.mapboxMapSpiderifierClusterCount, true );
                            this.mapboxMap.getSource( 'actions' ).getClusterLeaves(
                                clusterFeature.properties.cluster_id, 150, 0,
                                ( err, leafFeatures ) => {
                                    if ( err ) { return; }
                                    this.mapboxMapSpiderifier.spiderfy( clusterFeature.geometry.coordinates, leafFeatures );
                                }
                            );
                        }
                    } else {
                        this.unspiderifyMapboxMap();
                    }
                } );

                // 'Mouse Enter' listener on actions layer
                this.mapboxMap.on( 'mouseenter', 'unclustered-point', ( e ) => {
                    if ( e.features.length > 0 ) {
                        const action = e.features[0];


                        // Showing the map popup
                        var coordinates = action.geometry.coordinates.slice();

                        // Pre-fetching the action popin data
                        this.fetchSensiPopinData( action.id );
                    }
                } );

                // 'Click' listener on actions
                this.mapboxMap.on( 'click', 'unclustered-point', ( e ) => {
                    if ( e.features.length > 0 ) {
                        const action = e.features[0];
                        this.actionPopinFromHistory = false;
                        this.showSensiPopinForSensi( action.id);
                    }
                } );

                // 'Mouse Enter' & 'Mouse Leave' listeners on clusters layers
                const addCursor = () => this.mapboxMap.getCanvas().style.cursor = 'pointer';
                const removeCursor = () => this.mapboxMap.getCanvas().style.cursor = '';
                this.mapboxMap.on( 'mouseenter', 'clusters', addCursor );
                this.mapboxMap.on( 'mouseleave', 'clusters', removeCursor);
                this.mapboxMap.on( 'mouseenter', 'unclustered-point', addCursor );
                this.mapboxMap.on( 'mouseleave', 'unclustered-point', removeCursor );
            } );
        }
    }


    getMapboxMapSpiderifier() {
        this.mapboxMapSpiderifierCluster = null;
        this.mapboxMapSpiderifierClusterCount = null;
        return new MapboxglSpiderifier( this.mapboxMap, {
            animate: true,
            animationSpeed: 250,
            customPin: true,
            onClick: ( e, spiderLeg ) => {
                const action = spiderLeg.feature;
                this.actionPopinFromHistory = false;
                this.showSensiPopinForSensi( action.id );
            },
            initializeLeg: ( spiderLeg ) => {
                const pin = spiderLeg.elements.pin;
                const action = spiderLeg.feature;

                let backgroundColor = '#61615a';
                backgroundColor = action.properties.color
                css( pin, { backgroundColor: backgroundColor } );
            }
        } );
    }


    unspiderifyMapboxMap() {
        this.mapboxMapSpiderifier.unspiderfy();
        this.setMapboxMapSpiderifyHoverSensiState( this.mapboxMapSpiderifierCluster, false );
        this.setMapboxMapSpiderifyHoverSensiState( this.mapboxMapSpiderifierClusterCount, false );
        setTimeout( () => {
            this.mapboxMapSpiderifierCluster = null;
            this.mapboxMapSpiderifierClusterCount = null;
        }, 100 );
    }


    setMapboxMapSpiderifyHoverSensiState( item, state ) {
        if ( item ) {
            this.mapboxMap.setFeatureState( item, { spiderifyHover: state } );
        }
    }


    getMapboxMapProgramsColors() {
        const colorsSpecs = [ 'case' ];
        colorsSpecs.push( '#61615a' );
        return colorsSpecs;
    }


    initMapboxMapData() {
        Request.AJAX( {
            'url': window.FNC.ajax_url + '?action=' + 'fnc_sensies_ajax',
            'method':'GET',
            'success': ( response ) => {
                if ( response && hasProperty( response, 'data' ) ) {

                    this.actions = response.data;
                    this.filteredSensis = this.actions;
                    this.updateMapboxMapSensis();
                    this.updateFiltersPopinMobileResults();
                    this.checkOnLoadSensiPopin();
                }
            }
        } );
    }


    fetchSensiPopinData( sensiId ) {
        if ( hasProperty( this.actionsPopinData, sensiId ) ) {
            return;
        }

        this.actionsPopinData[sensiId] = { ready: false };
        Request.AJAX( {
            'url': window.FNC.ajax_url + '?action=fnc_sensi_ajax',
            'data': { 'sensi_id': sensiId },
            'success': ( response ) => {
                if ( response && hasProperty( response, 'data' ) ) {
                    this.actionsPopinData[response.data.sensi_id].ready = true;
                    this.actionsPopinData[response.data.sensi_id].data = response.data;
                    this.emit( 'sensi-popin-data-loaded', response.data.sensi_id, response.data );
                }
            }
        } );
    }


    resetFilters( e ) {
        e.target.blur();
        this.resetTaxonomiesTermsCheckboxes();
       this.updateFiltersTaxonomiesDisplayBadges();
        this.filterDisplayedSensis();
    }
    updateFiltersTaxonomiesDisplayBadges(){
        this.$filterDropdowns = this.$filters.querySelectorAll('[data-component="dropdown"]')
        this.$filterDropdowns.forEach(dropdown=>{
            let amount = dropdown.querySelectorAll('input[type="checkbox"]:checked').length
            let badge = dropdown.querySelector('.badge')
            dropdown.querySelector('.badge').innerText = amount
            if (amount < 1) {
                addClass(badge,'empty')
            }else{
                removeClass(badge,'empty')
            }
        })
    };


    
    resetTaxonomiesTermsCheckboxes() {
        if ( this.$filtersTaxonomiesTerms ) {
            for ( const filtersTaxonomyTerms of this.$filtersTaxonomiesTerms ) {
                const filtersTaxonomyCheckboxes = filtersTaxonomyTerms.querySelectorAll( '.' + this.classname + '__filters__taxonomy-terms__term__checkbox' );
                if ( filtersTaxonomyCheckboxes ) {
                    for ( const filtersTaxonomyCheckbox of filtersTaxonomyCheckboxes ) {
                        filtersTaxonomyCheckbox.checked = false;
                    }
                }
            }
        }
        if ( this.$filtersPopinMobileTaxonomiesTerms ) {
            for ( const filtersTaxonomyTerms of this.$filtersPopinMobileTaxonomiesTerms ) {
                const filtersTaxonomyCheckboxes = filtersTaxonomyTerms.querySelectorAll( '.' + this.classname + '__filters__taxonomy-terms__term__checkbox' );
                if ( filtersTaxonomyCheckboxes ) {
                    for ( const filtersTaxonomyCheckbox of filtersTaxonomyCheckboxes ) {
                        filtersTaxonomyCheckbox.checked = false;
                    }
                }
            }
        }
    }


    addFiltersOpenMobileEventListeners() {
        if ( this.$filtersOpenMobile ) {
            addEventListener( this.$filtersOpenMobile, 'click', ( e ) => {
                e.target.blur();
                this.showFiltersPopin();
            } );
        }
    }

    showFiltersPopin() {
        if ( this.$filtersPopinMobile ) {
            disableBodyScroll( this.$filtersPopinMobile );
            css( this.$filtersPopinMobile, { visibility: 'visible' } );
            css( this.$navbar, { visibility: 'hidden' } );
            css( this.$navbar, { 'pointer-events': 'none' } );
            addClass( this.$filtersPopinMobile,'is-open' );
        }
    }


    addFiltersPopinMobileEventListeners() {
        if ( this.$filtersPopinMobile ) {
            if ( this.$filtersPopinMobileClose ) {
                addEventListener( this.$filtersPopinMobileClose, 'click', ( e ) => {
                    e.target.blur();
                    this.hideFiltersPopinMobile();
                } );
            }

            if ( this.$filtersPopinMobileResults ) {
                addEventListener( this.$filtersPopinMobileResults, 'click', ( e ) => {
                    e.target.blur();
                    this.hideFiltersPopinMobile();
                } );
            }

        }
    }


    hideFiltersPopinMobile() {
        if ( this.$filtersPopinMobile ) {
            removeClass( this.$filtersPopinMobile, 'is-open' );
            clearAllBodyScrollLocks();
            setTimeout( () => {
                css( this.$filtersPopinMobile, { visibility: 'hidden' } )
                css( this.$navbar, { visibility: 'visible' } );
                css( this.$navbar, { 'pointer-events': 'all' } );
            }, 300 );
        }
    }



    toggleFiltersCheckbox( item) {
        const type = item.getAttribute('value').includes('department') ? 'department':'year'
        const currentFilter = item.getAttribute('value').replace(type + '__','')
        if (item.checked) {
            this.filters[type].push(currentFilter)
        }else{
            this.filters[type] = this.filters[type].filter(filter => filter !== currentFilter)
        }
       
       this.updateFiltersTaxonomiesDisplayBadges();
        this.filterDisplayedSensis();
    }

    filterDisplayedSensis() {
        this.filteredSensis = this.actions.filter( ( item ) => {
            if ( hasProperty( item, 'properties' ) && (this.filters.year.length > 0 || this.filters.department.length > 0)) {
                let toShow;
                if (this.filters.year.length > 0 && this.filters.department.length > 0) {
                    toShow = this.filters.year.includes(item.properties.year) && this.filters.department.includes(item.properties.department)
                }else{
                    toShow = this.filters.year.includes(item.properties.year) || this.filters.department.includes(item.properties.department)
                }

                css(this.$filtersReset,{display:'block'})
                return toShow
            }
            css(this.$filtersReset,{display:'none'})
            return true;
        } );

        this.updateMapboxMapSensis();
        this.updateFiltersPopinMobileResults();
    }

    
    updateMapboxMapSensis() {
        this.mapboxMap.getSource( 'actions' ).setData( {
            'type': 'FeatureCollection',
            'features': this.filteredSensis
        } );
    }


    updateFiltersPopinMobileResults() {
        const filteredSensisCount = this.filteredSensis.length;
        let label = this.$filtersPopinMobileResults.getAttribute( 'data-no-results' );
        if ( filteredSensisCount === 0 ) {
            label = this.$filtersPopinMobileResults.getAttribute( 'data-no-results' );
        } else if ( filteredSensisCount === 1 ) {
            label = this.$filtersPopinMobileResults.getAttribute( 'data-single-result' );
        } else {
            label = stringFormat( this.$filtersPopinMobileResults.getAttribute( 'data-multiple-results' ), filteredSensisCount );
        }
        this.$filtersPopinMobileResults.innerHTML = label;
    }


    initSensiPopin() {
        this.isSensiPopinOpen = false;
        this.isSensiImagePopinOpen = false;
        this.actionPopinDefaultClass = this.$sensiPopin.getAttribute( 'class' );
        this.isSensiPopinSensiId = null;
        this.actionPopinMedia = null;
        this.sensiPopinInfo = null;
        this.actionPopinFromHistory = false;
    }


    addSensiPopinEventListeners() {
        if ( this.$sensiPopin ) {
            if ( this.$sensiPopinClose ) {
                addEventListener( this.$sensiPopinClose, 'click', ( e ) => {
                    e.target.blur();
                    this.actionPopinFromHistory = false;
                    this.hideSensiPopin();
                } );
            }
        }
        this.$sensiPopinImageClose.addEventListener('click',()=>{
            this.hideSensiImagePopin();
        })

        addEventListener( window, 'popstate', ( event ) => {
            this.actionPopinFromHistory = true;
            if ( hasProperty( event.state, 'sensi_id' ) ) {
                this.showSensiPopinForSensi( event.state.sensi_id );
            } else {
                this.hideSensiPopin();
            }
        } );
    }


    showSensiPopinForSensi( sensiId, zoomOnMap = false ) {
        if ( sensiId && ( this.isSensiPopinSensiId !== sensiId ) ) {
            this.isSensiPopinSensiId = sensiId;
            this.showSensiPopin();
            if ( hasProperty( this.actionsPopinData, sensiId ) && this.actionsPopinData[sensiId].ready ) {
                const data = this.actionsPopinData[sensiId].data;
                this.showSensiPopinDataForSensi( data );
            } else {
                const showSensiPopinDataWhenLoaded = ( id, data ) => {
                    if ( sensiId === id ) {
                        this.off( 'sensi-popin-data-loaded', showSensiPopinDataWhenLoaded );
                        this.showSensiPopinDataForSensi( data );
                    }
                };

                this.on( 'sensi-popin-data-loaded', showSensiPopinDataWhenLoaded );
                this.fetchSensiPopinData( sensiId );
            }
        }
    }


    showSensiPopinDataForSensi( data, zoomOnMap = false ) {

        // Media
        const mediaDOMElement = convertHTMLStringToDOMElement( data.media_html );
        if ( mediaDOMElement ) {
            this.$sensiPopinMedia.firstElementChild.appendChild( mediaDOMElement );
        }
        // Info
        const infoDOMElement = convertHTMLStringToDOMElement( data.info_html );
        if ( infoDOMElement ) {
            this.$sensiPopinInfo.firstElementChild.appendChild( infoDOMElement );

        }
        // Animate
        this.updateSensiPopinMediaMinWidth();
        if ( this.sensiPopinInfo ) {
            css( this.sensiPopinInfo, { display: 'none' } );
        }
        css( infoDOMElement, { display: 'block' } );
        TweenMax.fromTo( mediaDOMElement, 0.7 ,{ width: '0%' }, { width: '100%', ease: Power1.easeOut, onStart: () => {
            addClass( mediaDOMElement, 'active' );
        } } );
        if ( this.sensiPopinInfo ) {
            TweenMax.fromTo( this.sensiPopinInfo, 0.3, { y: 0, autoAlpha: 1 }, { x: 25, autoAlpha: 0, ease: Power1.easeOut } );
        }
        TweenMax.fromTo( infoDOMElement, 0.5, { x: -25, autoAlpha: 0 }, { x: 0, autoAlpha: 1, delay: 0.2, ease: Power1.easeOut, onComplete: () => {
            addClass( infoDOMElement, 'active' );
            this.resetSensiPopinData();
            this.actionPopinMedia = mediaDOMElement;
            this.sensiPopinInfo = infoDOMElement;
            if ( this.$sensiPopinInfo.querySelector('.c-sensi-map-map__sensi__popin--slider')) {
                this.$sensiPopinInfo.querySelectorAll('[data-src]').forEach(img=>{
                    img.addEventListener('click',()=>{
                        this.showSensiImagePopin(img);
                    })
                })
                if (this.flkty) {
                    this.flkty.destroy()
                }
                // FLICKITY
                this.flkty = new Flickity( this.$sensiPopinInfo.querySelector('.c-sensi-map-map__sensi__popin--slider'), {
                    cellAlign: 'left',
                    contain: true,
                    groupCells: true,
                    watchCSS: true,
                    wrapAround: false,
                    draggable:false,
                    arrowShape: 'M55.8,56.7c0.3,0,0.6-0.2,0.9-0.3l7.9-7.7c0.3-0.2,0.3-0.6,0.3-0.9c0-0.3-0.2-0.6-0.3-0.9l-7.7-7.7 c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0.2-0.8,0.5-0.9,0.9s0,0.9,0.5,1.3l5.7,5.5H31.4c-0.8,0-1.3,0.6-1.3,1.3s0.5,1.3,1.3,1.3h29.1 l-5.7,5.7c-0.5,0.5-0.5,1.3,0,1.7C55,56.5,55.3,56.7,55.8,56.7'
                });
            }
            if ( this.app.isMobile ) {
                disableBodyScroll( this.sensiPopinInfo.querySelector( '.u-overflow-container' ) );
            }
        } } );

        // Zoom on map
        if ( zoomOnMap ) {
            this.mapboxMap.jumpTo( {
                center: data.gps_coordinates,
                zoom: this.mapMaxZoom
            } );
        }
    }


    resetSensiPopinData() {
        if ( this.actionPopinMedia ) {
            this.$sensiPopinMedia.firstElementChild.removeChild( this.actionPopinMedia );
            this.actionPopinMedia = null;
        }
        if ( this.sensiPopinInfo ) {
            this.$sensiPopinInfo.firstElementChild.removeChild( this.sensiPopinInfo );
            this.sensiPopinInfo = null;
        }
    }


    showSensiPopin() {
        if ( this.isSensiPopinOpen ) {
            return;
        }

        if ( this.$sensiPopin ) {
            this.isSensiPopinOpen = true;
            this.updateSensiPopinPositionAndSize();
            css( this.$sensiPopin, { visibility: 'visible' } );
            if ( this.app.isMobile ) {
                disableBodyScroll( this.$sensiPopin );
            }
            addClass( this.$sensiPopin, this.classname + '__sensi__popin--show' );
        }
    }
    showSensiImagePopin(img) {
        if ( this.isSensiImagePopinOpen ) {
            return;
        }
        if ( this.$sensiPopinImage ) {
            let image = document.createElement('img')
            let title = document.createElement('span')
            image.src = img.getAttribute('data-src')
            title.classList.add('title')
            title.innerText = img.getAttribute('alt') 
            document.querySelector('.' + this.classname + '__sensi__popin__image__container').append(image,title)
            this.isSensiImagePopinOpen = true;
            css( this.$sensiPopinImage, { visibility: 'visible' } );
            if ( this.app.isMobile ) {
                disableBodyScroll( this.$sensiPopinImage );
            }
            addClass( this.$sensiPopinImage, this.classname + '__sensi__popin__image--show' );
        }
    }



    updateSensiPopinMediaMinWidth() {
        if ( this.$sensiPopinMedia && this.$sensiPopinInfo ) {
            const mediaItems =  this.$sensiPopinMedia.querySelectorAll( '.' + this.classname + '__sensi__popin__media__item' );
            if ( mediaItems ) {
                for ( const media of mediaItems ) {
                    var mediaFirstElementChild = media.firstElementChild;
                    if ( mediaFirstElementChild ) {
                        let minWidth = getPageWidth();
                        if ( this.app.isBreakpointUp( 'lg' ) ) {
                            minWidth = this.$sensiPopinMedia.getBoundingClientRect().width;
                        }
                        css( mediaFirstElementChild, { minWidth: minWidth + 'px' } );
                    }
                }
            }
        }
    }


    hideSensiPopin() {
        if ( !this.isSensiPopinOpen ) {
            return;
        }

        if ( this.$sensiPopin ) {
            removeClass( this.$sensiPopin, this.classname + '__sensi__popin--show' );
            if ( this.app.isMobile ) {
                clearAllBodyScrollLocks();
            }
            setTimeout( () => {
                css( this.$sensiPopin, { visibility: 'hidden' } );
                this.resetSensiPopinData();
                if (this.flkty) {
                    this.flkty.destroy()
                }
                this.isSensiPopinSensiId = null;
                this.isSensiPopinOpen = false;
            }, 300 );
        }
    }
    hideSensiImagePopin() {
        if ( !this.isSensiImagePopinOpen ) {
            return;
        }
        if ( this.$sensiPopinImage ) {
            removeClass( this.$sensiPopinImage, this.classname + '__sensi__popin__image--show' );
            if ( this.app.isMobile ) {
                clearAllBodyScrollLocks();
            }
            setTimeout( () => {
                css( this.$sensiPopinImage, { visibility: 'hidden' } );
                document.querySelector('.' + this.classname + '__sensi__popin__image__container').innerHTML = ""
                this.isSensiImagePopinOpen = false;
            }, 300 );
        }
    }

    updateSensiPopinPositionAndSize() {
        if ( this.isSensiPopinOpen && this.$sensiPopinContainer && this.$sensiPopin ) {
            const actionPopinMediaRatio = { width: 540, height: 292 };
            const elBoundingClientRect = this.$el.getBoundingClientRect();
            if ( this.app.isBreakpointUp( 'lg' ) ) {
                css( this.$sensiPopinContainer, {
                    top: this.$el.offsetTop + 'px',
                    left: this.$el.offsetParent.offsetLeft + 'px',
                    height: elBoundingClientRect.height + 'px'
                } );
                css( this.$sensiPopin, {
                    height: elBoundingClientRect.height + 'px'
                } );
                css( this.$sensiPopinInfo, {
                    height: ( elBoundingClientRect.height - actionPopinMediaRatio.height ) + 'px'
                } );
            } else {
                css( this.$sensiPopinContainer, { top: null, left: null, height: null } );
                css( this.$sensiPopin, { height: null } );
                css( this.$sensiPopinInfo, {
                    height: ( getPageHeight() - ( getPageWidth() * actionPopinMediaRatio.height / actionPopinMediaRatio.width ) ) + 'px'
                } );
            }
        }
    }


    checkOnLoadSensiPopin() {
        if ( hasProperty( window.FNC, 'actions_map_sensi_popin_data' ) ) {
            const sensiId = window.FNC.actions_map_sensi_popin_data.sensi_id;
            this.actionsPopinData[sensiId] = {
                ready: true,
                data: window.FNC.actions_map_sensi_popin_data
            };
            window.scrollTo( 0, this.$el.offsetTop - this.$navbar.getBoundingClientRect().height );
            const actionData = window.FNC.actions_map_sensi_popin_data;
            if(!this.$el.classList.contains('in-master')){
                history.replaceState( { sensi_id: actionData.sensi_id }, actionData.page_title, actionData.url );  
            }
            this.showSensiPopin();
            this.showSensiPopinDataForSensi( actionData, true );
        }
    }


    resize() {
        this.updateSensiPopinPositionAndSize();
        this.updateSensiPopinMediaMinWidth();
    }


    destroy() {}
}