import AbstractComponent from "../AbstractComponent";
import { addClass, removeClass } from '../../utils/CssUtils';
import { addEventListener, preventDefault } from "../../utils/EventUtils";
import Logger from '../../utils/Logger';
import Flickity from 'flickity';
import VideoEmbed from "../video-embed";


export default class HomeSectionVideos extends AbstractComponent {
    constructor(...args) {
        Logger.log('HomeSectionVideos->constructor()');
        super(...args);

        this.classname = 'c-section-videos';
        this.mobileBreakpoints = [ 'xs', 'sm', 'md' ];
        this.desktopBreakpoints = [ 'lg', 'xl', 'xxl' ];
        this.initDomElements();
        this.initSlider();
        this.initItems();
        this.addEventListeners();
    }


    initDomElements() {
        this.$sliderContainer = this.$el.querySelector( '.' + this.classname + '__content__highlight-items' );
        this.$highlightVideoItems = this.$el.querySelectorAll( '.' + this.classname + '__content__highlight-item' );
        this.$videoItems = this.$el.querySelectorAll( '.' + this.classname + '__content__item' );
    }


    initSlider() {
        if ( ( this.$highlightVideoItems && ( this.$highlightVideoItems.length > 0 ) ) 
          && ( this.$videoItems && ( this.$videoItems.length > 0 ) ) ) {
            const sliderConfiguration = this.getSliderConfiguration();
            this.slider = new Flickity( this.$sliderContainer, sliderConfiguration );
            if ( this.activeIndex ) {
                this.slider.select( this.activeIndex, false, true );
            }
            this.slider.on( 'select', ( i ) => this.setActiveItem( i ) );
        }
    }


    destroySlider() {
        if ( this.slider ) {
            this.slider.destroy();
        }
    }


    getSliderConfiguration() {
        const sliderConfiguration = {
            arrowShape: 'M55.8,56.7c0.3,0,0.6-0.2,0.9-0.3l7.9-7.7c0.3-0.2,0.3-0.6,0.3-0.9c0-0.3-0.2-0.6-0.3-0.9l-7.7-7.7 c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0.2-0.8,0.5-0.9,0.9s0,0.9,0.5,1.3l5.7,5.5H31.4c-0.8,0-1.3,0.6-1.3,1.3s0.5,1.3,1.3,1.3h29.1 l-5.7,5.7c-0.5,0.5-0.5,1.3,0,1.7C55,56.5,55.3,56.7,55.8,56.7'
        };
        // Mobile
        if ( this.app.isBreakpointDown( 'md' ) ) {
            sliderConfiguration.accessibility = true;
            sliderConfiguration.draggable = true;
            sliderConfiguration.pageDots = true;
            sliderConfiguration.prevNextButtons = true;
            sliderConfiguration.wrapAround = true;
        // Desktop
        } else if ( this.app.isBreakpointUp( 'lg' ) ) {
            sliderConfiguration.accessibility = false;
            sliderConfiguration.draggable = false;
            sliderConfiguration.pageDots = false;
            sliderConfiguration.prevNextButtons = false;
            sliderConfiguration.wrapAround = false;
        }
        return sliderConfiguration;
    }


    initItems() {
        this.activeIndex = null;
        this.activeItem = null;
        this.items = [];
        if ( this.$videoItems && ( this.$videoItems.length > 0 ) ) {
            for ( let i = 0; i < this.$videoItems.length; i++ ) {
                const item = {};
                item.videoItem = this.$videoItems[i];
                item.highlightVideoItem = this.$highlightVideoItems[i];
                const mediaVideo = item.highlightVideoItem.querySelector( '.' + this.classname + '__media__video' );
                if ( mediaVideo ) {
                    item.mediaVideo = new VideoEmbed( mediaVideo );
                }
                this.items.push( item );
            }
        }
        this.setActiveItem( 0, true );
    }

    
    setActiveItem( index, updateSlider = false, playVideo = false ) {
        if ( this.activeIndex != index ) {
            // Resetting an already running video
            if ( this.activeItem ) {
                this.activeItem.mediaVideo.reset();
            }

            // Setting the active item
            this.activeIndex = index;
            this.activeItem = this.items[index];
    
            // Updating the active item visually
            const activeClassname = this.classname + '__content__item--active';
            removeClass( this.$videoItems, activeClassname );
            addClass( this.activeItem.videoItem, activeClassname );
        }

        // Updating the slider
        if ( updateSlider ) {
            this.slider.select( index, false, true );
        }

        // Playing the video
        if ( playVideo ) {
            this.activeItem.mediaVideo.play();
        }
    }

    addEventListeners() {
        this.app.on( 'breakpoint-update', this.breakpointUpdateListener.bind( this ) );

        for ( let i = 0; i < this.$videoItems.length; i++ ) {
            const videoItem = this.$videoItems[i];
            addEventListener( videoItem, 'click', () => this.setActiveItem( i, true, true ) );
        }
    }


    breakpointUpdateListener( newBreakpoint, previousBreakpoint ) {
        const mobileToDesktop = this.isInBreakpoints( previousBreakpoint, this.mobileBreakpoints )
            && this.isInBreakpoints( newBreakpoint, this.desktopBreakpoints );
        const desktopToMobile = this.isInBreakpoints( previousBreakpoint, this.desktopBreakpoints )
            && this.isInBreakpoints( newBreakpoint, this.mobileBreakpoints );
        if ( mobileToDesktop || desktopToMobile ) {
            this.destroySlider();
            this.initSlider();
        }
    }


    isInBreakpoints( breakpoint, breakpoints ) {
        return breakpoints.indexOf( breakpoint ) !== -1
    }

    destroy() {}
}