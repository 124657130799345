import AbstractComponent from '../AbstractComponent';
import Logger from '../../utils/Logger';
import { addClass, css, removeClass } from '../../utils/CssUtils';
import { addEventListener } from '../../utils/EventUtils';
import { createHTMLElement } from '../../utils/HtmlUtils';
import { hasProperty } from '../../utils/JsUtils';
import { TweenMax, TimelineLite, Power1, Linear, CSSPlugin } from 'gsap/all';


export default class HomeSectionMap extends AbstractComponent {
    constructor(...args) {
        Logger.log('HomeSectionMap->constructor()');
        super(...args);

        this.classname = 'c-section-map';
        this.bounds = {
            'default': {
                'latMin': 40.5,
                'latMax': 51.1,
                'lngMin': -4.65,
                'lngMax': 9
            },
            'corsica': {
                'latMin': 41.3871740,
                'latMax': 47,
                'lngMin': 3.7,
                'lngMax': 9.5
            },
            'check-corsica': {
                'latMin': 41.30,
                'latMax': 43.03,
                'lngMin': 8.54,
                'lngMax': 9.61
            }            
        };

        this.initDomElements();
        this.initMap();
        this.initSlider();
        this.addEventListeners();
    }


    initDomElements() {
        this.$map = this.$el.querySelector( '.' + this.classname +  '__head__map' );
        this.$sliderMedia = this.$el.querySelector( '.' + this.classname +  '__slider__media' );
        this.$sliderMediaItems = this.$el.querySelectorAll( '.' + this.classname +  '__slider__media__item' );
        this.$sliderInfo = this.$el.querySelector( '.' + this.classname +  '__slider__info' );
        this.$sliderInfoItems = this.$el.querySelectorAll( '.' + this.classname +  '__slider__info__item' );
        this.$sliderPrevButton = this.$el.querySelector( '.' + this.classname +  '__slider__controls__nav--prev' );
        this.$sliderNextButton = this.$el.querySelector( '.' + this.classname +  '__slider__controls__nav--next' );
    }


    initMap() {
        if ( this.$sliderInfoItems && ( this.$sliderInfoItems.length > 0 ) ) {
            for ( let i = 0; i < this.$sliderInfoItems.length; i++ ) {
                const action = this.$sliderInfoItems[i];
                const lat = action.getAttribute( 'data-lat' );
                const lng = action.getAttribute( 'data-lng' );
                if ( ( lat != null ) && ( lng != null ) ) {
                    const mapPosition = this.getMapPosition( lat, lng );
                    if ( !isNaN( mapPosition.x ) && !isNaN( mapPosition.y ) ) {
                        action.mapDot = createHTMLElement( 'button', { classname: this.classname + '__head__map__dot' } );
                        const program = action.getAttribute( 'data-program' );
                        if ( program != null ) {
                            addClass( action.mapDot, this.classname + '__head__map__dot--' + program );
                        }
                        css( action.mapDot, { left: mapPosition.x + '%', bottom: mapPosition.y + '%' } );
                        this.$map.appendChild( action.mapDot );
                        addEventListener( action.mapDot, 'click', () => this.showSliderItem( i ) );
                    }
                }
            }
            this.updateSelectedMapDot( 0 );
        }
    }


    updateSelectedMapDot( index ) {
        if ( this.$sliderInfoItems ) {
            for ( let i = 0; i < this.$sliderInfoItems.length; i++ ) {
                const action = this.$sliderInfoItems[i];
                if ( hasProperty( action, 'mapDot' ) ) {
                    removeClass( action.mapDot, this.classname + '__head__map__dot--active' );
                }
                if ( i == index ) {
                    addClass( action.mapDot, this.classname + '__head__map__dot--active' );
                }
            }
        }
    }
    

    initSlider() {
        this.slider = {
            current: 0,
            running: false
        };
        css( this.$sliderMediaItems[this.slider.current], { width: '100%' } );
        // css( this.$sliderInfoItems[this.slider.current], { position: 'relative' } );
        css( this.$sliderInfoItems[this.slider.current], { display: 'block' } );
        this.updateSliderItemProgramClass( null, this.$sliderInfoItems[this.slider.current] );
        this.updateMediaMinWidth();
    }


    updateMediaMinWidth() {
        for ( const media of this.$sliderMediaItems ) {
            var mediaElement = media.querySelector( 'img' );
            if ( mediaElement ) {
                css( mediaElement, { minWidth: this.$sliderMedia.getBoundingClientRect().width + 'px' } );
            }
        }
    }


    addEventListeners() {
        if ( this.$sliderPrevButton ) {
            this._showPreviousSliderItem = this.showPreviousSliderItem.bind( this );
            addEventListener( this.$sliderPrevButton, 'click', this._showPreviousSliderItem );
        }
        if ( this.$sliderNextButton ) {
            this._showNextSliderItem = this.showNextSliderItem.bind( this );
            addEventListener( this.$sliderNextButton, 'click', this._showNextSliderItem );
        }

        // Resize
        this.app.on( 'resize', this.resize.bind(this) );
    }


    showPreviousSliderItem() {
        if ( !this.slider.running ) {
            this.slider.running = true;

            // Define current and prev
            var current = this.slider.current;
            var prev = ( current == 0 ) ? this.$sliderMediaItems.length - 1 : current - 1;

            // Animate
            this.updateSliderItemProgramClass( this.$sliderInfoItems[current], this.$sliderInfoItems[prev] );
            this.updateSelectedMapDot( prev );
            TweenMax.set( this.$sliderMediaItems[prev], { width: '100%' } );
            css( this.$sliderInfoItems[current], { display: 'none' } );
            css( this.$sliderInfoItems[prev], { display: 'block' } );
            // TweenMax.set( this.$sliderInfoItems[current], { clearProps: 'position' } );
            // TweenMax.set( this.$sliderInfoItems[prev], { position: 'relative' } );
            TweenMax.fromTo( this.$sliderMediaItems[current], 0.7, { width: '100%' }, { width: '0%', ease: Power1.easeOut, onComplete: () => {
                addClass( this.$sliderMediaItems[prev], 'active' );
                removeClass( this.$sliderMediaItems[current], 'active' );
            } } );
            TweenMax.fromTo( this.$sliderInfoItems[current], 0.3, { y: 0, autoAlpha: 1 }, { x: -25, autoAlpha: 0, ease: Power1.easeOut });
            TweenMax.fromTo( this.$sliderInfoItems[prev], 0.5, { x: 25, autoAlpha: 0 }, { x: 0, autoAlpha: 1, delay: 0.2, ease: Power1.easeOut, onComplete: () => {
                addClass( this.$sliderInfoItems[prev], 'active' );
                removeClass( this.$sliderInfoItems[current], 'active' );
                this.$sliderInfoItems[current].firstElementChild.scrollTop = 0;
                this.slider.current = prev;
                this.slider.running = false;
            } } );
        }
    }


    showNextSliderItem() {
        if ( !this.slider.running ) {
            this.slider.running = true;

            // Define current and next
            var current = this.slider.current;
            var next = ( current == this.$sliderMediaItems.length - 1 ) ? 0 : current + 1;
            
            // Animate
            this.animateNextSliderItem( current, next );
        }
    }


    showSliderItem( index ) {
        if ( !this.slider.running ) {
            this.slider.running = true;

            // Define current and next
            var current = this.slider.current;
            var next = index;

            if ( current != next ) {
                // Animate
                this.animateNextSliderItem( current, next );
            } else {
                this.slider.running = false;
            }
        }
    }


    animateNextSliderItem( current, next ) {
        this.updateSliderItemProgramClass( this.$sliderInfoItems[current], this.$sliderInfoItems[next] );
        this.updateSelectedMapDot( next );
        css( this.$sliderInfoItems[current], { display: 'none' } );
        css( this.$sliderInfoItems[next], { display: 'block' } );
        // TweenMax.set( this.$sliderInfoItems[current], { clearProps: 'position' } );
        // TweenMax.set( this.$sliderInfoItems[next], { position: 'relative' } );
        TweenMax.fromTo( this.$sliderMediaItems[next], 0.7 ,{ width: '0%' }, { width: '100%', ease: Power1.easeOut, onStart: () => {
            addClass( this.$sliderMediaItems[next], 'active' );
            if ( next == 0 ) {
                removeClass( this.$sliderMediaItems[current], 'active' );
            }
        } } );
        TweenMax.fromTo( this.$sliderInfoItems[current], 0.3, { y: 0, autoAlpha: 1 }, { x: 25, autoAlpha: 0, ease: Power1.easeOut });
        TweenMax.fromTo( this.$sliderInfoItems[next], 0.5, { x: -25, autoAlpha: 0 }, { x: 0, autoAlpha: 1, delay: 0.2, ease: Power1.easeOut, onComplete: () => {
            addClass( this.$sliderInfoItems[next], 'active' );
            removeClass( this.$sliderInfoItems[current], 'active' );
            removeClass( this.$sliderMediaItems[current], 'active' );
            this.$sliderInfoItems[current].firstElementChild.scrollTop = 0;
            css( this.$sliderMediaItems[current], { width: '0' } );
            this.slider.current = next;
            this.slider.running = false;
        } } );
    }


    updateSliderItemProgramClass( previous, next ) {
        if ( previous ) {
            const previousProgram = previous.getAttribute( 'data-program' );
            if ( previousProgram != null ) {
                removeClass( this.$sliderInfo, this.classname + '__slider__info--' + previousProgram );
            }
        }
        if ( next ) {
            const nextProgram = next.getAttribute( 'data-program' );
            if ( nextProgram != null ) {
                addClass( this.$sliderInfo, this.classname + '__slider__info--' + nextProgram );
            }
        }
    }


    getMapPosition( lat, lng ) {
        const mapBounds = this.isInCorsica( lat, lng ) ? this.bounds.corsica : this.bounds.default;
        const x = this.convertLngToX( lng, mapBounds );
        const y = this.convertLatToY( lat, mapBounds );
        return { x: x, y: y };
    }


    isInCorsica( lat, lng ) {
        const corsicaBounds = this.bounds['check-corsica'];
        const checkLat = ( ( lat >= corsicaBounds.latMin ) && ( lat <= corsicaBounds.latMax ) );
        const checkLng = ( ( lng >= corsicaBounds.lngMin ) && ( lng <= corsicaBounds.lngMax ) );
        return checkLat && checkLng ;
    }


    convertLngToX( lng, bounds ) {
        var position = ( lng - bounds.lngMin ) / ( bounds.lngMax - bounds.lngMin );
        return 100 * position;
    }


    convertLatToY( lat, bounds ) {
        var position = ( lat - bounds.latMin ) / ( bounds.latMax - bounds.latMin );
        return 100 * position;
    }


    resize() {
        this.updateMediaMinWidth();
    }


    destroy() {}
}