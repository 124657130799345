import '../utils/RequestAnimationFrame'; // Cross browser RequestAnimationFrame implementation
import { debounce } from 'lodash';
import { addClass, css, hasClass, removeClass } from '../utils/CssUtils';
import { addEventListener } from '../utils/EventUtils';
import { getPageWidth, hasProperty } from '../utils/JsUtils';
import EventEmitter from 'eventemitter3';
import MobileDetect from 'mobile-detect';
import DefaultController from './DefaultController';
import Logger from '../utils/Logger';
import 'svgxuse';
import SiteMenu from '../components/site-menu';
import SiteFooter from '../components/site-footer';
import Popin from '../components/popin';
import components from '../components/_map';
import controllers from './_map';


export default class AppController extends EventEmitter {
    constructor() {
        super();
        Logger.log( 'AppController->constructor()' );

        // Handling singleton creation
        if ( !AppController.singleton ) {
            // Storing the singleton instance
            AppController.singleton = this;

            // Fields
            this.controllers = controllers;
            this.currentController = null;
            this.previousController = null;
            this.components_map = components;
            this.components = [];
            this.animation = {
                loader: {
                    duration: 750,
                    interval: 250,
                    timeout: 2500
                }
            };

            // Initializing
            this.initDomElements();
            this.initViewportVhVar();
            this.initDeviceOrientationDetection();
            this.initPopin();
            this.instantiateController( this.$body.getAttribute( 'data-controller' ), this.controllerReady.bind( this ) );
            this.instantiateComponents();
            this.initMenu();
            this.initFooter();
            this.initCustomRaf();
            this.initEventListeners();
            this.checkLoader();

            /** TEMP : GRID DEBUG **/
            // Bind CTRL key to display grid columns over the content
            this._grid_shown = false;
            this._grid_controlled = false;
            document.addEventListener('keydown', (event) => {
                if (event.defaultPrevented) {
                    return;
                }
                var key = event.key || event.keyCode;
                if (key === 'Control' || key === 'ControlLeft' || key === 17) {
                    if( this._grid_controlled == false ) {
                        this._grid_controlled = true;
                    }
                }
                if (key === 'g' || key === 'KeyG' || key === 71) {
                    if( this._grid_controlled == true && this._grid_shown == false ) {
                        this._grid_shown = true;
                        this.$main.classList.add( 'debug-grid' );
                    }
                }
            });
            document.addEventListener('keyup', (event) => {
                if (event.defaultPrevented) {
                    return;
                }
                var key = event.key || event.keyCode;
                if (key === 'Control' || key === 'ControlLeft' || key === 17) {
                    if( this._grid_controlled == true ) {
                        this._grid_controlled = false;
                    }
                }
                if (key === 'g' || key === 'KeyG' || key === 71) {
                    if( this._grid_shown == true ) {
                        this._grid_shown = false;
                        this.$main.classList.remove( 'debug-grid' );
                    }
                }
            });

        }
       
        // Returning the singleton
        return AppController.singleton;
    }


    static getInstance() {
        return AppController.singleton;
    }

    
    initDomElements() {
        this.$html = document.getElementsByTagName( 'html' )[0];
        this.$body = document.getElementsByTagName( 'body' )[0];
        this.$main = document.getElementsByTagName( 'main' )[0];
        this.$loader = document.querySelector( '.c-page-transition' );
    }


    initViewportVhVar() {
        this.updateViewportVhVar();
        this.addListener( 'resize', this.updateViewportVhVar );
    }


    initMenu() {
        this.Menu = new SiteMenu();
    }

    initFooter() {
        this.Footer = new SiteFooter();
        this.on( 'dropdown_item_toggled', ( dropdownItem, open ) => {
            this.Footer.udpateDropdownSection( dropdownItem, open );
        } )
    }

    initPopin() {
        this.Popin = new Popin();
    }

    startLeavingPageTransition( href ) {
        this.emit( 'leaving_page_transition_call', href )
    }


    updateViewportVhVar() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty( '--vh', `${ vh }px` );
    }


    initDeviceOrientationDetection() {
        // Device and orientation detection fields
        this.device = 'mobile';
        this.isMobile = true;
        this.isTablet = false;
        this.isDesktop = false;
        this.orientation = 'landscape';
        this.isLandscape = true;
        this.isPortrait = false;
        this.breakpoints = {
            'xs': 0,
            'sm': 576,
            'md': 768,
            'lg': 1024,
            'xl': 1280,
            'xxl': 1600
        };
        this.breakpoint = 'xs';

        this.detectDeviceOrientationAndBreakpoint();
        this.addListener( 'resize', this.detectDeviceOrientationAndBreakpoint.bind( this ) );
    }


    detectDeviceOrientationAndBreakpoint() {
        this.detectDevice();
        this.detectOrientation();
        this.detectBreakpoint();
    }


    detectDevice() {
        removeClass( this.$html, 'mobile' );
        removeClass( this.$html, 'tablet' );
        removeClass( this.$html, 'desktop' );
        const md = new MobileDetect( window.navigator.userAgent );
        this.device = md.tablet() ? 'tablet' : ( md.mobile() ? 'mobile' : 'desktop' );
        this.isMobile = ( this.device == 'mobile' );
        this.isTablet = ( this.device == 'tablet' );
        this.isDesktop = ( this.device == 'desktop' );
        // this.isSafari = ( md.match( 'Safari' ) && !md.match( 'Chrome' ) && md.ua.indexOf( 'CriOS' ) == -1 ); // to avoid chrome IOS to be safari detected
        this.isSafari = ( md.match( 'Safari' ) && !md.match( 'Chrome' ) );
        this.isEdge = ( md.match( 'Edge' ) && md.match( 'Safari' ) && md.match( 'Chrome' ) );
        this.isIE = ( md.match( 'MSIE' ) || md.match( 'Trident' ) );
        this.isFirefox = ( md.match( 'Firefox' ) );
        addClass( this.$html, this.device );
        if( this.isEdge )
            addClass( this.$html, 'edge' );
        if( this.isSafari )
            addClass( this.$html, 'safari' );
        if( this.isFirefox )
            addClass( this.$html, 'firefox' );
        if( this.isIE )
            addClass( this.$html, 'ie' );
    }

    
    detectOrientation() {
        removeClass( this.$html, 'landscape' );
        removeClass( this.$html, 'portrait' );
        this.orientation = ( window.innerWidth > window.innerHeight ) ? 'landscape' : 'portrait';
        this.isLandscape = ( this.orientation == 'landscape' );
        this.isPortrait = ( this.orientation == 'portrait' );
        addClass( this.$html, this.orientation );
    }

    
    detectBreakpoint() {
        const previousBreakpoint = this.breakpoint;
        const newBreakpoint = this.getCurrentBreakpoint();
        if ( newBreakpoint !== previousBreakpoint ) {
            this.breakpoint = newBreakpoint;
            this.emit( 'breakpoint-update', newBreakpoint, previousBreakpoint );
        }
    }


    getCurrentBreakpoint() {
        const pageWidth = getPageWidth();
        let previousBreakpoint = null;
        for ( const breakpoint in this.breakpoints ) {
            if ( pageWidth < this.breakpoints[breakpoint] ) {
                break;
            }
            previousBreakpoint = breakpoint;
        }
        return previousBreakpoint;
    }



    getBreakpointNext( breakpoint ) {
        let breakpointFound = false;
        for ( let currentBreakpoint in this.breakpoints ) {
            if ( breakpointFound ) {
                return currentBreakpoint;
            }
            if ( currentBreakpoint == breakpoint ) {
                breakpointFound = true;
            }
        }
        return null;
    }


    getBreakpointMin( breakpoint ) {
        if ( hasProperty( this.breakpoints, breakpoint ) ) {
            return this.breakpoints[breakpoint];
        }
        return null;
    }


    getBreakpointMax( breakpoint ) {
        const nextBreakpoint = this.getBreakpointNext( breakpoint );
        if ( nextBreakpoint != null ) {
            if ( hasProperty( this.breakpoints, nextBreakpoint ) ) {
                return this.breakpoints[nextBreakpoint] - .02;
            }
        }
        else if ( hasProperty( this.breakpoints, breakpoint ) ) {
            return Infinity;
        }
        return null;
    }


    isBreakpointUp( breakpoint ) {
        const min = this.getBreakpointMin( breakpoint );
        if ( min != null ) {
            return getPageWidth() >= min;
        }
        return false;
    }


    isBreakpointDown( breakpoint ) {
        const max = this.getBreakpointMax( breakpoint );
        if ( max ) {
            return getPageWidth() < max;
        }
        return false;
    }


    isBreakpointBetween( lower, upper ) {
        const min = this.getBreakpointMin( lower );
        const max = this.getBreakpointMax( upper );
        if ( ( min != null ) && ( max != null ) ) {
            const pageWidth = getPageWidth();
            return ( pageWidth >= min ) && ( pageWidth < max );
        }
        else if ( max == null ) {
            return this.isBreakpointUp( lower );
        }
        else if ( min == null ) {
            return this.isBreakpointDown( upper );
        }
        return false;
    }


    getCurrentBreakpointValue( breakpoints ) {
        return this.getBreakpointValue( breakpoints, this.breakpoint );
    }

    
    getBreakpointValue( breakpoints, breakpoint ) {
        let currentValue = null;
        if ( breakpoints ) {
            let breakpointFound = false;
            for ( const currentBreakpoint in this.breakpoints ) {
                if ( hasProperty( breakpoints, currentBreakpoint ) ) {
                    currentValue = breakpoints[currentBreakpoint];
                }
                if ( currentBreakpoint == breakpoint ) {
                    breakpointFound = true;
                    if ( currentValue !== null ) {
                        return currentValue;
                    }
                } else {
                    if ( breakpointFound && ( currentValue !== null ) ) {
                        return currentValue;
                    }
                }
            }
        }
        return currentValue;
    }

    
    instantiateController( namespace, callback = null ) {
        this.$body.setAttribute( 'data-controller', namespace );

        if ( hasProperty( this.controllers, namespace ) ) {
            const controller = this.controllers[namespace];

            if ( controller ) {
                this.setCurrentController( new controller.default( callback ) );
                return;
            }
        }
    
        this.setCurrentController( new DefaultController( callback ) );
    }


    updateBodyClassesFromContainers( currentContainer, nextContainer ) {
        if ( currentContainer ) {
            removeClass( this.$body, currentContainer.getAttribute( 'data-body-class' ) );
        }

        if ( nextContainer ) {
            const nextContainerBodyClass = nextContainer.getAttribute( 'data-body-class' );
            if ( nextContainerBodyClass && !hasClass( this.$body, nextContainerBodyClass ) ) {
                addClass( this.$body, nextContainerBodyClass );
            }
        }
    }


    setCurrentController( controller ) {
        this.previousController = this.currentController;
        this.currentController = controller;
    }


    getCurrentController() {
        return this.currentController;
    }


    getPreviousController() {
        return this.previousController;
    }


    clearPreviousController() {
        this.destroyPreviousController();
        this.resetPreviousController();
    }

    
    destroyPreviousController() {
        if ( this.previousController ) {
            this.previousController.destroy();
        }
    }


    resetPreviousController() {
        this.previousController = null;
    }


    instantiateComponents() {
        const components = this.$body.querySelectorAll( '[data-component]' );
        for ( let i = 0; i < components.length; i++ ) {
            const component = components[i];
            const name = component.getAttribute('data-component');
            if ( hasProperty( this.components_map, name ) ) {
                const componentClass = this.components_map[name];
                if ( componentClass ) {
                    this.components.push( new componentClass.default( component ) );
                }
            }
        }
    }

    initEventListeners() {
        addEventListener( document.body, 'click', (event) => {
            this.emit( 'body_click', event );
        } );

        addEventListener( window, 'resize', debounce( ( event ) => {
            this.emit( 'resize', event );
            this.resize();
        }, 16 ) );

        // Handle leaving animation page to avoid navigator to immediatly switch page
        this.on( 'leaving_page_transition_start', ( data ) => {
            setTimeout(() => {
                window.location = data.href;
            }, data.duration * 1000 );
        });
    }


    initCustomRaf() {
        this.ticking = false;
        this.lastScrollY = window.scrollY;
        addEventListener( window, 'scroll', this.onScrollHandler.bind(this) );
    }


    onScrollHandler() {
        this.lastScrollY = window.scrollY;
        this.raf();
    }


    raf() {
        if( !this.ticking ) {
            requestAnimationFrame( this.run.bind(this) );
            this.ticking = true;
        }
    }


    run() {
        this.update();
        this.emit( 'app_scroll' );
        this.ticking = false;
    }


    update() {
        this.getCurrentController().update();

        this.Menu.update();
    }

    resize() {
        this.Popin.resize();
    }


    hideLoader( animation ) {
        if ( !this.isLoaderHidden ) {
            if ( !animation ) {
                animation = this.animation.loader;
            }

            // Callback the controller before hiding the loader
            if ( this.currentController.needsLoader() ) {
                this.currentController.beforeReveal();
            }
        }
    }


    checkLoader() {
        if ( !this.currentController.needsLoader() ) {
            css( this.$loader, { display: 'none' } );
        }
    }


    controllerReady() {
        if ( this.currentController.needsLoader() ) {
            var currentTime = new Date().getTime();
            var timeDifference = currentTime - this.loaderStartTime;
            var loaderTimeout = this.animation.loader.timeout - timeDifference;
            setTimeout( () =>  {
                this.hideLoader();
            }, ( loaderTimeout > 0 ) ? loaderTimeout : 0 );
        }
    }
}
