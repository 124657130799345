import AbstractPageController from './AbstractPageController';
import Logger from '../utils/Logger';
import { getTimeline, isElementInViewport } from "../utils/AnimationUtils";
import { TweenMax, TimelineLite, TimelineMax, Power0, Power2, Linear, Elastic, CSSPlugin } from "gsap/all";
import { createHTMLElement } from '../utils/HtmlUtils';
import {addEventListener, removeEventListener} from '../utils/EventUtils';
import {loopIndex} from '../utils/JsUtils';
import { debounce } from 'lodash';
import { addClass, css, removeClass } from '../utils/CssUtils';


import EventEmitter from 'eventemitter3';
import AppController from "./AppController";

export default class QuizController extends AbstractPageController {
    constructor( ...args ) {
        super( ...args );

        Logger.log( 'QuizController->constructor()' )

        this._dataQuiz = dataQuiz;
        // Shuffle questions in front to avoid cache
        if( this._dataQuiz.isExam == false && this._dataQuiz.hasOwnProperty( 'listQuestions' ) ) {
            for( let i = this._dataQuiz.listQuestions.length - 1; i > 0; i-- ) {
                const j = Math.floor( Math.random() * i );
                const temp = this._dataQuiz.listQuestions[i];
                this._dataQuiz.listQuestions[i] = this._dataQuiz.listQuestions[j];
                this._dataQuiz.listQuestions[j] = temp;
            }
        }

        this._device = ( this.app.breakpoints[ this.app.breakpoint ] < this.app.breakpoints['lg'] ) ? 'mobile' : 'desktop';


        this.initDomElements();
        this.addEventListeners();

        this.ready();

        this._QuizManager = new QuizManager({
            containerClass : 'js-quiz',
            durationTimer: this._dataQuiz.isExam ? 10 : 15,
            device: this._device,
            isExam : this._dataQuiz.isExam,
            listQuestions : this._dataQuiz.listQuestions,
            pll: this._dataQuiz.pll
        });
    }

    initDomElements() {}


    addEventListeners() {
        this.app.on( 'breakpoint-update', this._updateBreakpoint.bind(this) );
    }

    _updateBreakpoint( newBreakpoint, previousBreakpoint ) {

        let device;
        if( this.app.breakpoints[ newBreakpoint ] < this.app.breakpoints['lg'] ) {
            device = 'mobile';
        } else {
            device = 'desktop';
        }

        if( this._device != device ) {
            this._device = device;
            this._QuizManager.setDevice( device );
        }
    }

    resizeEventListener() {
    }

    /**
     * Update call to render animations
     */
    update() {
    }


    destroy() {
        Logger.log( 'QuizController->destroy()' );

        this.removeEventListeners();
    }


    removeEventListeners() {}
}


/*
    Class QuizManager
    Manage quiz
    Params : {
       {String} containerClass - the classname of the quiz container
       {Boolean} isExam - if the quiz is in exam mode
       {Number} durationTimer - to change the stopwatch time
       {String} device - 'mobile' or 'tablet' or 'desktop'
       {Object} listQuestions - List of all the questions (with differents answers)
       {Object} pll - Different translations of sentences / titles
    }
*/
class QuizManager extends EventEmitter {

    constructor( { containerClass = null, isExam = false, durationTimer = null, device = null, listQuestions = null, pll = null } = {} ) {
        super();

        this.referringClassName = {};
        this.referringClassName.quiz = 'js-quiz';
        this.referringClassName.button = this.referringClassName.quiz + '-button';

        // Define properties
        this._styleConsole = 'background: #222; color: #ff8c8c';
        this._userAnswers = [];
        this._listQuestions = false;
        this._current = 0;
        this._classes = {
            container: null,
            buttonValidate: this.referringClassName.button + '-validate',
            buttonNext: this.referringClassName.button + '-next',
            hide: 'hide',
            show: 'show',
            disabled: 'disabled'
        }
        this._userAnswerValidate = false;
        this._isTimed = true;
        this._timeIsRunning = false;
        ///////////////////////////////////////////////////////////////

        this._durationTimer = ( durationTimer != null ) ? durationTimer : false;
        this._classes.container = containerClass;
        this._isExam = isExam;
        this._device = device;
        this._listQuestions = listQuestions;
        this._pll = pll;

        if( this._checkRequiredParams() == false )
            return;

        if( this._initDomElements() == false )
            return;

        this._validInitClass = this._initClass();
        if( this._validInitClass )
            this._addEventListeners();
    }

    /**
     * Check if the mandatory parameters to create the quiz are well informed
     * @return {Boolean}
     */
    _checkRequiredParams() {

        if( this._classes.container == false || this._classes.container == null ) {
            console.warn('%c The container class of the quiz is not defined ', this._styleConsole);
            return false;
        }

        if( this._isExam == null || typeof this._isExam != 'boolean' ) {
            console.warn('%c No indication if the quiz is an exam ', this._styleConsole);
            return false;
        }

        if( this._listQuestions == null || typeof this._listQuestions != 'object' ) {
            console.warn('%c No question for the quiz ', this._styleConsole);
            return false;
        }

        if( this._pll == null || typeof this._pll != 'object' ) {
            console.warn('%c No translation for the quiz ', this._styleConsole);
            return false;
        }
    }

    _initDomElements() {

        this.$container = document.body.querySelector( '.' + this._classes.container );

        // Make sure the quiz container exists before continuing
        if( this.$container == null) {
            console.warn('%c The container of the quiz is not defined ', this._styleConsole);
            return false;
        }

        // Retrieve button
        this.$button = {
            validate : this.$container.querySelector( '.' + this._classes.buttonValidate ),
        }
        if( this._isExam == false ) {
            this.$button.next = this.$container.querySelector( '.' + this._classes.buttonNext );
        }
    }

    _initClass() {

        // Initialization Introduction
        this._IntroductionManager = new IntroductionManager({
            containerQuiz : this.$container,
            device : this._device
        });

        // Initialization of the timer
        this._TimerManager = new TimerManager({
            durationTimer : this._durationTimer,
            device : this._device
        });

        // Initialization of the questions if the json containing the questions exists
        if( this._listQuestions ) {

            this._totalQuestions = this._listQuestions.length

            // Each question instantiates a Question Object
            this._QuestionManager = [];
            for( let i = 0, j = this._totalQuestions; i < j; i++ ) {

                let questionManager = new QuestionManager({
                    containerQuiz : this.$container,
                    data : this._listQuestions[i],
                    isExam : this._isExam,
                    number : i + 1,
                    device : this._device
                });

                if( questionManager._isValidRequiredParams )
                    this._QuestionManager.push( questionManager );
                else {
                    return false;
                }
            }
        }

        // Initialization of result
        this._ResultManager = new ResultManager({
            containerQuiz : this.$container,
            device : this._device,
            pll : this._pll
        });


        return true;
    }

    _addEventListeners() {

        addEventListener( this.$button.validate, 'click', this._validQuestion.bind(this) );

        if( this._isExam == false)
            addEventListener( this.$button.next, 'click', this._hideQuestion.bind(this) );

        this._IntroductionManager.on( MANAGE_INTRODUCTION_QUIZ_EVENT.CLICK_START, this._startQuiz.bind(this) );
        this._IntroductionManager.on( MANAGE_INTRODUCTION_QUIZ_EVENT.HIDDEN, this._showQuestionComponent.bind(this) );

        for( let i = 0, j = this._listQuestions.length; i < j; i++ ) {
            this._QuestionManager[i].on( MANAGE_QUESTION_EVENT.COMPONENT_SHOWN, this._showQuestion.bind(this) );
            this._QuestionManager[i].on( MANAGE_QUESTION_EVENT.SHOWN, this._startQuestion.bind(this) );
            this._QuestionManager[i].on( MANAGE_QUESTION_EVENT.CLICK_ANSWER, this._toggleButtonValidate.bind(this,false) );
            this._QuestionManager[i].on( MANAGE_QUESTION_EVENT.HIDDEN, this._getNextStepQuiz.bind(this) );
            this._QuestionManager[i].on( MANAGE_QUESTION_EVENT.COMPONENT_HIDDEN, this._hideComponentQuestion.bind(this) );
        }
    }

    /*
    * Called when the user starts the quiz ("go" button) : hide introduction and wait for him to hide
    * @param {Event} e - event
    */
    _startQuiz( isTimed ) {
        this._isTimed = isTimed;
        this._initTimer();
        this._IntroductionManager._hideComponent();
    }

    /*
   * Init the timer
   */
    _initTimer() {

        // Initialization of the timer if the quiz is in timed mode
        if( this._isTimed ) {
            this._TimerManager.show();
            this._TimerManager.on( MANAGE_TIMER_EVENT.END, this._timeOver.bind(this) );
        }
    }

    /*
    * Called when the introduction is hidden: display of the question block and wait for him to show
    */
    _showQuestionComponent(){
        this._QuestionManager[ this._current ].showComponent();
    }

    /*
    * Called to show the new question
    */
    _showQuestion() {
        this._QuestionManager[ this._current ].show();
    }

    /*
    * Called when the question is displayed
    */
    _startQuestion() {
        this._startTimer();
    }

    /*
   * Start the timer if it exists
   */
    _startTimer() {
        if( this._isTimed ) {
            this._TimerManager.start();
            this._timeIsRunning = true;
        }
    }

    /*
   * Called to change the "disabled" state of the "validate" button
   * @param {Boolean} isDisabled
   */
    _toggleButtonValidate( isDisabled ) {
        if( isDisabled )
            this.$button.validate.classList.add( this._classes.disabled );
        else
            this.$button.validate.classList.remove( this._classes.disabled );
    }

    /*
    * Called when the user confirms his answer (button "validate")
    * @param {Event} e - event
    */
    _validQuestion( e ) {

        // If the user has already clicked on the "validate" button
        if(
            ( this._isTimed && this._timeIsRunning == false ) ||
            ( this._userAnswerValidate )
        )
            return;

        // Check if the user has clicked on a response
        if( this._QuestionManager[ this._current ].getAnsweredQuestion() ) {

            // Indication that the user has clicked on the "validate" button
            this._userAnswerValidate = true;

            // Indication that the user has clicked on the "validate" button
            this._QuestionManager[ this._current ].setAnswerValidated( true );

            // Check if the user has answered correctly or not
            let isRightAnswer = this._QuestionManager[ this._current ].checkAnswer();
            this._registerUserAnswer( isRightAnswer )

            // If stopwatch: stop it
            if( this._isTimed ) {
                this._TimerManager.stop();
            }

            this._getNextStepQuestion();
        }
    }

    /*
    * Called to record the user's response with different data related to the question
    * @param {Boolean} isRightAnswer - if the user has answered correctly or not to the question
    */
    _registerUserAnswer( isRightAnswer = null ) {

        /*
        Storage :
            isRightAnswer : if the user has answered correctly or not,
            rightAnswer : position in the array of the correct answer,
            rightAnswerLetter : the letter (a, b, c, d) corresponding to the correct answer
            userAnswer : position in the array of the response given by the user (or "false" if the user did not respond)
            explanation : the text explanation of the answer
            number : number of the quesiton
            answers : list of answers to the question
            isEliminatory : if the question is eliminatory
            title : question title
            image : question image
            theme : theme of the question
         */
        this._userAnswers.push({
            isRightAnswer : isRightAnswer,
            rightAnswer : this._QuestionManager[ this._current ].getPositionRightAnswer(),
            rightAnswerLetter : this._QuestionManager[ this._current ].getLetterRightAnswer(),
            userAnswer : ( isRightAnswer != null ) ? this._QuestionManager[ this._current ].getPositionUserAnswer() : null,
            explanation : this._QuestionManager[ this._current ].getExplanation(),
            number : this._QuestionManager[ this._current ].getNumber(),
            answers : this._QuestionManager[ this._current ].getAnswers(),
            isEliminatory : this._QuestionManager[ this._current ].getIsEliminatory(),
            title: this._QuestionManager[ this._current ].getTitle(),
            image: this._QuestionManager[ this._current ].getImage(),
            theme: this._QuestionManager[ this._current ].getTheme()
        });
    }

    /*
     * Called at the end of each question : two possible choices: either we hide the question || either display the explanation
     */
    _getNextStepQuestion(){

        // Reset events on answers
        this._QuestionManager[ this._current ].removeEventAnswers();

        // If the quiz is in exam mode
        if( this._isExam ) {
            // We go directly to the next question
            this._hideQuestion();
        } else {
            // We show the explanation of the question
            this._showExplanation();
        }
    }

    //display of the quiz and the first question

    /*
    * Called to hide the current question
    * @param {Event} e - event
    */
    _hideQuestion( e = null ) {

        // If the user has not first clicked on the "validated" button and time is running : we don't go to the next question
        if( ( this._timeIsRunning && !this._userAnswerValidate ) || ( this._isTimed == false && !this._userAnswerValidate ) )
            return;

        // Reset data
        this._resetData();

        // Hiding the current question
        this._QuestionManager[ this._current ].hide();
    }

    /*
    * Called to re-initialize the different variables / states
    */
    _resetData() {

        // If the quiz is not in exam mode
        if( this._isExam == false ) {
            // Hiding the "next question" button
            this.$button.next.classList.remove( this._classes.show );
        }

        // The "validate" button is disabled
        this._toggleButtonValidate(true);

        // Reset properties
        this._userAnswerValidate = false;
        this._timeIsRunning = false;
    }

    /*
    * Show the explanation of the question & manage the display of the quiz buttons
    */
    _showExplanation() {

        // Display explanation of response
        this._QuestionManager[ this._current ].showExplanation();

        // If last question => change the label of the next button to "end"
        if( this._current == this._totalQuestions - 1 ) {
            this.$button.next.childNodes[1].innerHTML = this.$button.next.dataset.labelEnd;
        }

        // The "validate" button is disabled
        this._toggleButtonValidate(true);

        // Display of the "next question"
        this.$button.next.classList.add( this._classes.show );
    }

    /*
    * Called as soon as the previous question is hidden
    */
    _getNextStepQuiz() {

        // Get the index of the next question
        let next = this._current + 1;

        // Verification that the question exists
        if( next < this._totalQuestions ) {
            this._current = next;
            this._showQuestion();
        } else {
            this._showResults();
        }
    }

    /*
    * Called when all questions are finished
    */
    _showResults() {
        this._ResultManager.init( this._userAnswers );
        this._QuestionManager[ this._current ].hideComponent();
    }

    /*
    * Called when componant Question is hidden
    */
    _hideComponentQuestion() {
        this._ResultManager.showComponent();
    }

    /*
    * Called that the time for the question has elapsed
    */
    _timeOver() {

        this._timeIsRunning = false;

        // Verification that the user has not answered the question
        if( this._userAnswerValidate == false ) {
            this._registerUserAnswer();
            this._getNextStepQuestion();
        }
    }

    /*
   * Change the value of the device
   * @param {String} device - 'desktop' | 'mobile'
   */
    setDevice( device ) {

        if( this._device != device ) {
            this._device = device;

            this._IntroductionManager.setDevice( this._device );
            this._ResultManager.setDevice( this._device );

            if( this._TimerManager )
                this._TimerManager.setDevice( this._device );

            for( let i = 0, j = this._QuestionManager.length; i < j; i++ )
                this._QuestionManager[i].setDevice( this._device );
        }
    }
}

/*
    Class IntroductionManager
    Manage introduction
    Params : {
        {DOM} containerQuiz - The quiz container
        {String} device - 'mobile' or 'desktop'
    }
*/
class IntroductionManager extends EventEmitter {

    constructor( { containerQuiz = null, device = null } = {} ) {
        super();

        this.referringClassName = {};
        this.referringClassName.nameComponent = 'c-quiz-introduction';
        this.referringClassName.introduction = 'js-' + this.referringClassName.nameComponent;

        // Define properties
        this._styleConsole = 'background: #222; color: #ff8c8c';
        this._classes = {
            container : this.referringClassName.introduction,
            buttonStart: this.referringClassName.introduction + '-button-start',
            buttonNoTimed: 'is-not-timed',
            animY : this.referringClassName.introduction +'-anim-y',
            hide: 'hide',
            show: 'show'
        };
        ///////////////////////////////////////////////////////////////
        this._containerQuiz = containerQuiz;
        this._device = device;

        this._initDomElements()
        this._initClass();
        this._addEventListeners();
    }

    _initDomElements() {
        this.$container = this._containerQuiz.querySelector( '.' + this._classes.container );
        this.$buttonsStart = this.$container.querySelectorAll( '.' + this._classes.buttonStart );
        this.$eltAnimY = this.$container.querySelectorAll( '.' + this._classes.animY );
    }

    _initClass() {
        this._initAnimation();
    }

    /**
     * Init all animations of introduction
     */
    _initAnimation() {

        // Init anim "Hide Introduction"
        this._tlHide = new TimelineMax({
            paused: true,
            onComplete: () => {
                this.$container.classList.add( this._classes.hide );
                this.emit( MANAGE_INTRODUCTION_QUIZ_EVENT.HIDDEN );
            }
        });

        this._tlHide
            .to( this.$container, 0.8, { y : -140, ease : Power2.easeOut } )
            // .staggerTo( this.$eltAnimY, 0.8, { y : -20, ease : Power2.easeIn }, 0.1, '-=0.5' )
            .to( this.$container, 0.25, { autoAlpha : 0, ease : Power2.easeOut }, '-=0.6' );
    }

    _addEventListeners() {

        if( this.$buttonsStart ) {
            for( let i = 0, j = this.$buttonsStart.length; i < j; i++ ){
                addEventListener( this.$buttonsStart[i], 'click', this._clickStart.bind(this) );
            }
        }
    }

    /**
     * Called when the user clicks on one of the "start" buttons in the intro
     */
    _clickStart( event ) {
        // Check if the button contains "do not time"
        let target = event.currentTarget;
        let isTimed = (!target.classList.contains( this._classes.buttonNoTimed ));

        this.emit( MANAGE_INTRODUCTION_QUIZ_EVENT.CLICK_START, isTimed );
    }

    /**
     * Hide the introduction component
     */
    _hideComponent() {
        this._tlHide.play(0);
    }

    /*
   * Change the value of the device
   */
    setDevice( device ) {
        this._device = device;
    }
}


/*
    Class TimerManager
    Manage timer
    Params : {
        {Number} durationTimer - duration of the quiz
        {String} device - 'mobile' or 'desktop'
    }
*/
class TimerManager extends EventEmitter {

    constructor( { durationTimer = false, device = null } ) {
        super();

        this.referringClassName = {};
        this.referringClassName.timer = 'js-quiz-timer';

        // Define properties
        this._classes = {
            container : this.referringClassName.timer + '-container',
            toShow: this.referringClassName.timer + '-show',
            bar : this.referringClassName.timer + '-progress-bar',
            number : this.referringClassName.timer + '-number',
            active: 'active',
            show: 'show'
        };
        this._isPlaying = false;
        this._timeIsStopped = false;
        ///////////////////////////////////////////////////////////////
        this._duration = ( durationTimer != false ) ? durationTimer : 10;
        this._device = device;

        this._initDomElements();
        this._initClass();
    }

    _initDomElements() {
        this.$container = document.body.querySelectorAll( '.' + this._classes.container );

        this.$bars = [];
        this.$numbers = [];
        this.$eltToShow = [];
        this._deviceList = [];

        for( let i = 0, j = this.$container.length; i < j; i++ ) {
            let container = this.$container[i];
            let device = container.dataset.device;

            if( typeof device != 'undefined' ) {
                this._deviceList.push( device );
                this.$bars.push( container.querySelector( '.' + this._classes.bar ) );
                this.$numbers.push( container.querySelector( '.' + this._classes.number ) );
                let eltToShow = [].slice.call( container.querySelectorAll( '.' + this._classes.toShow ) );
                this.$eltToShow = this.$eltToShow.concat( eltToShow );
            }
        }
    }

    _initClass() {
        this._initAnimation();
    }

    /**
     * Creation of timer animation
     */
    _initAnimation() {

        // this._tlTimer = {};
        //
        // for( let i = 0, j = this._deviceList.length; i < j; i++ ) {
        //
        //     let device = this._deviceList[i];
        //
        //     let animTimer = new TimelineMax({
        //         paused : true,
        //         onComplete : () => {
        //             this._end( device )
        //         },
        //         onStart: () => {
        //             this.$number[ device ].innerHTML = this._duration;
        //             this._time = 0;
        //         },
        //         onUpdate : () => {
        //             let second = this._duration - ( Math.floor( this._time / 60 ) );
        //             this._time ++;
        //             this.$number[ device ].innerHTML = second;
        //         }
        //     });
        //
        //     animTimer.fromTo( this.$bar[device], this._duration, { scaleX : 1 }, { scaleX : 0, ease : Power0.easeNone } );
        //
        //     this._tlTimer[ device ] = animTimer;
        // }

        this._tlTimer = new TimelineMax({
            paused : true,
            onComplete : () => {
                this._end()
            },
            // onStart: () => {
            //     this._time = 0;
            //     this._setTimeNumber( this._duration );
            // },
            // onUpdate : () => {
            //     let second = this._duration - ( Math.floor( this._time / 60 ) );
            //     this._time ++;
            //     this._setTimeNumber( second );
            // }
        });

        this._tlTimer.fromTo( this.$bars, this._duration, { scaleX : 1 }, { scaleX : 0, ease : Power0.easeNone } );
    }

    /**
     * Start the timer animation
     */
    start() {
        this._setTimeNumber( this._duration );
        this._time = 0;
        this._intervalTimer = setInterval( this._updateTimer.bind(this), 1000 );
        this._tlTimer.play(0);
        this.toggleAnimNumber( true );
    }

    /**
     * Stop the timer animation
     */
    stop() {
        this._timeIsStopped = true;
        this._tlTimer.progress(1).kill();
    }

    /**
     * Called every second: changes the number of all the timers
     */
    _updateTimer() {
        this._time ++;
        this._setTimeNumber( this._duration - this._time );
    }

    /**
     * Called when the timer animation is finished
     */
    _end() {
        if( this._timeIsStopped == false ) {
            this.emit( MANAGE_TIMER_EVENT.END );
        }
        this._resetData();
    }

    /**
     * Reset different properties
     */
    _resetData() {
        clearInterval( this._intervalTimer );
        this._setTimeNumber( 0 );
        this.toggleAnimNumber( false );
        this._timeIsStopped = false;
    }


    /**
     * Animation number
     * @param {Boolean} isActive - play animation or not
     */
    toggleAnimNumber( isActive ) {

        if( isActive ) {
            for( let i = 0; i < this.$numbers.length; i++ )
                this.$numbers[i].classList.add( this._classes.active );
        } else {
            for( let i = 0; i < this.$numbers.length; i++ )
                this.$numbers[i].classList.remove( this._classes.active );
        }

    }

    /**
     * Show the different timers
     */
    show() {
        for( let i = 0, j = this.$eltToShow.length; i < j; i++ ) {
            this.$eltToShow[i].classList.add( this._classes.show );
        }
    }

    /**
     * Update all the digits indicating the remaining time
     * @param {Number} number - the remaining time
     */
    _setTimeNumber( number ) {
        for( let i = 0; i < this.$numbers.length; i++ )
            this.$numbers[i].innerHTML = number;
    }

    /**
     * Change the value of the device
     */
    setDevice( device ) {
        this._device = device;
    }
}

/*
    Class QuestionManager
    Manage question
    Params : {
       {DOM} containerQuiz - The quiz container
       {Object} data - The data of the question (title, answers ...)
       {Boolean} isExam - if the quiz is in exam mode
       {Number} number - The position of the question in relation to all of the questions
       {String} device - 'mobile' or 'desktop'
    }
*/
class QuestionManager extends EventEmitter {

    constructor( { containerQuiz = null, data = null, isExam = false, number = null, device = null } = {} ) {
        super();

        this.referringClassName = {};
        this.referringClassName.nameComponent = 'c-quiz-question';
        this.referringClassName.question = 'js-' + this.referringClassName.nameComponent;
        this.referringClassName.explanation = this.referringClassName.question + '-explanation';

        // Define properties
        this._styleConsole = 'background: #222; color: #ff8c8c';
        this._classes = {
            container : this.referringClassName.question,
            eliminatory : this.referringClassName.question + '-eliminatory',
            titleNumber : this.referringClassName.question + '-title-number',
            number : this.referringClassName.question + '-number',
            question : this.referringClassName.question + '-title',
            image : this.referringClassName.question + '-image',
            answersContainer : this.referringClassName.question + '-answers',
            answerButton : this.referringClassName.nameComponent + '__content__answer-item',
            answerButtonJS: this.referringClassName.question + '-answer-item',
            explanationContainer: this.referringClassName.explanation,
            explanationText: this.referringClassName.explanation + '-text',
            explanationAnswer: this.referringClassName.explanation + '-right-answer',
            explanationLetter: this.referringClassName.explanation + '-letter' ,
            hide: 'hide',
            show: 'show',
            selected: 'selected',
            right: 'right',
            wrong: 'wrong',
            portrait: 'portrait',
            landscape: 'landscape',
            animRight : this.referringClassName.question + '-anim-right',
            animLeft : this.referringClassName.question + '-anim-left',
            animX : this.referringClassName.question + '-anim-x',
        };
        this._answeredQuestion = false;
        this._posUserAnswer = null;
        this._firstClickAnswer = true;
        this._letters = ['A', 'B', 'C', 'D'];
        this._answerValidated = false;
        this._isValidRequiredParams = true;
        ///////////////////////////////////////////////////////////////

        this._containerQuiz = containerQuiz;
        this._data = data;
        this._isExam = isExam;
        this._number = number;
        this._device = device;

        if( this._checkRequiredParams() == false ) {
            this._isValidRequiredParams = false
            return;
        }

        this._initDomElements();
        this._initClass();
        this._addEventListeners();
    }

    /**
     * Check if the mandatory parameters to create a question are well informed
     * @return {Boolean}
     */
    _checkRequiredParams() {

        if( this._data == false || this._data == null ) {
            console.warn('%c There is no information on the matter ', this._styleConsole);
            return false;
        }

        if( this._data.hasOwnProperty('question') == false || this._data.question == false ) {
            console.warn('%c There is no question title ', this._styleConsole);
            return false;
        }

        if( this._data.hasOwnProperty('explanation') == false || this._data.explanation == false ) {
            console.warn('%c There is no explanation for the question ( ' + this._data.question + ' ) ', this._styleConsole);
            return false;
        }

        if( this._data.hasOwnProperty('is_eliminatory') == false || typeof this._data.is_eliminatory != 'boolean' ) {
            console.warn('%c No indication if this question is eliminatory ( ' + this._data.question + ' ) ', this._styleConsole);
            return false;
        }

        if( this._data.hasOwnProperty('theme') == false || this._data.question == false ) {
            console.warn('%c There is no theme for the question (' + this._data.question + ' ) ', this._styleConsole);
            return false;
        }


        if( this._data.hasOwnProperty('answers') == false || this._data.answers == false || Array.isArray( this._data.answers ) == false && this._data.answers.length == 0 ) {
            console.warn('%c There are no answers to this question ( ' + this._data.question + ' ) ', this._styleConsole);
            return false;
        }

        for( let i = 0; i < this._data.answers.length; i ++ ) {
            if( this._data.answers[i].hasOwnProperty('label') == false || this._data.answers[i].label == false ) {
                console.warn('%c There is no title to the answer ( ' + this._data.question + ' ) ', this._styleConsole);
                return false;
            }

            if( this._data.answers[i].hasOwnProperty('is_right') == false || typeof this._data.answers[i].is_right != 'boolean' ) {
                console.warn('%c No indication whether the answer is correct or not ( ' + this._data.question + ' : ' + this._data.answers[i]['label'] + ' ) ', this._styleConsole);
                return false;
            }
        }
    }

    _initDomElements() {

        this.$eliminatory = document.body.querySelectorAll( '.' + this._classes.eliminatory );
        this.$titleNumber = document.body.querySelectorAll( '.' + this._classes.titleNumber );
        this.$number = document.body.querySelectorAll( '.' + this._classes.number );
        this.$container = this._containerQuiz.querySelector( '.' + this._classes.container );
        this.$question = this.$container.querySelector( '.' + this._classes.question );
        this.$answersContainer = this.$container.querySelector( '.' + this._classes.answersContainer );
        this.$image = this.$container.querySelector( '.' + this._classes.image );
        this.$answers = null;

        // Retrieve all elements of explanation if the quiz is in exam mode
        if( this._isExam == false ) {
            this.$explanation = {
                container : this.$container.querySelector( '.' + this._classes.explanationContainer )
            }
            this.$explanation.text = this.$explanation.container.querySelector( '.' + this._classes.explanationText );
            this.$explanation.letter = this.$explanation.container.querySelector( '.' + this._classes.explanationLetter );
            this.$explanation.answer = this.$explanation.container.querySelector( '.' + this._classes.explanationAnswer );
        }

        // Animation
        this.$animLeft = this.$container.querySelector( '.' + this._classes.animLeft );
        this.$animRight = this.$container.querySelector( '.' + this._classes.animRight );
        this.$eltAnimX = this.$container.querySelectorAll( '.' + this._classes.animX );
    }

    _initClass() {

        this._answers = [];
        for( let i = 0, j = this._data.answers.length; i < j; i ++ ) {
            // Determine the position of the array containing the correct answer
            if( this._data.answers[i].is_right == true ) {
                this._posRightAnswer = i;
            }

            // Array that contains all the answer label
            this._answers.push( this._data.answers[i].label );
        }

        // Storage of question data in different attributes
        this._question = this._data.question;
        this._explanation = this._data.explanation;
        this._isEliminatory = this._data.is_eliminatory;
        this._image = ( this._data.image ) ? this._data.image : false;
        this._theme = this._data.theme;

        this._initAnimation();
    }

    _addEventListeners() {}

    /**
     * Init all animations of a question
     */
    _initAnimation() {

        // Init anim "Show Component"
        this._tlShowComponent = new TimelineMax({
            paused: true,
            onStart: () => {
                TweenMax.to( window, 0, { scrollTo: { y: 0 } } );
                this.$container.classList.add( this._classes.show );
            },
            onComplete: () => {
                this.emit( MANAGE_QUESTION_EVENT.COMPONENT_SHOWN );
            }
        });

        this._tlShowComponent
            .fromTo( this.$container, 0.01, { autoAlpha : 0 }, { autoAlpha : 1, ease : Power0.easeNone } );

        // Init anim "Hide Component"
        this._tlHideComponent = new TimelineMax({
            paused: true,
            onComplete: () => {
                TweenMax.to( window, 0, { scrollTo: { y: 0 } } );
                this.$container.classList.remove( this._classes.show );
                this.emit( MANAGE_QUESTION_EVENT.COMPONENT_HIDDEN );
            }
        });

        this._tlHideComponent
            .fromTo( this.$container, 0.01, { autoAlpha : 1 }, { autoAlpha : 0, immediateRender:false, ease : Power0.easeNone }, 'start' )
            .to( this.$titleNumber, 0.01, { autoAlpha : 0, ease : Power2.easeOut }, 'start' )


        // Init anim "Show Question"
        this._tlShow = new TimelineMax({
            paused: true,
            onComplete: () => {
                this.emit( MANAGE_QUESTION_EVENT.SHOWN );
            }
        });

        this._tlShow
            .to( this.$titleNumber, 0.7, { autoAlpha : 1, ease : Power2.easeOut }, 'start' )
            .fromTo( this.$animLeft, 0.7, { autoAlpha : 0, x : -80 }, { autoAlpha : 1, x : 0, ease : Power2.easeOut }, 'start' )
            .fromTo( this.$animRight, 0.7, { x : 80}, { x : 0, ease : Power2.easeOut }, 'start+=0.15' )
            .fromTo( this.$animRight, 0.55, { autoAlpha : 0 }, { autoAlpha : 1, ease : Power2.easeOut }, 'start+=0.25' )
            .staggerFromTo( this.$eltAnimX, 0.6, { x : -80 }, { x : 0, ease : Power2.easeInOut }, 0.05, 'start' )
            .staggerFromTo( this.$eltAnimX, 0.5, { autoAlpha : 0 }, { autoAlpha : 1, ease : Power2.easeInOut }, 0.05, 'start+=0.15' )

        // Init anim "Hide Question"
        this._tlHide = new TimelineMax({
            paused: true,
            onComplete: () => {
                if( this._isExam == false ) {
                    this._tlExplanation.progress(0).kill();
                }
                this._resetData();
                this.emit( MANAGE_QUESTION_EVENT.HIDDEN );
            }
        });

        this._tlHide
            .fromTo( this.$animLeft, 0.5, { autoAlpha : 1, x : 0 }, { autoAlpha : 0, x : -80,immediateRender:false, ease : Power2.easeIn }, 'start' )
            .fromTo( this.$animRight, 0.5, { autoAlpha : 1, x : 0 }, { autoAlpha : 0, x : 80, immediateRender:false, ease : Power2.easeIn }, 'start+=0.15' )
            .to( this.$eltAnimX, 0.01, { x : -80, autoAlpha : 0, immediateRender:false, ease : Power2.easeInOut } )


        // Init anim "Show Explanation"
        if( this._isExam == false ) {
            this._tlExplanation = new TimelineMax({
                paused: true,
            });

            this._tlExplanation
                .fromTo( this.$explanation.container, 0.7, { y : 80 }, { y : 0, ease : Power2.easeOut }, 'start' )
                .fromTo( this.$explanation.container, 0.55, { autoAlpha : 0 }, { autoAlpha : 1, ease : Power2.easeOut }, 'start+=0.15' )
        }
    }

    showComponent() {
        this._tlShowComponent.play(0);
    }

    hideComponent() {
        this._tlHideComponent.play(0);
    }

    /**
     * Called when showing the question
     */
    show() {
        this._createDOM();
        this._bindAnswers();
        this._animShow();
    }

    /**
     * Insertion of question data in different places in the DOM
     */
    _createDOM() {

        // Question title
        this.$question.innerHTML = this._question;

        // Question number
        for( let i = 0, j = this.$number.length; i < j; i++ ) {
            this.$number[i].innerHTML = this._number;
        }

        // If image exists :
        if( this._image ) {
            this.$image.setAttribute('src', this._image['url']);
            this.$image.setAttribute('alt', this._image['alt']);
            if( this._image['width'] >= this._image['height'] )
                this.$image.classList.add( this._classes.landscape );
            else
                this.$image.classList.add( this._classes.portrait );
            this.$image.classList.remove( this._classes.hide );
        }

        // Display or not of the block "eliminatory question"
        for( let i = 0, j = this.$eliminatory.length; i < j; i++ ) {
            let element = this.$eliminatory[i];
            if( this._isEliminatory ) {
                element.classList.add( this._classes.show );
            }else {
                element.classList.remove( this._classes.show );
            }
        }

        /// Creation of DOM elements for each answer
        const fragment = document.createDocumentFragment();

        for( let i = 0, j = this._answers.length; i < j; i++ ) {

            // Create answer button
            let answerButton = createHTMLElement('button',  {
                classname: this._classes.answerButton + ' ' + this._classes.answerButtonJS,
                text: this._letters[i] + '. ' + this._answers[i],
            });
            fragment.appendChild( answerButton );
        }

        // Insertion of answer elements in the DOM
        this.$answersContainer.appendChild(fragment);
    }

    /*
    * Called after responses have been inserted into the DOM,
    * We put event on the click of answers
    */
    _bindAnswers() {

        this.$answers = [].slice.call( this.$answersContainer.querySelectorAll( '.' + this._classes.answerButtonJS ) );
        this._answersListeners = [];

        for( let i = 0, j = this.$answers.length; i < j; i++ ) {
            this._answersListeners[i] = this._clickAnswer.bind(this);
            addEventListener( this.$answers[i], 'click', this._answersListeners[i] );
        }
    }

    /*
    * Called when the user clicks on a answer
    * @param {Event} e - event
    */
    _clickAnswer( event ) {

        let target = event.currentTarget;
        let indexTarget = this.$answers.indexOf( target );

        for( let i = 0, j = this.$answers.length; i < j; i++ ) {
            let answer = this.$answers[i];

            if( indexTarget == i ) {
                // Save response user (position)
                this._posUserAnswer = i;
                answer.classList.add( this._classes.selected );
            } else {
                answer.classList.remove( this._classes.selected );
            }
        }

        // Detection if the user has clicked on a answer for the first time
        if( this._firstClickAnswer ) {
            this._firstClickAnswer = false;
            this._answeredQuestion = true;
            this.emit( MANAGE_QUESTION_EVENT.CLICK_ANSWER );
        }
    }

    /*
    * Animates the display of the question
    */
    _animShow() {
        this._tlShow.play(0);
    }

    /*
     * Hide the question (when the question is hidden: re-initialize the DOM containing the question data
    */
    hide() {
        this._tlHide.play(0);
    }

    /*
    * Displays the explanation of the answer to the question
     */
    showExplanation() {
        this._showAnswer();
        this._showTextExplanation();
    }

    /*
    * Displays the different contents of the explanation
   */
    _showTextExplanation() {

        // Letter corresponding to the answer
        this.$explanation.letter.innerHTML = this.getLetterRightAnswer();

        // Displays the text corresponding to the correct answer
        this.$explanation.answer.innerHTML = this._answers[this._posRightAnswer];

        // Show explanation
        this.$explanation.text.innerHTML = this._explanation;

        // Display indication of correct or incorrect answer (check if the user has validated his answer)
        if( this._answerValidated && this.checkAnswer() ) {
            this.$explanation.container.classList.add( this._classes.right );
        } else {
            this.$explanation.container.classList.remove( this._classes.right );
        }

        // Display of the DOM element containing all these elements
        this.$explanation.container.classList.add( this._classes.show );

        this._tlExplanation.play(0);
        //HERE Animation pour montrer l'explication (!!! que si examen !!! )
    }

    /*
    * Displays the correct answer (and the wrong one if the user made a mistake)
    */
    _showAnswer() {

        // We color in red the wrong answer given by the user (check if the user has validated his answer)
        if( this.checkAnswer() == false && this._answeredQuestion && this._answerValidated ) {
            this.$answers[this._posUserAnswer].classList.add( this._classes.wrong );
        }

        // The correct answer is colored green
        this.$answers[this._posRightAnswer].classList.add( this._classes.right );

        // Removal of the "selected" class for all answers
        for( let i = 0, j = this.$answers.length; i < j; i++ ) {
            this.$answers[i].classList.remove( this._classes.selected );
        }
    }

    /*
    * Remove all events on answers
    */
    removeEventAnswers() {
        for( let i = 0, j = this.$answers.length; i < j; i++ ) {
            removeEventListener( this.$answers[i], 'click', this._answersListeners[i] );
        }
    }

    /*
    * Reset different properties
   */
    _resetData() {

        // Question title
        this.$question.innerHTML = '';

        // If image exists :
        if( this._image ) {
            this.$image.setAttribute('src', '');
            this.$image.setAttribute('alt', '');
            this.$image.classList.remove( this._classes.portrait );
            this.$image.classList.remove( this._classes.landscape );
            this.$image.classList.add( this._classes.hide );
        }

        // Hide the block "eliminatory question"
        for( let i = 0, j = this.$eliminatory.length; i < j; i++ ) {
            let element = this.$eliminatory[i];
            element.classList.remove( this._classes.show );
        }

        // Delete answers if they exist
        let oldAnswers = this.$answersContainer.childNodes;
        if( oldAnswers.length > 0 ) {
            while( this.$answersContainer.firstChild ) {
                this.$answersContainer.removeChild(this.$answersContainer.firstChild);
            }
        }

        if( this._isExam == false ) {
            // Letter corresponding to the answer
            this.$explanation.letter.innerHTML = '';

            // Displays the text corresponding to the correct answer
            this.$explanation.answer.innerHTML = '';

            // Show explanation
            this.$explanation.text.innerHTML = '';

            // Remove indication of correct or incorrect answer
            this.$explanation.container.classList.remove( this._classes.right );

            // Hide DOM element containing all these elements
            this.$explanation.container.classList.remove( this._classes.show );
        }
    }

    /*
    * Returns the text explaining the answer to the question
    * @return {String}
    */
    getExplanation() {
        return this._explanation;
    }

    /*
    * Returns the question number
    * @return {Number}
    */
    getNumber() {
        return this._number;
    }

    /*
    * Returns the question title
    * @return {String}
    */
    getTitle() {
        return this._question;
    }

    /*
    * Returns the question image
    * @return {String}
    */
    getImage() {
        return this._image;
    }

    /*
    * Returns if question is eliminatory
    * @return {Boolean}
    */
    getIsEliminatory() {
        return this._isEliminatory;
    }

    /*
    * Returns the theme of the question
    * @return {String}
    */
    getTheme() {
        return this._theme;
    }

    /*
    * Returns the position of the array containing the correct answer
    * @return {Number}
    */
    getPositionRightAnswer() {
        return this._posRightAnswer;
    }

    /*
    * Returns the position of the array containing the response given by the user
    * @return {Number}
    */
    getPositionUserAnswer() {
        return this._posUserAnswer;
    }

    /*
    * Returns if the user has clicked on one of the responses
    * @return {Boolean}
    */
    getAnsweredQuestion() {
        return this._answeredQuestion;
    }

    /*
    * Returns the letter (a, b, c, d) corresponding to the correct answer
    * @return {String}
    */
    getLetterRightAnswer() {
        return this._letters[this._posRightAnswer];
    }

    /*
    * Returns all possible answers to the question
    * @return {Array}
    */
    getAnswers() {
        return this._answers;
    }

    /*
    * Returns if the user has answered the question correctly
    * @return {Boolean}
    */
    checkAnswer() {
        return ( this._posUserAnswer == this._posRightAnswer ) ? true : false;
    }

    /*
    * Change the value of the device
    */
    setDevice( device ) {
        this._device = device;
    }

    /*
    * Change the value which allows to know if the user has confirmed his answer to the question
    */
    setAnswerValidated( isValidate ) {
        this._answerValidated = isValidate;
    }

}

/*
    Class ResultManager
    Manage results
    Params : {
        {DOM} containerQuiz - The quiz container
        {String} device - 'mobile' or 'desktop'
        {Object} pll - Different translations of sentences / titles
    }
*/
class ResultManager extends EventEmitter {

    constructor({ containerQuiz = null, device = null, pll = null }) {
        super();

        this.referringClassName = {};
        this.referringClassName.nameComponent = 'c-quiz-result';
        this.referringClassName.result = 'js-' + this.referringClassName.nameComponent;
        this.referringClassName.resultContainerBottom = this.referringClassName.nameComponent + '__bottom__container__content';

        // Define properties
        this._styleConsole = 'background: #222; color: #ff8c8c';
        this._classes = {
            container: this.referringClassName.result,
            title: this.referringClassName.result + '-title',
            titleNumber: this.referringClassName.result + '-title-number',
            titleEliminatory: this.referringClassName.result + '-title-eliminatory',
            questionsContainer : this.referringClassName.result + '-questions',
            questionItem: this.referringClassName.resultContainerBottom + '__question',
            questionItemJS: this.referringClassName.result + '-question-item',
            questionLeft: this.referringClassName.resultContainerBottom + '__question__left',
            questionNumber: this.referringClassName.resultContainerBottom + '__question__left__number',
            questionEliminatory: this.referringClassName.resultContainerBottom + '__question__left__eliminatory',
            questionTitle: this.referringClassName.resultContainerBottom + '__question__left__title',
            questionState: this.referringClassName.resultContainerBottom + '__question__state',
            right: 'right',
            wrong: 'wrong',
            empty: 'empty',
            show: 'show',
            hide: 'hide',
            animY : this.referringClassName.result + '-anim-y'
        }
        this._totalRightAnswers = 0;
        this._successAnswerEliminatory = true;
        this._currentQuestionPopin = 0;
        this._totalQuestion = 0;
        ///////////////////////////////////////////////////////////////
        this._containerQuiz = containerQuiz;
        this._device = device;
        this._pll = pll;

        this._initDomElements();
        this._initClass();
    }

    _initDomElements() {
        this.$container = this._containerQuiz.querySelector( '.' + this._classes.container );
        this.$title = this.$container.querySelector( '.' + this._classes.title );
        this.$titleNumber = this.$title.querySelector( '.' + this._classes.titleNumber );
        this.$titleEliminatory = this.$title.querySelector( '.' + this._classes.titleEliminatory );
        this.$questionsContainer = this.$container.querySelector( '.' + this._classes.questionsContainer );

        // Animation
        this.$eltAnimY = this.$container.querySelectorAll( '.' + this._classes.animY );
    }

    _initClass() {
        this._initAnimation();
    }

    /**
     * Init all animations of a question
     */
    _initAnimation() {

        // Init anim "Show Component"
        this._tlShowComponent = new TimelineMax({
            paused: true,
            onStart: () => {
                this.$container.classList.add( this._classes.show );
            }
        });

        this._tlShowComponent
            .staggerFromTo( this.$eltAnimY, 0.9, { y : 80 }, { y : 0, ease : Power2.easeInOut }, 0.1, 'start' )
            .staggerFromTo( this.$eltAnimY, 0.8, { autoAlpha : 0 }, { autoAlpha : 1, ease : Power2.easeInOut }, 0.1, 'start+=0.25' )

    }

    /**
     * Called when the result block is displayed
     */
    init( dataQuestions ) {
        this._dataQuestions = dataQuestions;
        this._totalQuestion = this._dataQuestions.length;

        this._createDOM();
        this._bindQuestions();
        this._initPopin();
        this._addEventListeners();
    }

    /**
     * Insertion of the different questions in the DOM result
     */
    _createDOM() {

        // Creation of DOM elements for each question
        let fragment = document.createDocumentFragment();

        this._dataQuestions.sort( this._orderResults );

        for( let i = 0, j = this._dataQuestions.length; i < j; i++ ) {

            let question = this._dataQuestions[i];

            // Create question item
            let questionItem = createHTMLElement('div',  {
                classname: this._classes.questionItem + ' ' + this._classes.questionItemJS,
            });
            fragment.appendChild( questionItem );

            // Create question 'left'
            let questionLeft = createHTMLElement('div', {
                classname: this._classes.questionLeft
            });
            questionItem.appendChild( questionLeft );

            // Create question 'question number'
            let questionNumber = createHTMLElement('div', {
                classname: this._classes.questionNumber,
                text : this._pll.questionLabel + ' ' + question.number
            });
            questionLeft.appendChild( questionNumber );

            // Create question 'eliminatory'
            if( question.isEliminatory ) {
                let questionEliminatory = createHTMLElement('div', {
                    classname: this._classes.questionEliminatory,
                    text : this._pll.questionEliminatory
                });
                questionLeft.appendChild( questionEliminatory );
            }

            // Create question 'title'
            let questionTitle = createHTMLElement('p', {
                classname: this._classes.questionTitle,
                text : question.title
            });
            questionLeft.appendChild( questionTitle );

            // Create question 'state'
            let questionState = createHTMLElement('div', {
                classname: ( question.isRightAnswer ) ? this._classes.questionState + ' ' + this._classes.right : this._classes.questionState
            });
            questionItem.appendChild( questionState );


            if( question.isRightAnswer )
                this._totalRightAnswers ++;
            
            if( question.isEliminatory && question.isRightAnswer == false )
                this._successAnswerEliminatory = false;
        }

        // Insertion of answer elements in the DOM result
        this.$questionsContainer.appendChild(fragment);

        this.$titleNumber.innerHTML = this._totalRightAnswers;

        if( this.$titleEliminatory && this._successAnswerEliminatory )
            this.$titleEliminatory.style.display = 'none';
    }

    /**
     * Order by isRightAnswer
     * @param a
     * @param b
     */
    _orderResults( a, b ) {
        return ( a.isRightAnswer === b.isRightAnswer ) ? 0 : a.isRightAnswer ? 1 : -1;
    }

    /*
    * Called after questions have been inserted into the DOM,
    * We put event on the click of questions
    */
    _bindQuestions() {

        this.$questions = [].slice.call( this.$questionsContainer.querySelectorAll( '.' + this._classes.questionItemJS ) );
        this._questionsListeners = [];

        for( let i = 0, j = this.$questions.length; i < j; i++ ) {
            this._questionsListeners[i] = this._clickQuestion.bind(this);
            addEventListener( this.$questions[i], 'click', this._questionsListeners[i] );
        }
    }

    /**
     * Called when the explanation popin is closed
     */
    _initPopin() {
        this._PopinQuiz = new PopinQuizManager({
            device : this._device
        });
    }

    _addEventListeners() {
        this._PopinQuiz.on(MANAGE_POPIN_QUIZ_EVENT.CHANGE_QUESTION, this._changeQuestion.bind(this) );
    }

    showComponent() {
        this._tlShowComponent.play(0);
    }

    /*
    * Called when the user clicks on a question
    * @param {Event} e - event
    */
    _clickQuestion( event ) {
        let target = event.currentTarget;
        let indexTarget = this.$questions.indexOf( target );
        this._currentQuestionPopin = indexTarget;

        this._PopinQuiz.prepare( this._dataQuestions[ indexTarget ] );
    }


    /*
    * Called when PopinQuiz requests a next or previous question
    * @param {string} direction - 'prev' or 'next'
   */
    _changeQuestion( direction ) {

        let operator = (direction == 'prev') ? -1 : 1;
        let next = loopIndex( this._currentQuestionPopin + operator, this._totalQuestion);
        this._PopinQuiz.prepare( this._dataQuestions[ next ] )
        this._currentQuestionPopin = next;
    }

    /*
    * Change the value of the device
    */
    setDevice( device ) {
        this._device = device;
    }
}

class PopinQuizManager extends EventEmitter {

    constructor({ device = null }) {
        super();

        this.referringClassName = {};
        this.referringClassName.nameComponent = 'c-quiz-popin';
        this.referringClassName.popin = 'js-' + this.referringClassName.nameComponent;

        // Define properties
        this._styleConsole = 'background: #222; color: #ff8c8c';
        this._classes = {
            container: this.referringClassName.popin,
            eliminatory: this.referringClassName.popin + '-eliminatory',
            number: this.referringClassName.popin + '-number',
            state: this.referringClassName.popin + '-state',
            answersContainer: this.referringClassName.popin + '-answers',
            title: this.referringClassName.popin + '-title',
            answer: 'c-quiz-popin__container__content__answer-item',
            explanation: this.referringClassName.popin + '-explanation',
            containerRight: this.referringClassName.popin + '-container-right',
            image: this.referringClassName.popin + '-image',
            theme: this.referringClassName.popin + '-theme',
            right: 'right',
            wrong: 'wrong',
            empty: 'empty',
            show: 'show',
            hide: 'hide',
            portrait: 'portrait',
            landscape: 'landscape',
            animX : this.referringClassName.popin + '-anim-x',
            animXReverse : this.referringClassName.popin + '-anim-x-reverse',
            animLeft : this.referringClassName.popin + '-anim-left',
            animRight : this.referringClassName.popin + '-anim-right',
            animRightXReverse : this.referringClassName.popin + '-anim-right-x-reverse',
        }
        this._letters = ['A', 'B', 'C', 'D'];
        this._isPopinOpen = false;
        this._direction = false;
        this._clickNavigationPopin = false;
        ///////////////////////////////////////////////////////////////
        this._device = device;

        this._initDomElements();
        this._initClass();
        this._addEventListeners();
    }

    _initDomElements() {
        this.$container = document.body.querySelector( '.' + this._classes.container );
        if( this.$container ) {
            this.$eliminatory = this.$container.querySelector('.' + this._classes.eliminatory );
            this.$number = this.$container.querySelector('.' + this._classes.number );
            this.$state = this.$container.querySelector('.' + this._classes.state );
            this.$title = this.$container.querySelector( '.' + this._classes.title );
            this.$answersContainer = this.$container.querySelector('.' + this._classes.answersContainer );
            this.$explanation = this.$container.querySelector('.' + this._classes.explanation );
            this.$containerRight = this.$container.querySelector('.' + this._classes.containerRight );
            this.$image = this.$container.querySelector('.' + this._classes.image );
            this.$theme = this.$container.querySelector('.' + this._classes.theme );

            // Animation
            this.$animLeft = this.$container.querySelector( '.' + this._classes.animLeft );
            this.$animRight = this.$container.querySelector( '.' + this._classes.animRight );
            this.$eltAnimX = this.$container.querySelectorAll( '.' + this._classes.animX );
            this.$eltAnimXReverse = this.$container.querySelectorAll( '.' + this._classes.animXReverse );
            this.$eltAnimRightXReverse = this.$container.querySelectorAll( '.' + this._classes.animRightXReverse );
        }
    }

    _initClass(){
        this._Popin = AppController.getInstance().Popin;
        this._Popin.setType( 'quiz' );

        this._initAnimation();
    }

    /**
     * Init all animations of a question
     */
    _initAnimation() {

        //--- Init anim "Show Question"
        this._tlShowQuestion = new TimelineMax({
            paused: true,
            onComplete : () => {
                this._endAnimShowQuestion();
            }
        });

        this._tlShowQuestion
            .fromTo( this.$animLeft, 0.7, { autoAlpha : 0, x : -80 }, { autoAlpha : 1, x : 0, ease : Power2.easeOut }, 'start' )
            // .fromTo( this.$animRight, 0.7, { x : 80, y: '-50%' }, { x : 0, y: '-50%', ease : Power2.easeOut }, 'start+=0.15' )
            .fromTo( this.$animRight, 0.55, { autoAlpha : 0, x : 0, y: '-50%' }, { autoAlpha : 1, x : 0, y: '-50%', ease : Power2.easeOut }, 'start+=0.25' )
            .staggerFromTo( this.$eltAnimX, 0.6, { x : -80 }, { x : 0, ease : Power2.easeInOut }, 0.05, 'start' )
            .staggerFromTo( this.$eltAnimX, 0.5, { autoAlpha : 0 }, { autoAlpha : 1, ease : Power2.easeInOut }, 0.05, 'start+=0.10' )
            .staggerFromTo( this.$eltAnimXReverse, 0.6, { x : 80 }, { x : 0, ease : Power2.easeInOut }, 0.05, 'start' )
            .staggerFromTo( this.$eltAnimXReverse, 0.5, { autoAlpha : 0 }, { autoAlpha : 1, ease : Power2.easeInOut }, 0.05, 'start+=0.10' )
            .staggerFromTo( this.$eltAnimRightXReverse, 0.5, { x : 80 }, { x : 0, ease : Power2.easeInOut }, 0.05, 'start+=0.05' )


        //--- Init anim "Hide Question"
        this._tlHideQuestion = new TimelineMax({
            paused: true,
            onComplete: () => {
                this._endAnimHideQuestion();
            }
        });

        this._tlHideQuestion
            .fromTo( this.$animLeft, 0.5, { autoAlpha : 1, x : 0 }, { autoAlpha : 0, x : -80, immediateRender:false, ease : Power2.easeIn }, 'start' )
            .fromTo( this.$animRight, 0.5, { autoAlpha : 1, x : 0, y: '-50%' }, { autoAlpha : 0, x : 80, y: '-50%', immediateRender:false,  ease : Power2.easeIn }, 'start+=0.15' )
            .to( this.$eltAnimX, 0.01, { x : -80, autoAlpha : 0, immediateRender:false, ease : Power2.easeInOut } )
            .to( this.$eltAnimXReverse, 0.01, { x : 80, autoAlpha : 0, immediateRender:false, ease : Power2.easeInOut } )
            .to( this.$eltAnimRightXReverse, 0.01, { x : 80, immediateRender:false, ease : Power2.easeInOut } )


        //--- Init anim "Close Popin"
        this._tlClosePopin = new TimelineMax({
            paused: true,
            onComplete: () => {
                this._endAnimClosePopin();
            }
        });

        this._tlClosePopin
            .fromTo( this.$animLeft, 0.5, { autoAlpha : 1, x : 0 }, { autoAlpha : 0, x : -80, immediateRender:false, ease : Power2.easeIn }, 'start' )
            .fromTo( this.$animRight, 0.5, { autoAlpha : 1, x : 0, y: '-50%' }, { autoAlpha : 0, x : 80, y: '-50%', immediateRender:false, ease : Power2.easeIn }, 'start+=0.15' )
            .to( this.$eltAnimX, 0.01, { x : -80, autoAlpha : 0, immediateRender:false, ease : Power2.easeInOut } )
            .to( this.$eltAnimXReverse, 0.01, { x : 80, autoAlpha : 0, immediateRender:false, ease : Power2.easeInOut } )

    }

    _addEventListeners() {
        this._Popin.on( 'popin_open', this._openPopin.bind(this) );
        this._Popin.on( 'popin_to_close', this._closePopin.bind(this) );
        this._Popin.on( 'popin_next', this._nextQuestion.bind(this) );
        this._Popin.on( 'popin_prev', this._prevQuestion.bind(this) );
    }

    /*
   * Called when the user clicks on a question (of the result block) OR change question in the popin
   * @param {Event} e - event
   */
    prepare( question ) {
        this._currentQuestion = question;

        this._injectData( this._currentQuestion );

        if( this._isPopinOpen == false ) {
            this._isPopinOpen = true;
            this._Popin.open();
        } else {
            this._showQuestion();
        }
    }

    /*
   * Injection of data from the question into the popin
   * @param {Object} question - the question clicked
   */
    _injectData( question ) {

        if( this.$container == null )
            return;

        // Question title
        this.$title.innerHTML = question.title;

        // Question number
        this.$number.innerHTML = question.number;

        // Question theme
        this.$theme.innerHTML = question.theme;

        // If image exists :
        if( question.image ) {
            this.$image.setAttribute('src', question.image['url']);
            this.$image.setAttribute('alt', question.image['alt']);

            if( question.image['width'] >= question.image['height'] )
                this.$containerRight.classList.add( this._classes.landscape );
            else
                this.$containerRight.classList.add( this._classes.portrait );

            this.$image.classList.remove( this._classes.hide );
        }

        // Question explanation
        this.$explanation.innerHTML = question.explanation;

        // Display or not of the block "eliminatory question"
        if( question.isEliminatory ) {
            this.$eliminatory.classList.add( this._classes.show );
        }else {
            this.$eliminatory.classList.remove( this._classes.show );
        }

        // Question state
        this.$state.classList.remove( this._classes.right )
        this.$state.classList.remove( this._classes.empty )
        switch (question.isRightAnswer) {
            case true :
                this.$state.classList.add( this._classes.right );
                break;
            case null :
                this.$state.classList.add( this._classes.empty )
                break;
        }

        /// Creation of DOM elements for each answer
        let fragment = document.createDocumentFragment();

        for( let i = 0, j = question.answers.length; i < j; i++ ) {

            let classAnswers = this._classes.answer;
            if( question.userAnswer != null && (question.userAnswer != question.rightAnswer) && (question.userAnswer == i) )
                classAnswers += ' ' + this._classes.wrong;
            if( question.rightAnswer == i )
                classAnswers += ' ' + this._classes.right;

            // Create answer button
            let answer = createHTMLElement('div',  {
                classname: classAnswers,
                text:  this._letters[i] + '. ' + question.answers[i],
            });
            fragment.appendChild( answer );
        }

        // Insertion of answer elements in the DOM
        this.$answersContainer.appendChild(fragment);
    }

    /**
     * Called when the generic Popin is open
     */
    _openPopin() {
        this._showQuestion();
    }

    /**
     * Show question in popin
     */
    _showQuestion() {
        this._tlShowQuestion.play(0);
    }

    /*
    * Called when the user clicks on the "next" button of the Popin
    */
    _nextQuestion( ) {
        this._changeQuestion( 'next' );
    }

    /*
    * Called when the user clicks on the "prev" button of the Popin
    */
    _prevQuestion( ) {
        this._changeQuestion( 'prev' );
    }

    /*
    * Change of the question displayed in the Popin (depending on the 'prev' or 'next' button in the popin)
    */
    _changeQuestion( direction ) {
        this._direction = direction;
        this._clickNavigationPopin = true;
        this._tlHideQuestion.play(0);
    }

    /**
     * Called when the "display" animation of the question is finished
     */
    _endAnimShowQuestion() {
        if( this._clickNavigationPopin ) {
            this._clickNavigationPopin = false;
            this._Popin.emit('popin_end_process_navigation');
        }
    }

    /**
     * Called when the animation "disappearance" of the question is finished
     */
    _endAnimHideQuestion() {
        this._resetData();
        this.emit(MANAGE_POPIN_QUIZ_EVENT.CHANGE_QUESTION, this._direction);
    }

    /**
     * Called when the animation of the popin closure is finished
     */
    _endAnimClosePopin() {
        this._resetData();
        this._isPopinOpen = false;
        this._Popin.emit('popin_close');
    }

    /**
     * Called before the generic popin closes
     */
    _closePopin() {
        this._tlClosePopin.play(0);
    }

    /*
    * Reset data from the question into the popin
    */
    _resetData(){

        if( this.$container == null )
            return;

        // Question title
        this.$title.innerHTML = '';

        // Question number
        this.$number.innerHTML = '';

        // Question theme
        this.$theme.innerHTML = '';

        // If image exists :
        if( this._currentQuestion['image'] ) {
            this.$image.setAttribute('src', '');
            this.$image.setAttribute('alt', '');
            this.$containerRight.classList.remove( this._classes.landscape );
            this.$containerRight.classList.remove( this._classes.portrait );
            this.$image.classList.add( this._classes.hide );
        }

        // Question explanation
        this.$explanation.innerHTML = '';

        // Hide the block "eliminatory question"
        this.$eliminatory.classList.remove( this._classes.show );

        // Question state
        this.$state.classList.remove( this._classes.right )
        this.$state.classList.remove( this._classes.empty )


        // Delete answers if they exist
        let oldAnswers = this.$answersContainer.childNodes;
        if( oldAnswers.length > 0 ) {
            while( this.$answersContainer.firstChild ) {
                this.$answersContainer.removeChild(this.$answersContainer.firstChild);
            }
        }
    }

    /*
    * Change the value of the device
    */
    setDevice( device ) {
        this._device = device;
    }
}

export const MANAGE_TIMER_EVENT = {
    'END' : '__mt_end',
    'START' : 'mt_start'
}

export const MANAGE_QUESTION_EVENT = {
    'COMPONENT_SHOWN' : '__mq_component_shown',
    'COMPONENT_HIDDEN' : '__mq_component_hidden',
    'CLICK_ANSWER' : '__mq_click_answer',
    'SHOWN' : '__mq_shown',
    'HIDDEN' : '__mq_hidden',
}

export const MANAGE_POPIN_QUIZ_EVENT = {
    'CHANGE_QUESTION' : '__mpq_change_question',
}

export const MANAGE_INTRODUCTION_QUIZ_EVENT = {
    'CLICK_START' : '__miq_click_start',
    'HIDDEN' : '__miq_hidden',
}
