import { TweenMax, TimelineLite, TimelineMax, Power0, Power2, Linear, Elastic, CSSPlugin } from "gsap/all";

export default class FollowCursor {

    constructor( {container = false, cursor = false } ) {

        this._styleConsole = 'background: #222; color: #ff8c8c';
        this._isHover = false;

        this.$container = container;
        this.$cursor = cursor;

        if( this.checkRequiredParams() == false )
            return;

        this.initClass();
        this.addEventListeners();
    }

    /**
     * Check if the mandatory parameters to create a question are well informed
     * @return {Boolean}
     */
    checkRequiredParams() {

        if( this.$container == false ||  typeof this.$container != 'object' ) {
            console.warn('%c No container is filled for the "Follow Cursor" Object ', this._styleConsole);
            return false;
        }

        if( this.$cursor == false ||  typeof this.$cursor != 'object' || this.$cursor === null ) {
            console.warn('%c No cursor is filled for the "Follow Cursor" Object ', this._styleConsole);
            return false;
        }
    }

    initClass() {

        window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
        window.cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame

        // this.$container.style.cursor = 'none';

        this.updateData();
    }

    addEventListeners() {
        this.$container.addEventListener( 'mouseenter', this.enterZoneCursor.bind(this) );
        this.$container.addEventListener( 'mousemove', this.moveZoneCursor.bind(this) );
        this.$container.addEventListener( 'mouseleave', this.leaveZoneCursor.bind(this) );
    }

    enterZoneCursor( event ) {
        this._isHover = true;

        this.mouse = {
            x : event.clientX,
            y : event.clientY
        };

        this.raf = requestAnimationFrame(this.update.bind(this));
    }

    leaveZoneCursor( event ) {
        this._isHover = false;
        cancelAnimationFrame(this.raf);

        TweenMax.fromTo( this.$cursor, 0.4, { x : this.dataCursor.x, y : this.dataCursor.y, z : 0 } ,{ x : 0, y :0, z : 0, ease : Power2.easeOut } );
        this.dataCursor.y = 0;
        this.dataCursor.x = 0;
    }

    moveZoneCursor( event ) {

        /** Position x and y of the cursor **/
        this.mouse = {
            x : event.clientX,
            y : event.clientY
        };
    }

    resize() {
        this.updateData();
    }

    update() {

        if( typeof this.posContainer.x == 'undefined' || typeof this.posContainer.y == 'undefined' )
            return;

        let mouseInnerX = this.mouse.x - this.posContainer.x;
        // this.dataCursor.x += (( mouseInnerX - this.dataCursor.innerX ) - this.dataCursor.x ) * 0.2;

        let mouseInnerY = this.mouse.y - this.posContainer.y;
        // this.dataCursor.y += (( mouseInnerY - this.dataCursor.innerY ) - this.dataCursor.y ) * 0.2;
        // this.$cursor.style.transform = 'translate3d( ' + this.dataCursor.x + 'px, ' + this.dataCursor.y + 'px, 0px)';

        // Work too
        // this.dataCursor.y = this.lerp( this.dataCursor.y, (this.mouse.y - this.dataCursor.top ), 0.1  );
        // this.dataCursor.x = this.lerp( this.dataCursor.x, (this.mouse.x - this.dataCursor.left ), 0.1  );


        this.dataCursor.y = this.lerp( this.dataCursor.y, (mouseInnerY - this.dataCursor.innerY ), 0.1 );
        this.dataCursor.x = this.lerp( this.dataCursor.x, (mouseInnerX - this.dataCursor.innerX ), 0.1 );

        this.$cursor.style.transform = 'translate3d( ' + this.dataCursor.x + 'px, ' + this.dataCursor.y + 'px, 0px)';

        this.raf = requestAnimationFrame(this.update.bind(this));
    }

    updateData() {
        this.updateDataContainer();
        this.updateDataCursor();
    }

    updateDataContainer(){
        this.posContainer = {
            x : this.$container.getBoundingClientRect().left,
            y : this.$container.getBoundingClientRect().top,
        }
    }

    updateDataCursor() {
        this.dataCursor = {
            x : 0,
            y : 0,
            width: this.$cursor.getBoundingClientRect().width,
            height: this.$cursor.getBoundingClientRect().height,
        }
        this.dataCursor.halfW = this.dataCursor.width / 2;
        this.dataCursor.halfH = this.dataCursor.height / 2;
        this.dataCursor.innerX = ( this.$cursor.getBoundingClientRect().left + this.dataCursor.halfW ) - this.posContainer.x ;
        this.dataCursor.innerY = ( this.$cursor.getBoundingClientRect().top + this.dataCursor.halfH ) - this.posContainer.y ;

        this.dataCursor.left = this.$cursor.getBoundingClientRect().left + this.dataCursor.halfW ;
        this.dataCursor.top = this.$cursor.getBoundingClientRect().top + this.dataCursor.halfH ;
    }

    lerp (start, end, amt){
        return (1-amt)*start+amt*end
    }

    // Raft manager
    // Resize
    // Scroll Event
    // Mobile
}
