import AbstractComponent from "../AbstractComponent";
import Logger from '../../utils/Logger';
import Flickity from 'flickity';


export default class MasterTripleColumns extends AbstractComponent {
    constructor(...args) {

        Logger.log('MasterTripleColumns->constructor()');
        super(...args);

        this.classname = 'c-master-triple-columns';

        this.initDomElements();
        this.addEventListeners();

    }

    initDomElements() {
        this.$slider_container = this.$el.querySelector( '.' + this.classname + '__content ul' );
        this.$items = this.$el.querySelectorAll( '.' + this.classname + '__content__item' );
    }


    addEventListeners() {

        // FLICKITY
        this.flkty = new Flickity( this.$slider_container, {
            cellAlign: 'center',
            contain: true,
            groupCells: true,
            watchCSS: true,
            wrapAround: true,
            arrowShape: 'M55.8,56.7c0.3,0,0.6-0.2,0.9-0.3l7.9-7.7c0.3-0.2,0.3-0.6,0.3-0.9c0-0.3-0.2-0.6-0.3-0.9l-7.7-7.7 c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0.2-0.8,0.5-0.9,0.9s0,0.9,0.5,1.3l5.7,5.5H31.4c-0.8,0-1.3,0.6-1.3,1.3s0.5,1.3,1.3,1.3h29.1 l-5.7,5.7c-0.5,0.5-0.5,1.3,0,1.7C55,56.5,55.3,56.7,55.8,56.7'
        });

    }

    removeEventListeners() {
        // REMOVE FLICKITY ?
    }


    destroy() {
        Logger.log( 'MasterTripleColumns->destroy()' );

        this.removeEventListeners();
    }
}