import AbstractComponent from './AbstractComponent';
import Logger from '../utils/Logger';
import { isDate, isDateTime, dateDifferenceInDays } from '../utils/DateUtils';
import { stringFormat } from '../utils/JsUtils';


export default class DateFormat extends AbstractComponent {
    constructor(...args) {
        Logger.log( 'DateFormat->constructor()' );
        super(...args);

        this.initDomElements();
        this.updateDate();
        setInterval( this.updateDate, 1000 );
    }


    initDomElements() {
        const dataDate = this.$el.getAttribute( 'data-date' );
        if ( dataDate ) {
            if ( isDate( dataDate ) || isDateTime( dataDate ) ) {
                this.$date = dataDate;
            }
        }
    }


    updateDate() {
        if ( this.$date ) {
            const date = new Date( this.$date );
            const now = new Date();
            const dateDifference = Math.abs( dateDifferenceInDays( date, now ) );
            this.$el.innerHTML = this.getDateDifferenceMessage( dateDifference, date );
        }
    }


    getDateDifferenceMessage( dateDifference, date ) {
        switch ( dateDifference ) {
            case 0: 
                return 'Aujourd\'hui';
            case 1:
                return 'Hier';
            case 2: 
            case 3:
                return stringFormat( 'Il y a {0} jours', dateDifference );
            default:
                return 'Le ' + ( '0' + date.getDate() ).slice( -2 ) + '.' + ( '0' + ( date.getMonth() + 1 ) ).slice( -2 ) + '.' + date.getFullYear()
        }
    }


    destroy() {}
}