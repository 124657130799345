import AbstractComponent from "../AbstractComponent";
import Logger from '../../utils/Logger';
import { addClass, css, removeClass } from "../../utils/CssUtils";
import { addEventListener, removeEventListener, preventDefault } from "../../utils/EventUtils";
import { TweenMax, TimelineLite, Power1, Linear, CSSPlugin } from "gsap/all";


export default class MasterChronology extends AbstractComponent {
    constructor(...args) {
        Logger.log('MasterChronology->constructor()');
        super(...args);

        this.classname = 'c-master-chronology';

        this.initDomElements();
        this.initSlideshow();
        this.addEventListeners();
    }

    initDomElements() {

        this.$container = this.$el.querySelector( '.' + this.classname + '__container__main' );
        this.$timeline_dates = this.$el.querySelectorAll( '.' + this.classname + '__container__dates-timeline__item' );
        this.$dates = this.$el.querySelectorAll( '.' + this.classname + '__container__main__dates__item' );
        this.$medias = Array.prototype.slice.call(this.$el.querySelectorAll( '.' + this.classname + '__container__main__medias__item' ));
        this.$backgroundMedias = this.$el.querySelectorAll( '.' + this.classname + '__container__background-medias__slider__item' );
        this.$texts_container = this.$el.querySelector( '.' + this.classname + '__container__textual' );
        this.$texts = this.$el.querySelectorAll( '.' + this.classname + '__container__textual__slider__item' );
        this.$prevBtn = this.$el.querySelector( '.' + this.classname + '__container__textual__controls__nav--prev' );
        this.$nextBtn = this.$el.querySelector( '.' + this.classname + '__container__textual__controls__nav--next' );
    }


    addEventListeners() {
        // Nav click
        if( this.$prevBtn ) {
            this._prevBtnListener = this.prevBtnListener.bind( this );
            addEventListener( this.$prevBtn, 'click', this._prevBtnListener );
        }
        if( this.$nextBtn ) {
            this._nextBtnListener = this.nextBtnListener.bind( this );
            addEventListener( this.$nextBtn, 'click', this._nextBtnListener );
        }
        if( this.$timeline_dates.length > 0 ) {
            for( var i =0, j = this.$timeline_dates.length; i < j; i++ ) {
                addEventListener( this.$timeline_dates[i], 'click', this.timelineDatesClickListener.bind(this, i) );
            }
        }

        // Resize
        this.app.on( 'resize', this.resize.bind(this) );
    }


    initSlideshow() {
        this.current = 0;
        this.currentDate = 0;
        this.is_running = false;
        css( this.$medias[this.current], { width: '100%' } );
        css( this.$backgroundMedias[this.current], { width: '100%' } );
        // css( this.$texts[this.current], { position: 'relative' } );
        css( this.$dates[this.current], { position: 'relative' } );

        this.updateMediasMinWidth();
    }

    prevBtnListener() {
        // Lock system
        if( this.is_running == false ) {
            this.is_running = true;

            // Define current and next
            var current = this.current;
            var next = ( current == 0 ) ? this.$medias.length - 1 : current - 1;

            this.animatePrevious( current, next );
        }
    }

    nextBtnListener() {
        // Lock system
        if( this.is_running == false ) {
            this.is_running = true;

            // Define current and next
            var current = this.current;
            var next = ( current == this.$medias.length - 1 ) ? 0 : current + 1;


            this.animateNext( current, next );
        }
    }

    animatePrevious( current, next ) {

        this.previousTimeline = new TimelineLite();

        // Initial positions
        this.previousTimeline.set( this.$medias[next], { width: '100%' } );
        this.previousTimeline.set( this.$backgroundMedias[next], { width: '100%' } );
        this.previousTimeline.set( this.$dates[current], { clearProps: 'position' } );
        this.previousTimeline.set( this.$dates[next], { position: 'relative' } );

        // TIMELINE DATES
        this.updateDatesTimeline( current, next );

        // DATES
        if( this.currentDate != this.$medias[next].getAttribute( 'data-date-index' ) ) {
            var nextDate = this.$medias[next].getAttribute( 'data-date-index' );
            this.previousTimeline.fromTo( this.$dates[this.currentDate], 0.3, { x: 0, autoAlpha: 1 }, { x: -25, autoAlpha: 0, ease: Power1.easeOut }, 'start' );
            this.previousTimeline.fromTo( this.$dates[next], 0.5, { x: 25, autoAlpha: 0 }, { x: 0, autoAlpha: 1, delay: 0.2, ease: Power1.easeOut, onComplete: () => {

                    addClass( this.$dates[next], 'active' );
                    removeClass( this.$dates[current], 'active' );

                    this.currentDate = nextDate;
                } }, 'start' );
        } else {
            this.previousTimeline.addLabel( 'start' );
        }


        // MAIN MEDIAS
        this.previousTimeline.fromTo( this.$medias[current], 0.7, { width: '100%' }, { width: '0%', ease: Power1.easeOut, onComplete: () => {
                addClass( this.$medias[next], 'active' );
                removeClass( this.$medias[current], 'active' );

            } }, 'start+=0.1' );
        // BACKGROUND MEDIAS
        this.previousTimeline.fromTo( this.$backgroundMedias[current], 0.7, { width: '100%' }, { width: '0%', ease: Power1.easeOut, onComplete: () => {

                addClass( this.$backgroundMedias[next], 'active' );
                removeClass( this.$backgroundMedias[current], 'active' );

                // TO CALL ON LONGEST TWEEN
                this.endAnimation( current, next );

            }}, 'start+=0.2' );

        // TEXTS
        this.alternateBackgroundColor( next );
        this.previousTimeline.fromTo( this.$texts[current], 0.3, { x: 0, autoAlpha: 1 }, { x: -25, autoAlpha: 0, ease: Power1.easeOut }, 'start+=0.15');
        this.previousTimeline.fromTo( this.$texts[next], 0.5, { x: 25, autoAlpha: 0 }, { x: 0, autoAlpha: 1, ease: Power1.easeOut, onComplete: () => {

                addClass( this.$texts[next], 'active' );
                removeClass( this.$texts[current], 'active' );

            }}, 'start+=0.35' );
    }

    animateNext( current, next ) {

        this.nextTimeline = new TimelineLite();

        // Initial positions
        this.nextTimeline.set( this.$dates[current], { clearProps: 'position' } );
        this.nextTimeline.set( this.$dates[next], { position: 'relative' } );

        // TIMELINE DATES
        this.updateDatesTimeline( current, next );

        // DATES
        if( this.currentDate != this.$medias[next].getAttribute( 'data-date-index' ) ) {
            var nextDate = this.$medias[next].getAttribute( 'data-date-index' );
            this.nextTimeline.fromTo( this.$dates[this.currentDate], 0.3 ,{ x: 0, autoAlpha: 1 }, { x: 25, autoAlpha: 0, ease: Power1.easeOut }, 'start' );
            this.nextTimeline.fromTo( this.$dates[nextDate], 0.5 ,{ x: -25, autoAlpha: 0 }, { x: 0, autoAlpha: 1, delay: 0.2, ease: Power1.easeOut, onComplete: () => {
                    addClass( this.$dates[next], 'active' );
                    removeClass( this.$dates[this.currentDate], 'active' );

                    this.currentDate = nextDate;
                } }, 'start' );
        } else {
            this.previousTimeline.addLabel( 'start' );
        }

        // MAIN MEDIAS
        this.nextTimeline.fromTo( this.$medias[next], 0.7 ,{ width: '0%' }, { width: '100%', ease: Power1.easeOut, onStart: () => {
                addClass( this.$medias[next], 'active' );
                if ( next == 0 ) {
                    removeClass( this.$medias[current], 'active' );
                }
            }}, 'start+=0.1' );
        // BACKGROUND MEDIAS
        this.nextTimeline.fromTo( this.$backgroundMedias[next], 0.7 ,{ width: '0%' }, { width: '100%', ease: Power1.easeOut, onStart: () => {
                addClass( this.$backgroundMedias[next], 'active' );
                if ( next == 0 ) {
                    removeClass( this.$backgroundMedias[current], 'active' );
                }
            }, onComplete: () => {

                // TO CALL ON LONGEST TWEEN
                this.endAnimation( current, next );

            }}, 'start+=0.2' );

        // TEXTS
        this.alternateBackgroundColor( next );
        this.nextTimeline.fromTo( this.$texts[current], 0.3 ,{ x: 0, autoAlpha: 1 }, { x: 25, autoAlpha: 0, ease: Power1.easeOut }, 'start+=0.15');
        this.nextTimeline.fromTo( this.$texts[next], 0.5 ,{ x: -25, autoAlpha: 0 }, { x: 0, autoAlpha: 1, ease: Power1.easeOut, onComplete: () => {
                addClass( this.$texts[next], 'active' );
                removeClass( this.$texts[current], 'active' );
            } }, 'start+=0.35' );

    }

    endAnimation( current, next ) {

        removeClass( this.$backgroundMedias[current],  'active' );
        css( this.$medias[current], { width: '0' } );
        css( this.$backgroundMedias[current], { width: '0' } );

        this.current = next;
        this.is_running = false;
    }

    alternateBackgroundColor( index ) {
        if( index % 2 != 0 )
            this.$texts_container.classList.add( 'color-bis' );
        else
            this.$texts_container.classList.remove( 'color-bis' );
    }

    timelineDatesClickListener( index ) {
        // Lock system
        if( this.is_running == false ) {
            this.is_running = true;

            if( this.currentDate != index ) {
                this.$timeline_dates[this.currentDate].classList.remove( 'active' );
                this.$timeline_dates[index].classList.add( 'active' );

                // Define next element
                for( var i = 0, j = this.$medias.length; i < j; i++ ) {
                    if( this.$medias[i].getAttribute( 'data-date-index' ) == index ) {
                        var nextElement = this.$medias[i];
                        // Convert index and animate
                        var next = this.$medias.indexOf( nextElement );
                        if( this.currentDate > index )
                            this.animatePrevious( this.current, next );
                        else
                            this.animateNext( this.current, next );

                        this.currentDate = index;
                        return;
                    }
                }
            } else {
                this.is_running = false;
                return;
            }
        }
    }

    updateDatesTimeline( current, next ) {
        this.$timeline_dates[current].classList.remove( 'active' );
        this.$timeline_dates[next].classList.add( 'active' );
    }

    resize() {
        this.updateMediasMinWidth();
    }


    updateMediasMinWidth() {
        for ( const media of this.$medias ) {
            var mediaFirstElementChild = media.firstElementChild;
            if ( mediaFirstElementChild ) {
                css( mediaFirstElementChild, { minWidth: this.$container.getBoundingClientRect().width + 'px' } );
            }
        }
        for ( const media of this.$backgroundMedias ) {
            var mediaFirstElementChild = media.firstElementChild;
            if ( mediaFirstElementChild ) {
                css( mediaFirstElementChild, { minWidth: this.$container.getBoundingClientRect().width + 'px' } );
            }
        }
    }


    removeEventListeners() {
        removeEventListener( this.$prevBtn, 'click', this._prevBtnListener );
        removeEventListener( this.$nextBtn, 'click', this._nextBtnListener );
    }


    destroy() {
        Logger.log( 'MasterChronology->destroy()' );

        this.removeEventListeners();
    }
}