import AbstractPageController from './AbstractPageController';
import Logger from '../utils/Logger';
import { addEventListener } from '../utils/EventUtils';
import { debounce } from 'lodash';
import { addClass, css, removeClass } from '../utils/CssUtils';
import Request from '../utils/Request';
import Sticky from '../utils/Sticky';
import queryString from 'query-string';
import { TweenMax, Cubic, ScrollToPlugin } from "gsap/all";
import gsap from 'gsap';
gsap.registerPlugin(ScrollToPlugin);


export default class NewsController extends AbstractPageController {
    constructor( ...args ) {
        super( ...args );
        Logger.log( 'NewsController->constructor()' );

        this.action = 'fnc_news_ajax';
        this.is_process = false;
        this.params = {
            post_id: document.querySelector( '[data-page-id]' ).getAttribute( 'data-page-id' ),
            page: 1,
            news_category: [],
        };
        this.loader_duration = 1500;

        this.initDomElements();
        this.addEventListeners();
        this.initStickyFilters();

        // Query Filters check
        this.urlQuery = queryString.parseUrl( window.location.href );
        if( Object.keys( this.urlQuery.query ).length > 0 ) {
            this.preFillFilters();
        }

        this.ready();
    }


    initDomElements() {
        this.$list_container = document.body.querySelector( '.js-news-container' );
        this.$navbar = document.body.querySelector( '.c-site-menu__navbar' );
        this.$header = document.body.querySelector( '.c-header__content__title' );
        this.$list_results = document.body.querySelector( '.js-news-results' );
        this.$load_more = document.body.querySelector( '.js-news-load-more' );
        this.$filter_container = document.body.querySelector('.c-news-filters');
        this.$filter_category = document.body.querySelector('.js-news-filter-category select');
        this.$filter_category_label = document.body.querySelector('.c-news-filters__container__label');
    }


    addEventListeners() {
        // Load more click
        this._loadMoreListener = this.processLoadMore.bind( this );
        addEventListener( this.$load_more, 'click', this._loadMoreListener );

        // Filter category
        if( this.$filter_category ) {
            this._filterCategoryListener = this.processFilter.bind( this, 'category' );
            addEventListener( this.$filter_category, 'change', this._filterCategoryListener );
        }

        addEventListener( window, 'resize', debounce( this.resize.bind(this), 16 ) );
    }

    initStickyFilters() {
        if( this.$filter_container ) {
            this.StickyFilters = new Sticky( this.$filter_container, {
                is_bounded: true,
                referer: this.$list_container,
                offset: document.body.querySelector( '.c-site-menu__navbar' ).getBoundingClientRect().height + this.$filter_container.getBoundingClientRect().height / 2,
                positionFrom: 'top'
            });
            this.resetSticky();
        }
    }

    preFillFilters() {

        // Prefill News Category
        if( this.urlQuery.query['c'] ) {
            for( var i = 0, j = this.$filter_category.options.length; i < j; i++ ) {
                if( this.$filter_category.options[i].getAttribute( 'data-slug' ) == this.urlQuery.query['c'] ) {
                    this.$filter_category.value = this.$filter_category.options[i].value;
                    this.params.news_category = [ this.$filter_category.value ];
                    this.params.news_category_slug = this.$filter_category.options[i].getAttribute( 'data-slug' )
                }
            }
        }
    }

    showLoader( el ) {
        this.current_loader = el;

        // Show loading classes
        document.body.classList.add('is-loading');
        this.current_loader.classList.add('is-loading');

        // Force delay to avoid too fast data load...
        this.loader_timeout = setTimeout( () => {
            this.loader_timeout = null;
            this.hideLoader( true );
            clearTimeout( this.loader_timeout );
        }, this.loader_duration );
    }

    hideLoader( fromTimeout = false ) {
        if( this.is_process == false ) {

            if( fromTimeout == true || this.loader_timeout == null ) {

                // Hide loading classes
                document.body.classList.remove('is-loading');
                this.current_loader.classList.remove('is-loading');

                this.showResults();
            }
        }
    }

    processFilter() {

        if( this.is_process == false ) {

            this.is_process = true;

            this.scrollTop();
            this.showLoader( this.$filter_category_label );

            // Prepare request data
            this.params.page = 1;
            this.params.news_category = ( this.$filter_category.value ) ? [ this.$filter_category.value ] : [];
            this.params.news_category_slug = ( this.$filter_category.value ) ? this.$filter_category.options[this.$filter_category.options.selectedIndex].getAttribute( 'data-slug' ) : '';

            // Format URL filters
            if( this.params.news_category.length > 0 ) {
                let newQueryUrl = {
                    c: this.params.news_category_slug
                };
                let newUrlParams = queryString.stringify( newQueryUrl );
                let url = ( newUrlParams.length > 0 ) ? this.urlQuery.url + '?' + newUrlParams : this.urlQuery.url;
                history.pushState( newUrlParams, null, url );
            } else
                history.pushState( null, null, this.urlQuery.url );


            // Send request
            this.request();
        }

    }

    processLoadMore() {

        if( this.is_process == false ) {
            this.is_process = true;

            this.showLoader( this.$load_more );

            // Prepare request data
            this.params.page = this.params.page + 1;

            // Send request
            this.request();
        }

    }

    request() {
        Request.AJAX({
            'url': window.FNC.ajax_url + '?action=' + this.action,
            'data': this.params,
            'success': this.onSuccess.bind(this)
        } );
    }


    onSuccess( response ) {

        // Results
        if( response.data.items_count > 0 ) {

            // Hide no results screen
            this.$list_container.classList.remove( 'no-results' );

            // Check if there is more items to load
            if( response.data.has_more == true )
                this.showLoadMore();
            else
                this.hideLoadMore();

            // Store data before replacing or appending it (to allow force loading)
            this.tempResponseItems = response.data.items_html;
            this.tempResponsePage = response.data.page;

        } else {

            // Show no results screen
            this.$list_container.classList.add( 'no-results' );

            // hide load more
            this.hideLoadMore();
        }

        // Unlock process
        this.is_process = false;
        this.hideLoader();
    }

    showLoadMore() {
        this.$load_more.classList.remove('u-d-none');
    }

    hideLoadMore() {
        this.$load_more.classList.add('u-d-none');
    }

    showResults() {

        if( this.tempResponsePage == 1 ) {
            this.$list_results.innerHTML = this.tempResponseItems;
        } else {
            this.$list_results.insertAdjacentHTML('beforeend', this.tempResponseItems);
        }

        // Animate-in new items
        this.newItems = document.querySelectorAll('.to-animate');
        for( var i = 0, j = this.newItems.length; i < j; i++ )
        {
            var delay = ( i + 1 ) * 210;
            this.animateResult( i, delay );
        }

        if( this.StickyFilters )
            this.StickyFilters.resize();
    }

    animateResult( index, delay ) {
        setTimeout(() => {
            this.newItems[index].classList.remove( 'to-animate' );
        }, delay);
    }

    resize() {
        if( this.StickyFilters )
            this.resetSticky();
    }

    resetSticky() {

        // Set new offset and resize
        var baseOffset = document.body.querySelector( '.c-site-menu__navbar' ).getBoundingClientRect().height + this.$filter_container.getBoundingClientRect().height / 2;
        var offset = baseOffset + 15;
        if( window.innerWidth > 576 ) // 576 => XS breakpoint
            offset = baseOffset + 25;
        else if( window.innerWidth > 768 )
            offset = baseOffset + 35

        this.StickyFilters.setOffset( offset );
        this.StickyFilters.resize();
    }

    scrollTop() {
        var offset = window.pageYOffset + this.$header.getBoundingClientRect().top - this.$navbar.getBoundingClientRect().height - 30;
        TweenMax.to( window, 0.65, { scrollTo: { y: offset, autoKill: false } , ease: Cubic.easeOut } );
    }


    update() {}


    destroy() {}
}
