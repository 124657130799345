import AbstractComponent from './AbstractComponent';
import Logger from '../utils/Logger';
import { addClass, removeClass } from '../utils/CssUtils';
import { addEventListener, preventDefault } from '../utils/EventUtils';
import { createHTMLElement } from '../utils/HtmlUtils';
import { hasProperty } from '../utils/JsUtils';
import Request from '../utils/Request';


export default class Newsletter extends AbstractComponent {
    constructor(...args) {
        Logger.log( 'Newsletter->constructor()' );
        super(...args);

        this.classname = 'c-newsletter-ribbon';
        this.enabled = true;
        this.needsResetAfterSuccess = false;
        this.initDomElements();
        this.addEventListeners();
    }


    initDomElements() {
        this.$emailField = this.$el.querySelector( '[name="form_email"]' );
        this.$submitButton = this.$el.querySelector( '[name="form_submit"]' );
    }


    addEventListeners() {
        if ( this.$emailField ) {
            addEventListener( this.$emailField, 'focus', () => this.checkResetAfterSuccess() );
            addEventListener( this.$emailField, 'blur', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$emailField, this.isEmpty, this.isValidEmail );
            } );
            addEventListener( this.$emailField, 'keyup', () => {
                this.checkResetAfterSuccess();
                this.checkField( this.$emailField, this.isEmpty, this.isValidEmail );
            } );
        }

        addEventListener( this.$el, 'submit', this.submit.bind( this ) );
    }


    submit( e ) {
        preventDefault( e );
        if ( this.enabled ) {
            this.enabled = false;

            const checkFormFields = this.checkFormFields();
            if ( checkFormFields ) {
                this.executeRecaptcha();
            } else {
                this.showErrorSubmitButton();
            }
        }
    }


    checkFormFields() {
        const allFields = [];

        if ( this.$emailField ) {
            allFields.push( [ this.$emailField, this.isEmpty, this.isValidEmail ] );
        }

        const checkAllFields = allFields.map( ( field ) => {
            return this.checkField( field[0], field[1], field[2] );
        } );
        
        return ( checkAllFields.indexOf( false ) === -1 );
    }


    checkField( field, checkEmpty = null, checkValid = null ) {
        if ( field ) {
            const value = field.value;
            if ( checkEmpty ) {
                const isEmpty = checkEmpty( value );
                if ( isEmpty ) {
                    this.showFieldError( field, 'empty' );
                    return false;
                }
            }
            if ( checkValid ) {
                const isValid = checkValid( value );
                if ( !isValid ) {
                    this.showFieldError( field, 'invalid' );
                    return false;
                }
            }
            this.hideFieldError( field );
        }
        return true;
    }


    isEmpty( s ) {
        if ( s && ( s !== '' ) && ( s.trim() !== '' ) ) {
            return false;
        }
        return true;
    }


    isValidEmail( email ) {
        if ( email && ( email !== '' ) && ( email.trim() !== '' ) ) {
            const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            return regex.test( email );
        }
        return false;
    }


    showFieldError( field, attribute ) {
        addClass( field.parentElement, this.classname + '__field--error' );
        const errorElement = field.parentElement.querySelector( '.' + this.classname + '__error' );
        if ( errorElement ) {
            const message = errorElement.getAttribute( 'data-message-' + attribute );
            if ( message ) {
                errorElement.innerHTML = message;
            }
        }
    }


    hideFieldError( field ) {
        removeClass( field.parentElement, this.classname + '__field--error' );
        const errorElement = field.parentElement.querySelector( '.' + this.classname + '__error' );
        if ( errorElement ) {
            errorElement.innerHTML = '';
        }
    }


    executeRecaptcha() {
        if ( grecaptcha !== undefined ) {
            addClass( this.$submitButton, this.classname + '__submit--progress' );

            // Removing previously created recaptcha & reset related fields
            if ( this.$recaptcha ) {
                this.$submitButton.parentElement.removeChild( this.$recaptcha );
                this.$recaptcha = null;
                this.recaptchaWidget = null;
                this.recaptchaValue = null;
            }
    
            // Initializing & executing a new Recaptcha
            this.$recaptcha = createHTMLElement( 'div', { classname: this.classname + '__recaptcha' } );
            this.$submitButton.parentElement.appendChild( this.$recaptcha );
            try {
                this.recaptchaWidget = grecaptcha.render( this.$recaptcha, {
                    'sitekey': window.FNC.recaptcha_site_key,
                    'size': 'invisible',
                    'callback': this.recaptchaCallback.bind( this ),
                    'error-callback': this.showErrorSubmitButton.bind( this )
                } );
                grecaptcha.execute( this.recaptchaWidget );
            } catch ( error ) {
                this.showErrorSubmitButton();
            }
        } else {
            this.showErrorSubmitButton();
        }
    }


    recaptchaCallback() {
        this.recaptchaValue = grecaptcha.getResponse( this.recaptchaWidget );
        
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push( { 'event': 'Newsletter' } );

        Request.AJAX( {
            'url': window.FNC.ajax_url + '?action=fnc_newsletter_ajax',
            'data': this.getFormData(),
            'success': this.handleAjaxResponse.bind( this ),
            'error': this.showErrorSubmitButton.bind( this )
        } );
    }


    getFormData() {
        const data = {};

        if ( this.$emailField ) {
            data['email'] = this.$emailField.value;
        }

        data['recaptcha'] = this.recaptchaValue;

        return data;
    }


    handleAjaxResponse( response ) {
        if ( response && hasProperty( response, 'success' ) && response.success ) {
            this.showSuccessSubmitButton();
        } else {
            this.showErrorSubmitButton();
        }
    }


    showSuccessSubmitButton() {
        removeClass( this.$submitButton, this.classname + '__submit--progress' );
        addClass( this.$submitButton, this.classname + '__submit--success' );
        setTimeout( () => {
            this.enabled = true;
            this.needsResetAfterSuccess = true;
        }, 1500 );
    }


    showErrorSubmitButton() {
        removeClass( this.$submitButton, this.classname + '__submit--progress' );
        const submitErrorClass = this.classname + '__submit--error';
        addClass( this.$submitButton, submitErrorClass );
        setTimeout( () => {
            removeClass( this.$submitButton, submitErrorClass );
            this.enabled = true;
        }, 1500 );
    }


    checkResetAfterSuccess() {
        if ( this.needsResetAfterSuccess ) {
            this.needsResetAfterSuccess = false;
            removeClass( this.$submitButton, this.classname + '__submit--success' );
            this.resetFormFields();
        }
    }


    resetFormFields() {
        if ( this.$emailField ) {
            this.$emailField.value = '';
        }
    }


    destroy() {}
}