/**
 * Checks if the given string is a date (YYYY-MM-DD).
 */
export function isDate( date ) {
    // First check for the pattern
    if ( !/^\d{4}-\d{2}-\d{2}$/.test( date ) ) {
        return false;
    }

    // Parse the date parts to integers
    const year = parseInt( date.substring( 0, 4 ), 10 );
    const month = parseInt( date.substring( 5, 7 ), 10 );
    const day = parseInt( date.substring( 8, 10 ), 10 );

    // Check the ranges of year
    if ( ( year < 1000 ) || ( year > 3000 ) ) {
        return false;
    }

    // Check the ranges of month
    if ( ( month == 0 ) || ( month > 12 ) ) {
        return false;
    }

    let monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

    // Adjust for leap years
    if ( ( year % 400 == 0 ) || ( ( year % 100 != 0 ) && ( year % 4 == 0 ) ) ) {
        monthLength[1] = 29;
    }

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
}


/**
 * Checks if the given string is a datetime (YYYY-MM-DD HH:MM:SS).
 */
export function isDateTime( date ) {
    // First check for the pattern
    if ( !/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test( date ) ) {
        return false;
    }

    // Parse the date parts to integers
    const year = parseInt( date.substring( 0, 4 ), 10 );
    const month = parseInt( date.substring( 5, 7 ), 10 );
    const day = parseInt( date.substring( 8, 10 ), 10 );

    // Check the ranges of year
    if ( ( year < 1000 ) || ( year > 3000 ) ) {
        return false;
    }

    // Check the ranges of month
    if ( ( month == 0 ) || ( month > 12 ) ) {
        return false;
    }

    let monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

    // Adjust for leap years
    if ( ( year % 400 == 0 ) || ( ( year % 100 != 0 ) && ( year % 4 == 0 ) ) ) {
        monthLength[1] = 29;
    }

    // Check the range of the day
    if ( day == 0 || day > monthLength[month - 1] ) {
        return false;
    }

    // Parse the time parts to integers
    const hours = parseInt( date.substring( 11, 13 ), 10 );
    const minutes = parseInt( date.substring( 14, 16 ), 10 );
    const seconds = parseInt( date.substring( 17, 19 ), 10 );

    // Check the ranges of hours
    if ( hours > 23 ) {
        return false;
    }

    // Check the ranges of minutes
    if ( minutes > 59 ) {
        return false;
    }

    // Check the ranges of seconds
    if ( seconds > 59 ) {
        return false;
    }

    return true;
}


/**
 * Returns the date difference in days.
 */
export function dateDifferenceInDays( date1, date2 ) {
    var t2 = date1.getTime();
    var t1 = date2.getTime();

    return parseInt( ( t2 - t1 ) / ( 24 * 3600 * 1000 ) );
}