import AbstractComponent from "./AbstractComponent";
import Logger from "../utils/Logger";
import anime from "animejs";
import { addEventListener } from "../utils/EventUtils";

export default class faqCardImageMorphing extends AbstractComponent {
    constructor(...args) {
        Logger.log("faqCardImageMorphing->constructor()");
        super(...args);
        this.classname = "c-js-faq-card";
        this.initDomElements();
        this.addEventListeners();
        this.initTimeline();
    }

    initDomElements() {
        this.$cardMask = this.$el.querySelector(
            "." + this.classname + "__image__clip-path"
        );
    }

    addEventListeners() {
        if (this.$cardMask) {
            addEventListener(this.$el, "mouseenter", this.playAnimation.bind(this));
            addEventListener(this.$el, "mouseleave", this.stopAnimation.bind(this));
        }
    }
    initTimeline() {
        this.tl = anime.timeline({
            targets: this.$cardMask,
            duration: 1000,
            easing: 'cubicBezier(.5, .05, .1, .3)',
            elasticity: 100,
            direction: "alternate",
            yoyo: true,
            autoplay: false,
        });
        this.tl.add({
            d: [
                {
                    value:
                        "M35,-63.6C46.4,-54.1,57.2,-46.7,67.5,-36.5C77.7,-26.2,87.3,-13.1,88.2,0.5C89.1,14.2,81.3,28.3,72.2,40.5C63.2,52.8,52.8,63.1,40.5,68.1C28.3,73,14.1,72.6,0.1,72.5C-14,72.4,-28,72.6,-40.6,67.8C-53.3,63.1,-64.6,53.4,-70.2,41.3C-75.9,29.2,-75.9,14.6,-73.7,1.3C-71.5,-12,-67,-24.1,-60.7,-35.1C-54.4,-46.1,-46.3,-56.2,-35.9,-66.1C-25.5,-76,-12.7,-85.9,-0.4,-85.1C11.8,-84.4,23.7,-73,35,-63.6Z",
                },
                {
                    value:
                        "M31.4,-58.9C41.1,-48.9,49.5,-41.2,60.5,-31.7C71.5,-22.3,85,-11.1,85.8,0.4C86.6,12,74.5,24.1,66,37.7C57.5,51.4,52.4,66.8,42,74.2C31.6,81.6,15.8,81.1,0.5,80.3C-14.8,79.5,-29.7,78.3,-41.7,71.8C-53.8,65.3,-63.1,53.6,-72,40.7C-81,27.9,-89.6,13.9,-90.4,-0.5C-91.1,-14.8,-84.1,-29.7,-75.2,-42.5C-66.2,-55.3,-55.3,-66.1,-42.5,-74.3C-29.6,-82.4,-14.8,-87.9,-2,-84.5C10.9,-81.1,21.8,-68.9,31.4,-58.9Z",
                },
            ],
        });
    }
    playAnimation() {
        this.tl.play();
    }
    stopAnimation() {
        this.tl.pause();
    }

    destroy() {}
}
