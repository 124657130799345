import AbstractComponent from "./AbstractComponent";
import Logger from "../utils/Logger";
import { addEventListener } from "../utils/EventUtils";
import { closest } from "../utils/JsUtils";

export default class Dropdown extends AbstractComponent {
  constructor(...args) {
    // Logger.log( 'Dropdown->constructor()' );
    super(...args);

    this.initDomElements();
    this.initDropdown();
    this.addEventListeners();
  }

  initDomElements() {
    this.$dropdown = this.$el.querySelector("[data-dropdown]");
    this.$dropdown_content = this.$dropdown.querySelector(
      "[data-dropdown-content]"
    );
    this.$cta = this.$el.querySelector("[data-dropdown-trigger]");
    if (this.$dropdown.classList.contains('c-sensi-map-map__col__content__dropdown__subcontent')) {
      this.is_map = true
    }else(
      this.is_map = false
    )
  }

  addEventListeners() {
    addEventListener(this.$cta, "click", this.toggle.bind(this));
    this.app.on("resize", this.resize.bind(this));
  }

  initDropdown() {
    this.is_open = this.$el.getAttribute("data-open") == "true" ? true : false;
    this.height = this.$dropdown_content.getBoundingClientRect().height;

    this.child_dropdown = Array.from(
      this.$dropdown_content.querySelectorAll("ul")
    );

    this.child_dropdown.forEach((child) => {
      this.height += child.getBoundingClientRect().height;
    });

    // Check initial state
    if (this.is_open == true) {
      this.open();
    } else {
      this.close();
    }

    this.is_running = false;
  }

  toggle() {
    if (this.is_running == false) {
      this.is_running = true;

      // Open
      if (this.is_open == false) {
        this.open();
      } else {
        this.close();
      }

      this.is_running = false;
    }
  }

  resize() {
    // if (this.$dropdown.querySelectorAll("[data-dropdown-content]").length > 1) {
    //   return;
    // }

    this.resetHeight();
  }

  resetHeight() {
    this.height = this.$dropdown_content.getBoundingClientRect().height;

    this.child_dropdown = Array.from(
      this.$dropdown_content.querySelectorAll("ul")
    );

    this.child_dropdown.forEach((child) => {
      this.height += child.getBoundingClientRect().height;
    });

    if (this.is_open == true) {
      if (this.is_map) {
        this.$dropdown.style.maxHeight = 200+ "px";
      }else{
        this.$dropdown.style.maxHeight = this.height + "px";
      }
    }
  }

  open() {
    // Emit for parent update
    this.app.emit("dropdown_item_toggled", this.$dropdown_content, true);

    // Open
    this.$el.classList.add("is-open");
    if (this.is_map) {
      this.$dropdown.style.maxHeight = 200+ "px";
    }else{
      this.$dropdown.style.maxHeight = this.height + "px";
    }

    this.is_open = true;
    this.$el.setAttribute("data-open", "true");
  }

  close() {
    // Emit for parent update
    this.app.emit("dropdown_item_toggled", this.$dropdown_content, false);

    // Close
    this.$el.classList.remove("is-open");
    this.$dropdown.style.maxHeight = "0px";
    this.is_open = false;
    this.$el.setAttribute("data-open", "false");
  }

  destroy() {}
}
