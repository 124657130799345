import AbstractPageController from './AbstractPageController';
import Logger from '../utils/Logger';
import { getTimeline, isElementInViewport } from "../utils/AnimationUtils";
// import { TweenMax, TimelineLite, Power2, Linear, Elastic, CSSPlugin } from "gsap/all";

import { addEventListener } from '../utils/EventUtils';
import { debounce } from 'lodash';
import { addClass, css, removeClass } from '../utils/CssUtils';


export default class HomeController extends AbstractPageController {
    constructor( ...args ) {
        super( ...args );
        Logger.log( 'HomeController->constructor()' );

        this.initDomElements();
        this.addEventListeners();

        this.ready();
    }


    initDomElements() {}


    addEventListeners() {}


    update() {}


    destroy() {}
}
