export default {
    'actions-map-map': require( './actions-map/map.js' ),
    'sensi-map': require( './sensi-map/map.js' ),
    'actions-map-other-actions': require( './actions-map/other-actions.js' ),
    'date-format': require( './date-format.js' ),
    'dropdown': require( './dropdown.js' ),
    'faq-filters': require( './faq-filters.js' ),
    'form-select': require( './form-select.js' ),
    'header': require( './header/header.js' ),
    'home-section-map': require( './home/section-map.js' ),
    'home-section-triple-rows': require( './home/section-triple-rows.js' ),
    'home-section-values': require( './home/section-values.js' ),
    'home-section-videos': require( './home/section-videos.js' ),
    'master-charts': require( './master/master-charts.js' ),
    'master-chronology': require( './master/master-chronology.js' ),
    'master-form-report': require( './master/master-form-report.js' ),
    'master-form': require( './master/master-form.js' ),
    'master-form-new': require( './master/master-form-new.js' ),
    'master-gallery': require( './master/master-gallery.js' ),
    'master-key-figures': require( './master/master-key-figures.js' ),
    'master-slideshow': require( './master/master-slideshow.js' ),
    'master-triple-columns': require( './master/master-triple-columns.js' ),
    'media-cover': require( './media-cover.js' ),
    'media': require( './media.js' ),
    'mobile-sharing-bar': require( './mobile-sharing-bar.js' ),
    'newsletter': require( './newsletter.js' ),
    'page-transition': require( './page-transition.js' ),
    'photo-video-popin': require( './photo-video-popin.js' ),
    'video-embed': require( './video-embed.js' ),
    'faq-load-more-button': require('./faq-load-more-button.js'),
    'faq-card-image-morphing':require('./faq-card-image-morphing.js')
}
