import AbstractComponent from "./AbstractComponent";
import Logger from '../utils/Logger';
import { hasProperty } from '../utils/JsUtils';


export default class Media extends AbstractComponent {
    constructor(...args) {
        Logger.log( 'Media->constructor()' );
        super(...args);

        this.initDomElements();
        this.addEventListeners();
        this.updateAttributes();
    }


    initDomElements() {
        const dataSrcBreakpoints = this.$el.getAttribute( 'data-src-breakpoints' );
        if ( dataSrcBreakpoints ) {
            this.$srcBreakpoints = JSON.parse( dataSrcBreakpoints );
        }

        const dataRatioBreakpoints = this.$el.getAttribute( 'data-ratio-breakpoints' );
        if ( dataRatioBreakpoints ) {
            this.$ratioBreakpoints = JSON.parse( dataRatioBreakpoints );
        }
    }


    addEventListeners() {
        if ( this.$srcBreakpoints || this.$ratioBreakpoints ) {
            this.app.on( 'breakpoint-update', this.updateAttributes.bind( this ) );
        }
    }


    updateAttributes() {
        this.updateSrc();
        this.updateRatio();
        this.emit( 'media-attributes-update', this );
    }

    
    updateSrc() {
        if ( this.$srcBreakpoints ) {
            const previousSrc = this.$el.getAttribute( 'src' );
            const newSrc = this.app.getCurrentBreakpointValue( this.$srcBreakpoints );
            if ( newSrc && ( newSrc !== previousSrc ) ) {
                this.$el.setAttribute( 'src', newSrc );
            }
        }
    }


    updateRatio() {
        if ( this.$ratioBreakpoints ) {
            const previousRatio = this.$el.getAttribute( 'data-ratio' );
            const newRatio = this.app.getCurrentBreakpointValue( this.$ratioBreakpoints );
            if ( newRatio && ( newRatio !== previousRatio ) ) {
                this.$el.setAttribute( 'data-ratio', newRatio );
            }
        }
    }


    destroy() {}
}