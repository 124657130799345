import AbstractComponent from "./AbstractComponent";
import Logger from '../utils/Logger';
import {addEventListener} from "../utils/EventUtils";
import { TweenMax, TimelineLite, Power2, Linear, Cubic } from "gsap/all";
import CookiesManager from '../utils/CookiesUtils';
import { delegate } from '../utils/JsUtils';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';


export default class PageTransition extends AbstractComponent {
    constructor(...args) {
        Logger.log( 'PageTransition->constructor()' );
        super(...args);

        this.current_index = 0;
        this.cookie_name = 'page_introduction_viewed';
        this.interval_between_words = 1;

        this.initDomElements();
        this.initTimelines();

        this.addEventListeners();
    }


    initDomElements() {
        this.$background = this.$el.querySelector( '.c-page-transition__container__background' );
        this.$bar = this.$el.querySelector( '.c-page-transition__container__background__bar' );
        this.$background_texts_parent = this.$el.querySelector( '.c-page-transition__container__centering__background-texts' );
        this.$background_texts_container = this.$el.querySelector( '.c-page-transition__container__centering__background-texts__list' );
        this.$background_texts = this.$el.querySelectorAll( '.c-page-transition__container__centering__background-text' );
        this.$logo = this.$el.querySelector( '.c-page-transition__container__centering__logo' );
        this.$page_links = document.body.querySelectorAll( 'a' );

        this.setStyles();
    }

    setStyles() {
        if ( this.$background_texts.length > 0 ) {
            this.height = this.$background_texts[0].getBoundingClientRect().height;
            this.$background_texts_parent.style.height = this.height + 'px';
            this.$background_texts_container.style.marginTop = this.height + 'px';
        }
    }

    initTimelines() {

        // INTRO FULL
        this.showIntroTl = new TimelineLite({ paused: true, onComplete: this.onIntroComplete.bind(this) });

        // Check IF COOKIE to only add 'first' word animation
        if( CookiesManager.check( this.cookie_name ) ) {
            for( var i = 0; i < this.$background_texts.length; i++ )
            {
                if( i > 0 ) {
                    this.$background_texts[i].remove();
                }
            }
            this.$background_texts = this.$el.querySelectorAll( '.c-page-transition__container__centering__background-text' );

            this.showIntroTl.set( this.$background_texts_container, { y: -(this.height * 1 ) }, 'start' );
            this.showIntroTl.set( this.$logo, { autoAlpha: 1 }, 'start' );
            this.showIntroTl.to( this.$background_texts_container, 0.55, { y: -(this.height * 2 ), delay: 0.3, ease: Power2.easeOut, onStart: () => {
                    this.showRevealPage.play();
                }}, 'start+=0.5' );


        } else {

            this.showIntroTl.to( this.$logo, 0.6, { autoAlpha: 1, ease: Power2.easeOut }, 'start' );

            // Loop to add each word to transition
            for( var i = 1, j = this.$background_texts.length + 1; i <= j; i++ )
            {
                if( i == this.$background_texts.length + 1 ) {
                    this.showIntroTl.to( this.$background_texts_container, 0.5, { y: -(this.height * i ), delay: ( (i - 1) * this.interval_between_words ), ease: Power2.easeOut, onStart: () => {
                            this.showRevealPage.play();
                        }}, 'start+=0.45' );
                } else {
                    this.showIntroTl.to( this.$background_texts_container, 0.5, { y: -(this.height * i ), delay: ( (i - 1) * this.interval_between_words ), ease: Power2.easeOut }, 'start+=0.45' );
                }
            }

        }

        this.showIntroTl.play();
        disableBodyScroll( this.$el );

        // REVEAL PAGE
        this.showRevealPage = new TimelineLite( {paused: true, onComplete: this.transitionComplete.bind(this)} );
        this.showRevealPage.to( this.$background, 0.65, { width: '0%', ease: Power2.easeIn }, 'start' )
            .to( this.$bar, 0.55, { x: '100%', ease: Power2.easeIn }, 'start+=0.1' )
            .to( this.$logo, 0.2, { autoAlpha: 0, ease: Power2.easeIn }, 'start+=0.2' );


        // LEAVING PAGE
        this.showLeavingPage = new TimelineLite( {paused: true, onComplete: this.leavingPage.bind(this)} );
        this.showLeavingPage.to( this.$bar, 0.6, { x: '200%', ease: Power2.easeIn }, 'start' )
            .to( this.$background, 0.65, { width: '100%', ease: Power2.easeIn }, 'start' );


    }

    onIntroComplete() {
        this.$background_texts_container.style.display = 'none';
    }

    transitionComplete() {
        enableBodyScroll( this.$el );

        // Disable interaction on element
        this.$el.style.pointerEvents = 'none';
        this.$el.style.display = 'none';

        this.$bar.style.left = 'auto';
        this.$background.style.right = 'auto';
        this.$bar.style.right = '80px';
        this.$background.style.left = 0;

        // Set cookie to play short version next tim
        CookiesManager.set( this.cookie_name, '1', 1 );

        this.app.emit( 'reveal_page_transition_end' );
    }

    leavingPage() {
        // window.location = this.nextLocationHref;
        this.isOutroDone = true;
        this.outroTimestamp = Date.now();
    }


    addEventListeners() {

        // Catpure leaving page links event to play leavingPage timeline
        delegate( document.body, '[href]', 'click', this.onClickHandler.bind(this), false );

        this.app.on( 'resize', this.resize.bind(this) );

        this.app.on( 'leaving_page_transition_call', this.startLeavingPage.bind( this ) );

        // Trick history cache btn and page-transitions issue
        window.addEventListener("pageshow", this.onWindowPageShow.bind(this) );
    }

    onWindowPageShow(event) {
        if (event.persisted) {
            document.querySelector('html').classList.add( 'reloading' );
            window.location.reload();
        }
    }

    onClickHandler( e ) {
        var href = e.delegateTarget.getAttribute( 'href' );
        var target = e.delegateTarget.getAttribute( 'target' );

        // Prevent self clicked url
        if( href == document.location.href ) {
            e.preventDefault();
            return null;
        }

        // Avoid cross-navigation links
        if( href != 'undefined' && href.indexOf( '#' ) == -1 && href.indexOf( 'mailto:' ) == -1 && href.indexOf( 'tel:' ) == -1 && target != '_blank' ) {
            e.preventDefault();
            e.stopImmediatePropagation();

            this.nextLocationHref = href;

            this.startLeavingPage();

            return null; // prevent user navigation away until animation's finished
        }
    }

    startLeavingPage( href = null ) {
        // Prevent self clicked url
        if( href && href == document.location.href ) {
            e.preventDefault();
            return;
        }

        // Display before animate
        this.$el.style.display = 'block';
        this.$el.style.pointerEvents = 'all';
        document.body.classList.add( 'loading' );
        this.showLeavingPage.play();

        // prevent user navigation away until animation's finished
        this.app.emit( 'leaving_page_transition_start', { href: ( href ) ? href : this.nextLocationHref,  duration: this.showLeavingPage.duration() } );
    }

    resize() {
        if ( this.$background_texts.length > 0 ) {
            this.height = this.$background_texts[0].getBoundingClientRect().height;
            this.$background_texts_parent.style.height = this.height + 'px';
            this.$background_texts_container.style.marginTop = this.height + 'px';
        }

        // reset current position ?
    }


    destroy() {}
}