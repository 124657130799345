import AbstractPageController from './AbstractPageController';
import Dropdown from '../components/dropdown';
import Logger from '../utils/Logger';
import { addClass, removeClass } from '../utils/CssUtils';
import { addEventListener } from '../utils/EventUtils';
import { closest, hasProperty, getProperty } from '../utils/JsUtils';
import { convertHTMLStringToDOMElements } from '../utils/HtmlUtils';
import Request from '../utils/Request';
import Masonry from 'masonry-layout';


export default class SearchResultsController extends AbstractPageController {
    constructor( ...args ) {
        super( ...args );
        Logger.log( 'SearchResultsController->constructor()' );

        this.classname = 'search-results';
        this.loadMore = {};
        for ( const group of [ 'news', 'pages', 'photos-videos', 'press', 'faq', 'info-intox' ] ) {
            this.loadMore[group] = {
                page: 1,
                isProcessing: false,
                ajax: false
            };
        }
        this.loadMoreLoaderDuration = 600;
        this.initDomElements();
        this.initPhotosVideosMasonryGrid();
        this.addEventListeners();

        this.ready();
    }


    initDomElements() {
        this.$container = document.querySelector( '.js-' + this.classname + '__container' );
        if ( this.$container ) {
            this.searchQuery = this.$container.getAttribute( 'data-search-query' );
        }

        this.$groups = document.querySelectorAll( '.js-' + this.classname + '__group' );
        if ( this.$groups ) {
            for ( const group of this.$groups ) {
                const type = group.getAttribute( 'data-type' );
                if ( type ) {
                    this.loadMore[type].group = group;
                    this.loadMore[type].groupDropdown = new Dropdown( group );
                    this.loadMore[type].container = group.querySelector( '.js-' + this.classname + '__group__content' );
                    this.loadMore[type].button = group.querySelector( '.js-' + this.classname + '__load-more' );
                }
            }
        }

        this.$photosVideosGrid = document.querySelector( '.js-' + this.classname + '__container__group__content__items--photos-videos' );

        this.$loadMoreButtons = document.querySelectorAll( '.js-' + this.classname + '__load-more' );
    }


    initPhotosVideosMasonryGrid() {
        if ( this.$photosVideosGrid ) {
            this.photosVideosMasonryGrid = new Masonry( this.$photosVideosGrid, {
                itemSelector: '.c-photos-videos-grid-item',
                percentPosition: true,
                columnWidth: '.c-photos-videos-item--small',
                gutter: 30
            } );
        }
    }


    addEventListeners() {
        if ( this.$loadMoreButtons ) {
            this._loadMoreClickListener = this.loadMoreClickListener.bind( this );
            addEventListener( this.$loadMoreButtons, 'click', this._loadMoreClickListener );
        }
    }


    loadMoreClickListener( e ) {
        const groupElement = closest( e.target, '.js-' + this.classname + '__group' );
        if ( groupElement ) {
            const type = groupElement.getAttribute( 'data-type' );
            if ( type ) {
                this.loadMore[type].isProcessing = true;
    
                this.showLoader( type, this.loadMore[type].button, () => this.updateFromAjaxResponse( type, this.loadMore[type].container, this.loadMore[type].button ) );
    
                const params = {};
                params.type = type;
                params.query = this.searchQuery;
                params.page = this.loadMore[type].page + 1;
    
                Request.AJAX( {
                    'url': window.FNC.ajax_url + '?action=fnc_search_ajax',
                    'data': params,
                    'success': ( response ) => this.handleAjaxResponse( type, this.loadMore[type].container, this.loadMore[type].button, response )
                } );
            }
        }
    }


    showLoader( type, button, callback = null ) {
        addClass( button, 'is-loading' );
        this.loadMore[type].loaderTimeout = setTimeout( () => {
            this.loadMore[type].loaderTimeout = false;
            this.hideLoader( type, button, callback );
        }, this.loadMoreLoaderDuration );
    }

    
    hideLoader( type, button, callback = null ) {
        if ( !this.loadMore[type].isProcessing ) {
            removeClass( button, 'is-loading' );
            if ( callback ) {
                callback();
            }
        }
    }


    handleAjaxResponse( type, container, button, response ) {
        if ( response ) {
            const success = getProperty( response, 'success' );
            if ( success ) {
                const data = getProperty( response, 'data' );
                if ( data ) {
                    this.loadMore[type].ajax = {
                        page: getProperty( data, 'page' ),
                        itemsHtml: getProperty( data, 'items_html' ),
                        hasMore: getProperty( data, 'has_more' )
                    };
                    this.loadMore[type].isProcessing = false;

                    if ( !this.loadMore[type].loaderTimeout ) {
                        this.updateFromAjaxResponse( type, container, button );
                    }
                }
            }
        } else {
            this.loadMore[type].isProcessing = false;
        }
    }


    updateFromAjaxResponse( type, container, button ) {
        const ajax = this.loadMore[type].ajax;
        if ( ajax ) {
            if ( type !== 'photos-videos' ) {
                container.insertAdjacentHTML( 'beforeend', ajax.itemsHtml );
            } else {
                const domItems = convertHTMLStringToDOMElements( ajax.itemsHtml );
                const fragment = document.createDocumentFragment();
                for ( let i = 0; i < domItems.length; i++ ) {
                    fragment.appendChild( domItems[i] );
                }
                this.$photosVideosGrid.appendChild( fragment );
                this.photosVideosMasonryGrid.appended( domItems );
            }

            this.animateNewItems();
            
            // Resizing the dropdown
            if ( this.loadMore[type].groupDropdown ) {
                this.loadMore[type].groupDropdown.resize();
            }
            
            // Checking if we reached the end
            if ( !ajax.hasMore ) {
                addClass( button.parentNode, 'u-d-none' );
            }
            
            // Updating state
            this.loadMore[type].page = ajax.page;
            this.loadMore[type].ajax = false;
            this.hideLoader( type, button );
        }
    }


    animateNewItems() {
        const newItems = document.querySelectorAll( '.to-animate' );
        for( let i = 0, j = newItems.length; i < j; i++ ) {
            const delay = ( i + 1 ) * 210;
            setTimeout(() => {
                removeClass( newItems[i], 'to-animate' );
            }, delay );
        }
    }


    update() {}


    destroy() {}
}
