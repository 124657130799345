import AbstractPageController from './AbstractPageController';
import Logger from '../utils/Logger';
import {getTimeline} from "../utils/AnimationUtils";


export default class DefaultController extends AbstractPageController {
    constructor( callback = null ) {
        super( callback );
        Logger.log( 'DefaultController->constructor()' );

        // Animations
        this.timelines = [];
        if( this.app.isDesktop ) {
            this.createScene();
        }

        this.ready();
    }

    createScene() {
        this.$animatedItems = document.querySelectorAll( '[data-animation]' );
        for( let i = 0, j = this.$animatedItems.length; i < j; i++ )
        {
            let el = this.$animatedItems[i];
            let animationName = el.getAttribute( 'data-animation' );
            let tween = getTimeline( animationName, el );
            this.timelines.push( {
                'tween': tween,
                'el': el
            });
        }
    }


    /**
     * Update call to render animations
     */
    update() {



    }



    destroy() {
        Logger.log( 'DefaultController->destroy()' );
    }
}