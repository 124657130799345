import { addClass, removeClass } from '../utils/CssUtils';
import { addEventListener, preventDefault } from '../utils/EventUtils';
import Logger from '../utils/Logger';
import AppController from '../controllers/AppController';
import ClipboardJS from 'clipboard';


export default class SiteMenu {
    constructor() {
        Logger.log( 'SiteMenu->constructor()' );

        this.classname = 'c-site-menu';
        this.isMenuOpen = false;
        this.isSearchBarOpen = false;

        this.initDomElements();
        this.addEventListeners();
        this.update();
    }


    initDomElements() {
        this.$menu = document.querySelector( '.js-' + this.classname );
        this.$siteContainer = document.querySelector( '.js-site-container' );
        this.$burger = this.$menu.querySelector( '.js-' + this.classname + '__burger' );
        this.$search = this.$menu.querySelector( '.js-' + this.classname + '__search' );
        this.$searchQuery = this.$menu.querySelector( '.js-' + this.classname + '__search__query' );
        this.$searchButton = this.$menu.querySelector( '.js-' + this.classname + '__search__button' );
        this.$extraNavbar = document.querySelector( '.c-master-navbar' ) || false;
        this.$footer = document.querySelector( 'footer' ) || false;
        if( this.$extraNavbar ) {
            this.$extraNavbarProgressBar = this.$extraNavbar.querySelector( '.c-master-navbar__progress' );
            this.$extraNavbarProgressReferer = document.querySelector( 'main' );
        }
        this.$sharingItemsPopup = this.$menu.querySelectorAll( '.js-' + this.classname + '__ribbon__sharing__item--popup' );
        this.$sharingClipboard = this.$menu.querySelector( '.js-' + this.classname + '__ribbon__sharing__item--clipboard' );
    }


    addEventListeners() {
        addEventListener( this.$burger, 'click', this.toggleMenu.bind( this ) );
        addEventListener( this.$search, 'click', this.toggleSearchBar.bind( this ) );
        addEventListener( this.$searchQuery, 'keyup', this.searchQueryKeyUpListener.bind( this ) );
        addEventListener( this.$searchButton, 'click', this.performSearch.bind( this ) );
        addEventListener( this.$sharingItemsPopup, 'click', this.openSharingPopup.bind( this ) );
        if (this.$sharingClipboard) {
            this.addCopyUrlToClipboardListener();
        }

    }


    toggleMenu() {
        if ( this.isMenuOpen ) {
            this.closeMenu();
        } else {
            this.openMenu();
        }
    }


    openMenu() {
        if ( !this.isMenuOpen ) {
            if( this.isSearchBarOpen ) {
                this.closeSearchBar();
            }
            this.isMenuOpen = true;
            addClass( this.$menu, 'is-open' );
        }
    }


    closeMenu() {
        if ( this.isMenuOpen ) {
            removeClass( this.$menu, 'is-open' );
            this.isMenuOpen = false;
        }
    }


    toggleSearchBar() {
        if ( this.isSearchBarOpen ) {
            this.closeSearchBar();
        } else {
            this.openSearchBar();
        }
    }


    openSearchBar() {
        if ( !this.isSearchBarOpen ) {
            if ( this.isMenuOpen ) {
                this.closeMenu();
            }
            this.isSearchBarOpen = true;
            addClass( this.$menu, 'is-searching' );
            addClass( this.$siteContainer, 'is-blurred' );
            setTimeout( () => {
                this.$searchQuery.focus();
                const length = this.$searchQuery.value.length;
                this.$searchQuery.setSelectionRange( length, length );
            }, 400 );
        }
    }


    closeSearchBar() {
        if ( this.isSearchBarOpen ) {
            this.isSearchBarOpen = false;
            this.$searchQuery.blur();
            removeClass( this.$siteContainer, 'is-blurred' );
            removeClass( this.$menu, 'is-searching' );
        }
    }


    searchQueryKeyUpListener( e ) {
        if ( e.key === 'Enter' ) {
            this.performSearch();
        }
        if ( event.key === 'Escape' || event.code == 'Escape' ) {
            this.closeSearchBar();
        }
    }


    performSearch() {
        const searchQuery = this.getSearchQuery();
        if ( searchQuery ) {
            if ( this.$searchButton ) {
                const searchUrl = this.$searchButton.getAttribute( 'data-search-url' );
                if ( searchUrl ) {
                    this.closeSearchBar();
                    AppController.getInstance().startLeavingPageTransition( searchUrl + '?q=' + encodeURIComponent( searchQuery ) );
                }
            }
        }
    }


    getSearchQuery() {
        if ( this.$searchQuery ) {
            return this.$searchQuery.value;
        }
        return null;
    }


    openSharingPopup( e ) {
        preventDefault( e );
        const target = e.currentTarget;
        const width = 650;
        const height = 450;
        const left = ( screen.width / 2 ) - ( width / 2 );
        var top = ( screen.height / 2 ) - ( height / 2 );
        window.open( target.href, 'Partage', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left );
    }


    addCopyUrlToClipboardListener() {
        const copyUrlToClipboard = new ClipboardJS( this.$sharingClipboard );
        let copyUrlToClipboardOverlayTimeout = null;
        copyUrlToClipboard.on( 'success', ( e ) => {
            clearTimeout( copyUrlToClipboardOverlayTimeout );
            addClass( this.$sharingClipboard.parentNode, this.classname + '__ribbon__sharing__item--show-overlay' );
            copyUrlToClipboardOverlayTimeout = setTimeout(
                () => removeClass( this.$sharingClipboard.parentNode, this.classname + '__ribbon__sharing__item--show-overlay' ),
                1500
            );
            e.clearSelection();
        } );
    }


    update() {
        // Scrolling menu ribbon
        if ( window.scrollY > 0 ) {
            addClass( this.$menu, 'is-scrolling' );
        } else {
            removeClass( this.$menu, 'is-scrolling' );
        }

        // Extra master-navbar
        if( this.$extraNavbar ) {

            // Manage display
            if( window.scrollY > window.innerHeight / 2 ) {
                addClass( this.$menu, 'extra-navbar' );
                // Update progress
                var percScroll = window.scrollY / ( this.$extraNavbarProgressReferer.getBoundingClientRect().height - this.$footer.getBoundingClientRect().height - (window.innerHeight / 2)  ) * 100;
                if( percScroll > 100 )
                    percScroll = 100;
                if( percScroll < 0 )
                    percScroll = 0
                this.$extraNavbarProgressBar.style.width = percScroll + '%';
            } else {
                removeClass( this.$menu, 'extra-navbar' );
            }
        }
    }
}
