import { addClass } from './CssUtils';


/**
 * Creates a simple DOM element.
 *
 * @param {string} tag - Element tag
 * @param {Object=} parameters - Element parameters
 * @param {string=} parameters.className - element classes
 * @param {string=} parameters.text - text classes
 * @param {Array<{name: string, value: string}>=} parameters.attributes - Element attributes array
 * @return {HTMLElement}
 */
export function createHTMLElement( tag, { classname, text, attributes } = {} ) {
    const element = document.createElement( tag );

    if ( classname ) {
        addClass( element, classname );
    }

    if ( text ) {
        element.innerHTML = text;
    }

    if ( attributes ) {
        for ( const key in attributes ) {
            element.setAttribute( key, attributes[key] );
        }
    }

    return element;
}


/**
 * Converts an HTML string to a DOM element.
 *
 * @param {string} html - HTML string
 * @return {HTMLElement}
 */
export function convertHTMLStringToDOMElement( html ) {
    const wrapper = document.createElement( 'div' );
    wrapper.innerHTML = html;
    return wrapper.firstChild;
}

/**
 * Converts an HTML string to DOM elements.
 *
 * @param {string} html - HTML string
 * @return {HTMLElement}
 */
export function convertHTMLStringToDOMElements( html ) {
    const wrapper = document.createElement( 'div' );
    wrapper.innerHTML = html;
    const domElements = [];
    for ( let i = 0; i < wrapper.children.length; i++) {
        domElements.push( wrapper.children[i] );
    }
    return domElements;
}