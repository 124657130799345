import AbstractComponent from "./AbstractComponent";
import Logger from '../utils/Logger';


export default class FormSelect extends AbstractComponent {
    constructor(...args) {
        Logger.log( 'FormSelect->constructor()' );
        super(...args);

        this.classname = 'c-form-select';
        this.initDomElements();
        this.addEventListeners();
        this.updatePlaceholder();
    }


    initDomElements() {
        this.$select = this.$el.querySelector( '.' + this.classname + '__input' );
        this.$placeholder = this.$el.querySelector( '.' + this.classname + '__placeholder' );
        if ( this.$placeholder ) {
            const placeholderBreakpoints = this.$placeholder.getAttribute( 'data-placeholder-breakpoints' );
            if ( placeholderBreakpoints ) {
                this.$placeholderBreakpoints = JSON.parse( placeholderBreakpoints );
            }
        }
    }


    addEventListeners() {
        if ( this.$placeholder ) {
            this.app.on( 'breakpoint-update', this.updatePlaceholder.bind( this ) );
        }
    }


    updatePlaceholder() {
        if ( this.$placeholderBreakpoints ) {
            const previousText = this.$placeholder.innerHTML;
            const newText = this.app.getCurrentBreakpointValue( this.$placeholderBreakpoints );
            if ( newText && ( newText !== previousText ) ) {
                this.$placeholder.innerHTML = newText;
            }
        }
    }


    destroy() {}
}