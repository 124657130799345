import AbstractComponent from "../AbstractComponent";
import Logger from '../../utils/Logger';
import { addClass, css, removeClass } from "../../utils/CssUtils";
import { addEventListener, removeEventListener, preventDefault } from "../../utils/EventUtils";
import { TweenMax, TimelineLite, Power1, Linear, CSSPlugin } from "gsap/all";
import VideoEmbed from "../video-embed";


export default class MasterSlideshow extends AbstractComponent {
    constructor(...args) {
        Logger.log('MasterSlideshow->constructor()');
        super(...args);

        this.classname = 'c-master-slideshow';
        this.initDomElements();
        this.initSlideshow();
        this.addEventListeners();
    }

    initDomElements() {
        this.$container = this.$el.querySelector( '.' + this.classname + '__container__medias' );
        this.$medias = this.$el.querySelectorAll( '.' + this.classname + '__container__medias__slider__item' );
        this.$texts = this.$el.querySelectorAll( '.' + this.classname + '__container__textual__slider__item' );
        this.$counter = this.$el.querySelector( '.' + this.classname + '__container__textual__counter__current' );
        this.$prevBtn = this.$el.querySelector( '.' + this.classname + '__container__medias__controls__nav--prev' );
        this.$nextBtn = this.$el.querySelector( '.' + this.classname + '__container__medias__controls__nav--next' );
    }


    addEventListeners() {
        // Nav click
        if( this.$prevBtn ) {
            this._prevBtnListener = this.prevBtnListener.bind( this );
            addEventListener( this.$prevBtn, 'click', this._prevBtnListener );
        }
        if( this.$nextBtn ) {
            this._nextBtnListener = this.nextBtnListener.bind( this );
            addEventListener( this.$nextBtn, 'click', this._nextBtnListener );
        }

        // Resize
        this.app.on( 'resize', this.resize.bind(this) );
    }


    initSlideshow() {
        this.current = 0;
        this.is_running = false;
        css( this.$medias[this.current], { width: '100%' } );
        css( this.$texts[this.current], { position: 'relative' } );

        this.videoEmbeds = [];
        for ( let index = 0; index < this.$medias.length; index++ ) {
            const videoEmbed = this.$medias[index].querySelector( '.c-video-embed' );
            if ( videoEmbed ) {
                this.videoEmbeds[index] = new VideoEmbed( videoEmbed );
            }
        }

        this.updateMediasMinWidth();
    }

    prevBtnListener() {
        // Lock system
        if( this.is_running == false ) {
            this.is_running = true;

            // Define current and next
            var current = this.current;
            var next = ( current == 0 ) ? this.$medias.length - 1 : current - 1;

            this.updateCounter( next );

            // Animate
            TweenMax.set( this.$medias[next], { width: '100%' } );
            TweenMax.set( this.$texts[current], { clearProps: 'position' } );
            TweenMax.set( this.$texts[next], { position: 'relative' } );
            TweenMax.fromTo( this.$medias[current], 0.7, { width: '100%' }, { width: '0%', ease: Power1.easeOut, onComplete: () => {
                addClass( this.$medias[next], 'active' );
                removeClass( this.$medias[current], 'active' );

                // Check video embed
                const videoEmbed = this.videoEmbeds[current];
                if ( videoEmbed ) {
                    videoEmbed.pause();
                }
            } } );
            TweenMax.fromTo( this.$texts[current], 0.3, { y: 0, autoAlpha: 1 }, { x: -25, autoAlpha: 0, ease: Power1.easeOut });
            TweenMax.fromTo( this.$texts[next], 0.5, { x: 25, autoAlpha: 0 }, { x: 0, autoAlpha: 1, delay: 0.2, ease: Power1.easeOut, onComplete: () => {
                addClass( this.$texts[next], 'active' );
                removeClass( this.$texts[current], 'active' );

                this.current = next;
                this.is_running = false;
            } } );
        }
    }

    nextBtnListener() {
        // Lock system
        if( this.is_running == false ) {
            this.is_running = true;

            // Define current and next
            var current = this.current;
            var next = ( current == this.$medias.length - 1 ) ? 0 : current + 1;

            this.updateCounter( next );

            // Animate
            TweenMax.set( this.$texts[current], { clearProps: 'position' } );
            TweenMax.set( this.$texts[next], { position: 'relative' } );
            TweenMax.fromTo( this.$medias[next], 0.7 ,{ width: '0%' }, { width: '100%', ease: Power1.easeOut, onStart: () => {
                addClass( this.$medias[next], 'active' );
                if ( next == 0 ) {
                    removeClass( this.$medias[current], 'active' );
                }
            }, onComplete: () => {
                // Check video embed
                const videoEmbed = this.videoEmbeds[current];
                if ( videoEmbed ) {
                    videoEmbed.pause();
                }
            } } );
            TweenMax.fromTo( this.$texts[current], 0.3 ,{ y: 0, autoAlpha: 1 }, { x: 25, autoAlpha: 0, ease: Power1.easeOut });
            TweenMax.fromTo( this.$texts[next], 0.5 ,{ x: -25, autoAlpha: 0 }, { x: 0, autoAlpha: 1, delay: 0.2, ease: Power1.easeOut, onComplete: () => {
                addClass( this.$texts[next], 'active' );
                removeClass( this.$texts[current], 'active' );
                removeClass( this.$medias[current],  'active' );
                css( this.$medias[current], { width: '0' } );
                this.current = next;
                this.is_running = false;
            } } );
        }
    }


    resize() {
        this.updateMediasMinWidth();
    }


    updateMediasMinWidth() {
        for ( const media of this.$medias ) {
            var mediaFirstElementChild = media.firstElementChild;
            if ( mediaFirstElementChild ) {
                css( mediaFirstElementChild, { minWidth: this.$container.getBoundingClientRect().width + 'px' } );
            }
        }
    }


    updateCounter( next ) {
        this.$counter.innerHTML = ( next + 1 ); // add 1 because it's indexed value
    }


    removeEventListeners() {
        removeEventListener( this.$prevBtn, 'click', this._prevBtnListener );
        removeEventListener( this.$nextBtn, 'click', this._nextBtnListener );
    }


    destroy() {
        Logger.log( 'MasterSlideshow->destroy()' );

        this.removeEventListeners();
    }
}