import { addClass, removeClass } from '../utils/CssUtils';
import { addEventListener, preventDefault } from '../utils/EventUtils';
import { hasProperty } from '../utils/JsUtils';
import Logger from '../utils/Logger';
import AppController from "../controllers/AppController";
import EventEmitter from 'eventemitter3';
import { TweenMax, TimelineLite, TimelineMax, Power3, Power2, Linear, Elastic, CSSPlugin } from "gsap/all";
import {delegate} from "../utils/JsUtils";
import Request from "../utils/Request";
import FollowCursor from '../utils/FollowCursor';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default class Popin extends EventEmitter{

    constructor() {
        super();
        Logger.log( 'Popin->constructor()' );

        this.classname = 'c-popin';
        this.app = AppController.getInstance();
        this.isPopinOpen = false;
        this.toClose = false;
        this.closeHistoryTitle = null;
        this.closeHistoryUrl = null;
        this.processNavigation = false;
        this._classes = {
            next : 'next',
            prev : 'prev',
            isOpen: 'is-open',
            endAnimOpen : 'end-anim-open'
        };
        this.action = 'fnc_popin_ajax'

        this.initDomElements();
        if( this.$popin ) {
            this.initClass();
            this.addEventListeners();
        }
    }


    initDomElements() {
        this.$popin = document.querySelector( '.js-' + this.classname );
        if( this.$popin ) {
            this.$close = this.$popin.querySelector( '.js-' + this.classname + '__close' );
            this.$cursor = this.$popin.querySelector( '.js-' + this.classname + '__cursor' );
            this.$navigationContainer = this.$popin.querySelector( '.js-' + this.classname + '__navigation-container' );
            this.$navigation = [].slice.call( this.$popin.querySelectorAll( '.js-' + this.classname + '__navigation' ) );
            this.$strip = this.$popin.querySelectorAll( '.js-' + this.classname + '__strip' );
            this.$popin_wrapper = this.$popin.querySelector( '.js-' + this.classname + '__wrapper' )
        }
    }

    initClass() {
        this._initAnimation();
        if( this.app.$html.classList.contains( 'desktop' ) )
            this._initFollowCursor();
    }

    _initAnimation() {
        //--- Open Popin
        this._tlOpen = new TimelineMax({
            paused : true,
            onStart: () => {
                addClass( this.$popin, this._classes.isOpen );
                if ( this._FollowCursor ) {
                    this._FollowCursor.updateData();
                }
            },
            onComplete : () => {
                this._endAnimPopinOpen();
            }
        });

        this._tlOpen
            .staggerTo( this.$strip, 0.5, { scaleX : 1, transformOrigin: '0% 0%', ease : Power2.easeOut }, 0.05, 'start' )
            .to( this.$close, 0.5, {autoAlpha : 1, ease : Power2.easeOut } )
        if( this.$navigation )
            this._tlOpen.to( this.$navigationContainer, 0.5, {autoAlpha : 1, translateX: 0, ease : Power2.easeOut }, 'start+=0.2' )


        //--- Close Popin
        this._tlClose = new TimelineMax({
            paused : true,
            onStart: () => {
                removeClass( this.$popin, this._classes.endAnimOpen );
            },
            onComplete : () => {
               this._endAnimPopinClose();
            }
        });

        if( this.$navigation )
            this._tlClose.to( this.$navigationContainer, 0.5, {autoAlpha : 0, ease : Power2.easeOut }, 'start' )
        this._tlClose
            .to( this.$close, 0.5, {autoAlpha : 0, ease : Power2.easeOut }, 'start' )
            .staggerTo( this.$strip, 0.5, { scaleX : 0, transformOrigin: '100% 100%', ease : Power2.easeOut }, 0.05 );
    }

    _initFollowCursor() {
        this._FollowCursor = new FollowCursor( {
            container : this.$close,
            cursor : this.$cursor,
        });
    }

    setType( type ) {
        if( this._type ) {
            this.$popin.classList.remove( this.classname + '--' + this._type );
        }
        this.$popin.classList.add( this.classname + '--' + type );
        this._type = type;
        if( this._type != 'quiz' ) {
            this.hideNavigation();
        }
    }

    setContents( contents ) {
        this.$popin_wrapper.innerHTML = contents;
    }

    addEventListeners() {
        if( this.$close ) {
            addEventListener( this.$close, 'click', this.to_close.bind( this ) );
        }

        if( this.$navigation ) {
            for ( let i = 0, j = this.$navigation.length; i < j; i++ ) {
                addEventListener( this.$navigation[i], 'click', this.onClickNavigation.bind( this ) );
            }
        }

        // Bind openers
        delegate( document.body, '.js-popin-open', 'click', this.onClickOpener.bind(this));

        this.on( 'popin_close', this._close.bind( this ) );
        this.on( 'popin_end_process_navigation', this._endProcessNavigation.bind( this ) );
    }

    open() {
        if ( !this.isPopinOpen ) {
            this.isPopinOpen = true;
            if ( this.$popin_wrapper.firstElementChild ) {
                disableBodyScroll( this.$popin_wrapper.firstElementChild );
            }
            this._tlOpen.play(0);
        }
    }

    openAndReveal() {
        if ( !this.isPopinOpen ) {
            this.open();

            setTimeout( () => {
                // Animate new content when opening is finished (instead of request process)
                this._revealContent();
            }, this._tlOpen.duration() * 1000 );
        }
    }

    setCloseHistory( title, url ) {
        this.closeHistoryTitle = title;
        this.closeHistoryUrl = url;
    }

    to_close() {
        if ( this.isPopinOpen && this.toClose == false ) {
            this.toClose = true;

            if ( this.closeHistoryTitle && this.closeHistoryUrl ) {
                history.pushState( null, this.closeHistoryTitle, this.closeHistoryUrl );
                document.title = this.closeHistoryTitle;
                this.closeHistoryTitle = null;
                this.closeHistoryUrl = null;
            }

            if( this._type == 'quiz' )
                this.emit( 'popin_to_close' );
            else
                this._hideContent();
        }
    }

    _close() {
        this._tlClose.play(0);
    }


    _endAnimPopinOpen() {
        this.emit( 'popin_open' );
        addClass( this.$popin, this._classes.endAnimOpen );
    }

    _endAnimPopinClose() {
        this.isPopinOpen = false;
        clearAllBodyScrollLocks();
        this.toClose = false;
        removeClass( this.$popin, this._classes.isOpen );
        if ( this._type == 'photo-video' ) {
            this.$popin_wrapper.innerHTML = '';
        }
        // BUG Loop of closing with event emitted and recall close on QuizPopin..
        if( this._type != 'quiz' ) {
            this.emit( 'popin_to_close' );
        }
    }

    onClickNavigation( event ) {
        let target = event.currentTarget;

        if( this.processNavigation == false ) {
            this.processNavigation = true;

            if( target.classList.contains( this._classes.next ) ) {
                this._next();
            } else if( target.classList.contains( this._classes.prev ) )
                this._prev();
        }
    }

    onClickOpener( event ) {
        preventDefault( event );
        var el = event.delegateTarget;
        var type = el.getAttribute( 'data-popin-type' );
        var id = el.getAttribute( 'data-popin-item-id' );

        this.setType( type );
        this.open();
        this._requestData( type, id );
    }

    _requestData( type, id ) {
        const params = {};
        params.type = type;
        params.id = id;

        Request.AJAX({
            'url': window.FNC.ajax_url + '?action=' + this.action,
            'data': params,
            'success': this.onSuccess.bind( this )
        } );
    }

    onSuccess( response ) {
        // Inject content
        this.$popin_wrapper.innerHTML = response.data.popin_html;

        // Instantiate components
        this.instantiateComponents();

        // Animate new content depending on type
        this._revealContent();
    }


    instantiateComponents() {
        const components = this.$popin_wrapper.querySelectorAll( '[data-component]' );
        for ( let i = 0; i < components.length; i++ ) {
            const component = components[i];
            const name = component.getAttribute('data-component');
            if ( hasProperty( this.app.components_map, name ) ) {
                const componentClass = this.app.components_map[name];
                if ( componentClass ) {
                    new componentClass.default( component );
                }
            }
        }
    }

    _revealContent() {
        this._tlRevealContent = new TimelineMax({
            paused : true,
            onStart: () => {
            },
            onComplete : () => {
                if ( this.$popin_wrapper.firstElementChild ) {
                    disableBodyScroll( this.$popin_wrapper.firstElementChild );
                }
                this.emit( 'popin_end_process_navigation' );
            }
        });

        // FAQ / Info-Intox / Federation
        if (this._type == 'new-faq' || this._type == 'faq' || this._type == 'info-intox' || this._type == 'federation' ) {
            let classname = '';
            if (this._type == 'new-faq' || this._type == 'faq' || this._type == 'info-intox' ) {
                classname = '.c-faq-popin';
            } else if ( this._type == 'federation' ) {
                classname = '.c-federation-popin';
            }
            var container = this.$popin_wrapper.querySelector( classname + '__container--left' );
            var image = this.$popin_wrapper.querySelector( classname + '__container--right' );

            // White left panel
            this._tlRevealContent.fromTo( container, 0.7, { autoAlpha : 0, x : -55 }, { autoAlpha : 1, x : 0, ease : Power2.easeOut }, 'start' );

            // Right Image
            this._tlRevealContent.fromTo( image, 0.7, { autoAlpha : 0, x : 55, yPercent: -50 }, { autoAlpha : 1, x : 0, yPercent: -50, ease : Power2.easeOut }, 'start' );
        }
        // Photo/Video
        else if ( this._type == 'photo-video' ) {
            var container = this.$popin_wrapper.querySelector( '.c-photo-video-popin' );
            this._tlRevealContent.fromTo( container, 0.7, { autoAlpha : 0 }, { autoAlpha : 1, ease : Power2.easeOut }, 'start' );
        }

        this._tlRevealContent.play(0);
    }

    _hideContent() {
        this._tlHideContent = new TimelineMax({
            paused : true,
            onStart: () => {
            },
            onComplete : () => {
                this._close();
            }
        });

        // FAQ / Info-Intox / Federation
        if (this._type == 'new-faq' || this._type == 'faq' || this._type == 'info-intox' || this._type == 'federation' ) {
            let classname = '';
            if (this._type == 'new-faq' || this._type == 'faq' || this._type == 'info-intox' ) {
                classname = '.c-faq-popin';
            } else if ( this._type == 'federation' ) {
                classname = '.c-federation-popin';
            }
            var container = this.$popin_wrapper.querySelector( classname + '__container--left' );
            var image = this.$popin_wrapper.querySelector( classname + '__container--right' );

            // White left panel
            this._tlHideContent.fromTo( container, 0.5, { autoAlpha : 1, x : 0 }, { autoAlpha : 0, x : -55, immediateRender:false, ease : Power2.easeIn }, 'start' );

            // Right Image
            this._tlHideContent.fromTo( image, 0.5, { autoAlpha : 1, x : 0, yPercent: -50 }, { autoAlpha : 0, x : 55, yPercent: -50, immediateRender:false, ease : Power2.easeIn }, 'start' );
        }
        // Photo/Video
        else if ( this._type == 'photo-video' ) {
            var container = this.$popin_wrapper.querySelector( '.c-photo-video-popin' );
            this._tlHideContent.fromTo( container, 0.3, { autoAlpha : 1 }, { autoAlpha : 0, immediateRender:false, ease : Power2.easeIn }, 'start' );
        }

        this._tlHideContent.play(0);
    }

    _next() {
        this.emit( 'popin_next' );
    }

    _prev() {
        this.emit( 'popin_prev' );
    }

    hideNavigation() {
        this.$navigationContainer.classList.add('d-none');
    }

    showNavigation() {
        this.$navigationContainer.classList.remove('d-none');
    }

    _endProcessNavigation() {
        this.processNavigation = false;
    }

    update() {
    }

    resize() {
        if ( this._FollowCursor ) {
            this._FollowCursor.resize();
        }
    }
}
