import AbstractComponent from "../AbstractComponent";
import { convertToBoolean } from '../../utils/JsUtils';
import Logger from '../../utils/Logger';
import Flickity from 'flickity';


export default class ActionsMapOtherActions extends AbstractComponent {
    constructor(...args) {
        Logger.log('ActionsMapOtherActions->constructor()');
        super(...args);

        this.classname = 'c-actions-map-other-actions';
        this.mobileBreakpoints = [ 'xs', 'sm', 'md' ];
        this.desktopBreakpoints = [ 'lg', 'xl', 'xxl' ];
        this.initDomElements();
        this.initSlider();
        this.addEventListeners();
    }


    initDomElements() {
        this.$slider = this.$el.querySelector( '.' + this.classname + '__slider' );
        this.$items = this.$el.querySelectorAll( '.' + this.classname + '__item' );
    }


    initSlider() {
        const sliderConfiguration = this.getSliderConfiguration();
        this.slider = new Flickity( this.$slider, sliderConfiguration );
        this.slider.on( 'staticClick', function( event, pointer, cellElement, cellIndex ) {
            console.log( 'staticClick' );
        } );
    }
    

    destroySlider() {
        if ( this.slider ) {
            this.slider.destroy();
        }
    }


    getSliderConfiguration() {
        const sliderConfiguration = {
            cellAlign: 'center',
            contain: true,
            groupCells: true,
            // watchCSS: true,
            adaptiveHeight: true,
            arrowShape: 'M55.8,56.7c0.3,0,0.6-0.2,0.9-0.3l7.9-7.7c0.3-0.2,0.3-0.6,0.3-0.9c0-0.3-0.2-0.6-0.3-0.9l-7.7-7.7 c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0.2-0.8,0.5-0.9,0.9s0,0.9,0.5,1.3l5.7,5.5H31.4c-0.8,0-1.3,0.6-1.3,1.3s0.5,1.3,1.3,1.3h29.1 l-5.7,5.7c-0.5,0.5-0.5,1.3,0,1.7C55,56.5,55.3,56.7,55.8,56.7'
        };
        // Mobile
        if ( this.app.isBreakpointDown( 'md' ) ) {
            sliderConfiguration.pageDots = true;
            sliderConfiguration.prevNextButtons = false;
            sliderConfiguration.wrapAround = true;
        // Desktop
        } else if ( this.app.isBreakpointUp( 'lg' ) ) {
            sliderConfiguration.pageDots = false;
            sliderConfiguration.prevNextButtons = convertToBoolean( this.$slider.getAttribute( 'data-show-prev-next-buttons' ) );
            sliderConfiguration.wrapAround = false;
        }
        return sliderConfiguration;
    }


    addEventListeners() {
        this.app.on( 'breakpoint-update', this.breakpointUpdateListener.bind( this ) );
    }


    breakpointUpdateListener( newBreakpoint, previousBreakpoint ) {
        const mobileToDesktop = this.isInBreakpoints( previousBreakpoint, this.mobileBreakpoints )
            && this.isInBreakpoints( newBreakpoint, this.desktopBreakpoints );
        const desktopToMobile = this.isInBreakpoints( previousBreakpoint, this.desktopBreakpoints )
            && this.isInBreakpoints( newBreakpoint, this.mobileBreakpoints );
        if ( mobileToDesktop || desktopToMobile ) {
            this.destroySlider();
            this.initSlider();
        }
    }


    isInBreakpoints( breakpoint, breakpoints ) {
        return breakpoints.indexOf( breakpoint ) !== -1
    }


    destroy() {}
}